import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { RiDeleteBin6Line, RiMailSendLine } from "react-icons/ri";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { zeroPad } from "../../../utils/functions/generic";
import ReactPaginate from "react-paginate";
import { Spinner } from "../../generic";
import { TableData } from "./Table";
import usePatientHook from "../../../hooks/usePatientHook";
import { IoIosArrowDropright, IoIosArrowForward } from "react-icons/io";
import { FaPlus, FaPrint } from "react-icons/fa6";
import useInvoiceHook from "../../../hooks/useInvoiceHook";
import { PulseLoader } from "react-spinners";
import RegisterPayment from "../../Modals/RegisterPayment";

const InvoiceTable = ({
  invoices,
 setCurrentPage,
  totalPages,
  currentPage,
}: any) => {
  const tbHeader = [
    "Invoice No.",
    "Full Name",
    "Date Created",
    "Due Date",
    "Due",
    "Amount",
    "Paid",
    "Balance Due",
    "Actions",
  ];
  const tbHeader_ = [
    "Invoice No.",
    "Full Name",
    "Date Created",
    "Due Date",
    "Paid",
    "Balance Due",
    "Actions",
  ];

  const [showDetails, setshowDetails] = useState(false);
  const [isloading, setisloading] = useState(false);
  const [selected, setselected] = useState<any>({});
  const [singleDetails, setsingleDetails] = useState<any>({});
  const [showRecordModal, setshowRecordModal] = useState(false);
  const { getInvoiceDetails } = useInvoiceHook();

  const [totaltrx, settotaltrx] = useState(0);

  const handlePageClick = async (data: any) => {
    setCurrentPage(data?.selected + 1);
  };

  const { patients } = usePatientHook();

  const InitDet = async () => {
    setisloading(true);

    try {
      var res = await getInvoiceDetails(selected.id);
      setsingleDetails(res);
      console.log(res, "bb");
    } catch (e: unknown) {
    } finally {
      setisloading(false);
    }
  };

  useEffect(() => {
    InitDet();
  }, [selected]);
  return (
    <div className="overflow-x-auto">
      {showRecordModal && (
        <RegisterPayment item={singleDetails} selecteditem={selected} show={setshowRecordModal} />
      )}
      <div className="flex w-full ">
        <div className={`${!showDetails ? "w-full" : ""}`}>
          <table className={`w-full bg-white`}>
            <thead className="w-full bg-[#EAECF0]">
              <tr className="w-full">
                {showDetails
                  ? tbHeader_.map((item, index) => (
                      <th
                        key={index}
                        scope="col"
                        className="px-6 py-3 uppercase text-left text-xs font-semibold whitespace-nowrap"
                      >
                        {item}
                      </th>
                    ))
                  : tbHeader.map((item, index) => (
                      <th
                        key={index}
                        scope="col"
                        className="px-6 py-3 uppercase text-left text-xs font-semibold whitespace-nowrap"
                      >
                        {item}
                      </th>
                    ))}
              </tr>
            </thead>
            {/* Table content */}
            <tbody className="w-full bg-white">
              {invoices?.map((item: any, index: any) => (
                <tr
                  className="bg-white border-b pt-8  hover:bg-gray-50"
                  key={index}
                >
                  <TableData item={item?.invoiceNo} />
                  <TableData item={`${item.fullName}`} />
                  <TableData item={item?.createdDate} />
                  <TableData item={item?.dueDate} />
                  {!showDetails && (
                    <TableData
                      item={
                        "In " +
                        (
                          (new Date(item.dueDate).getTime() -
                            new Date(item.createdDate).getTime()) /
                          3600000
                        ).toString() +
                        " Hrs"
                      }
                    />
                  )}
                  {!showDetails && <TableData item={item?.amount} />}
                  <TableData item={item?.paid} />
                  <TableData
                    item={(
                      parseInt(item.amount) - parseInt(item.paid)
                    ).toString()}
                  />

                  <td>
                    <div className="flex items-center px-6 py-3">
                      <IoIosArrowDropright
                        size={20}
                        className="text-blue-600 text-lg hover:bg-gray-400  active:bg-gray-400 rounded   cursor-pointer"
                        onClick={() => {
                          setshowDetails(true);
                          setselected(item);
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {showDetails && (
          <div className=" px-1 !w-full ">
            <div className="!w-full grid grid-cols-3 gap-2 ">
              <button
                disabled={Number(selected.paid) >= Number(selected.amount)}
                onClick={() => {
                  setshowRecordModal(true);
                }}
                className={`text-xs text-white py-2 px-1 rounded ${
                 !( Number(selected.paid) >= Number(selected.amount))
                    ? " bg-orange-400"
                    : "bg-gray-400"
                }`}
              >
                {" "}
                <FaPlus className="inline" /> Record Payment
              </button>
              <button className="text-xs text-white py-2 px-1 rounded bg-blue-400">
                {" "}
                <RiMailSendLine className="inline" /> Send to email
              </button>
              <button className="text-xs text-white py-2 px-1 rounded bg-primary">
                <FaPrint className="inline" /> Download
              </button>
            </div>

            <div className="p-3 bg-blue-100 rounded-[10px] mt-1 flex justify-between ">
              <div>
                <h2 className="text-gray-700 font-semibold">Invoice</h2>
                <p className="text-gray-500 text-sm">{selected.invoiceNo}</p>
                <p className="text-gray-500 text-sm">
                  {" "}
                  Issued date: {selected.createdDate}
                </p>
                <p className="text-gray-500 text-sm">
                  {" "}
                  Due date :{selected.dueDate}
                </p>
              </div>

              <div>
                <h2 className="text-gray-700 font-semibold">Issued to</h2>
                <p className="text-gray-500 text-sm"> {selected.fullName}</p>
              </div>
            </div>

            <div className="my-2 w-full">
              <h2 className="text-gray-700 font-semibold">Item Detils</h2>
              <p className="text-gray-500 text-sm">
                {" "}
                Item Details with more info
              </p>
            </div>

            {isloading ? (
              <div className=" m-12  justify-items-center justify-center">
                <PulseLoader
                  className=" m-12 justify-center"
                  color={"#350080"}
                  loading={isloading}
                  size={14}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : (
              <div className="w-full">
                <div className="flex justify-between my-2 w-full  ">
                  <p className="text-gray-700 font-semibold text-sm">Service</p>
                  <p className="text-gray-700 font-semibold text-sm">
                    Quantity{" "}
                  </p>
                  <p className="text-gray-700 font-semibold text-sm">Amount</p>
                </div>
                <hr />
                {singleDetails?.patientInvoiceItems?.map((itm: any) => (
                  <div className="flex justify-between w-full">
                    <p className="text-sm">{itm.service}</p>
                    <p className="text-sm">{itm.quantity}</p>
                    <p className="text-sm">{itm.amount}</p>
                  </div>
                ))}

                <p className="flex w-full justify-end items-end font-semibold text-sm my-2">
                  Total: {selected.amount}
                </p>
                <hr />
                <p className="text-gray-700 font-semibold text-sm my-2 ">
                  Transactions
                </p>
                <div className=" flex justify-between w-full ">
                  <p className="text-xs">Trx. No.</p>
                  <p className="text-xs">Payment Method</p>
                  <p className="text-xs">Amount</p>
                </div>
                <hr />
                {singleDetails?.patientTransactions?.map((i: any) => (
                  <div className=" flex justify-between w-full">
                    <p className="text-xs text-orange-400">
                      {i.patientTransactionNo}
                    </p>
                    <p className="text-xs text-blue-400">{i.paymentMethod}</p>
                    <p className="text-xs text-green-400">{i.amount}</p>
                  </div>
                ))}
                <p className="flex w-full justify-end items-end font-semibold text-sm my-2">
                  Total paid: {selected.paid}
                </p>
                <p className="flex w-full justify-end items-end font-semibold text-sm my-2">
                  Balance due: {Number(selected.amount) - Number(selected.paid)}
                </p>
              </div>
            )}
          </div>
        )}
      </div>
      {/* Table Footer (Pagination) */}
      {/* Table Footer (Pagination) */}
      <div className="w-full flex items-center justify-between">
        {/* Numbers */}
        <div>
          <p className="sub-heading text-black">{`${currentPage} of ${totalPages} Pages`}</p>
        </div>
        {/* NExt and Previous Btn */}
        {/* Pagination */}
        <div className="px-6 py-6">
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"inline-flex items-center -space-x-px"}
            pageClassName={
              "px-3 py-2 leading-tight text-sm text-gray-500 border border-gray-300 hover:text-gray-700 hover:bg-purple-300 hover:text-white"
            }
            pageLinkClassName={""}
            previousClassName={
              "block px-3 py-2 text-sm ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-purple-400 hover:text-white"
            }
            previousLinkClassName={""}
            nextClassName={
              "block px-3 py-2 leading-tight text-sm text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-purple-400 hover:text-white"
            }
            nextLinkClassName={""}
            breakClassName={
              "px-3 py-2 leading-tight text-sm text-gray-500 bg-white border border-gray-300 hover:bg-purple-400 hover:text-white"
            }
            breakLinkClassName={
              "px-3 py-2 leading-tight text-sm text-gray-500 bg-white border border-gray-300 hover:bg-purple-400 hover:text-white"
            }
            activeClassName={
              "z-10 px-3 py-2 leading-tight text-white border border-primary bg-primary hover:bg-purple-100 hover:text-primary"
            }
          />
        </div>
      </div>
    </div>
  );
};

export default InvoiceTable;
