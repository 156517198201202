import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import useStaffHook from "../../../hooks/useStaffHook";
import { zeroPad } from "../../../utils/functions/generic";
import ReactPaginate from "react-paginate";
import { Spinner } from "../../generic";
import { TableData } from "./Table";
import {
  AddpatientVital,
  GetAllvitals,
  GetAppointmentVitals,
  GetPatientAppointment,
} from "../../../utils/services/api/hospital/appointment";
import { useAuthContext } from "../../../context/AuthContext";
import { toast } from "react-toastify";
import { PulseLoader } from "react-spinners";
import { useForm } from "react-hook-form";
import { FaRegTimesCircle } from "react-icons/fa";
import { Button, InputField, SelectField } from "../../form";
import { TbHealthRecognition } from "react-icons/tb";

export default ({ patientAppoinments }: any) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { config } = useAuthContext();
  const currentDate = new Date();
  const [createModal, setCreateModal] = useState(false);
  const [selectedappid, setselectedappid] = useState();
  const [loading, setLoading] = useState(false);

  const vitaltaken = async (idd: any) => {
    var res = await GetAppointmentVitals(idd, config);
    if (res.data.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const { staff, setCurrentPage, totalPages, currentPage, onDeleteStaff } =
    useStaffHook();

  const stf: any = staff;
  const tbHeader = [
    "Assigned Doctor/Nurse",
    "Reason",
    "Status",
    "Date and Time",
  ];
  const handlePageClick = async (data: any) => {
    setCurrentPage(data?.selected + 1);
  };
  return (
    <div className="overflow-x-auto">
      {loading ? (
        <div className=" m-12  justify-items-center justify-center">
          <PulseLoader
            className=" m-12 justify-center"
            color={"#350080"}
            loading={loading}
            size={14}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <table className="w-full bg-white">
          <thead className="w-full bg-[#EAECF0]">
            <tr className="w-full">
              {tbHeader.map((item, index) => (
                <th
                  key={index}
                  scope="col"
                  className="px-6 py-3 uppercase text-left text-xs font-semibold whitespace-nowrap"
                >
                  {item}
                </th>
              ))}

              <th
                scope="col"
                className="px-6 py-3 uppercase text-left text-xs font-semibold whitespace-nowrap"
              >
                {"Actions"}
              </th>
            </tr>
          </thead>
          {/* Table content */}
          <tbody className="w-full bg-white">
            {patientAppoinments.map((item: any, index: number) => {
              return (
                <tr
                  className="bg-white border-b py-12 hover:bg-gray-50"
                  key={index}
                >
                  {/* Take vital modal */}

                  {createModal && (
                    <Modal
                      appointmentdetails={item}
                      apid={selectedappid}
                      setCreateModal={setCreateModal}
                    />
                  )}
                  <td className="px-6 py-3 flex items-center">
                    <p className="text-xs text-black">
                      {
                        stf.filter((ik: any) => ik.id == item.doctorID)[0]
                          ?.firstName
                      }
                      {
                        stf.filter((ik: any) => ik.id == item.doctorID)[0]
                          ?.lastName
                      }
                    </p>
                  </td>
                  <TableData item={item.reason} />
                  <TableData
                    color={`${
                      item.status == "pending" ? " text-[#FFA500]" : ""
                    }`}
                    item={
                      currentDate.getFullYear() ==
                        new Date(item.date).getFullYear() &&
                      currentDate.getMonth() ==
                        new Date(item.date).getMonth() &&
                      currentDate.getDay() == new Date(item.date).getDay()  
                        ? "Ongoing"
                        : item.status
                    }
                  />
                  <TableData item={`${item.date} ${item.time}`} />
                  <td>
                    <div className="flex  justify-between items-center gap-3 py-3">
                      <button
                        onClick={() => {
                          navigate(
                            `/hospital/patients/${id}/appointments/${item.id}`
                          );
                        }}
                        className="px-3 py-1 text-xs rounded bg-[#1FBE42] text-white "
                      >
                        View
                      </button>
                      <button
                        onClick={() => {
                          setCreateModal(true);
                          setselectedappid(item.id);
                          console.log(item);
                        }}
                        className="flex items-center justify-center font-semibold bg-blue-500 py-2 px-1 rounded-md text-xs text-white"
                      >
                        <TbHealthRecognition
                          size={20}
                          className="inline text-green-300"
                          color=""
                        />
                        Take Vitals
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      {/* Table Footer (Pagination) */}
      {/* Table Footer (Pagination) */}
      <div className="w-full flex items-center justify-between">
        {/* Numbers */}
        <div>
          <p className="sub-heading text-black">{`${currentPage} of ${totalPages} Pages`}</p>
        </div>
        {/* NExt and Previous Btn */}
        {/* Pagination */}
        <div className="px-6 py-6">
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"inline-flex items-center -space-x-px"}
            pageClassName={
              "px-3 py-2 leading-tight text-sm text-gray-500 border border-gray-300 hover:text-gray-700 hover:bg-purple-300 hover:text-white"
            }
            pageLinkClassName={""}
            previousClassName={
              "block px-3 py-2 text-sm ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-purple-400 hover:text-white"
            }
            previousLinkClassName={""}
            nextClassName={
              "block px-3 py-2 leading-tight text-sm text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-purple-400 hover:text-white"
            }
            nextLinkClassName={""}
            breakClassName={
              "px-3 py-2 leading-tight text-sm text-gray-500 bg-white border border-gray-300 hover:bg-purple-400 hover:text-white"
            }
            breakLinkClassName={
              "px-3 py-2 leading-tight text-sm text-gray-500 bg-white border border-gray-300 hover:bg-purple-400 hover:text-white"
            }
            activeClassName={
              "z-10 px-3 py-2 leading-tight text-white border border-primary bg-primary hover:bg-purple-100 hover:text-primary"
            }
          />
        </div>
      </div>
    </div>
  );
};

const Modal = ({ setCreateModal, refresh, apid, appointmentdetails }: any) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const { config } = useAuthContext();
  const { control, handleSubmit, watch } = useForm();
  const [selectedvitals, setselectedvitals] = useState<any>([]);
  const [allvitals, setallvitals] = useState<any>([]);
  const { id, appoinmentid, recordid } = useParams();

  const getAllVitals = async () => {
    var res = await GetAllvitals(config);
    setallvitals(res.data);
  };

  useEffect(() => {
    console.log(selectedvitals);
  }, [selectedvitals]);

  useEffect(() => {
    getAllVitals();
  }, []);

  const onSubmit = async (data: any) => {
    console.log(data);
    setLoading(true);
    try {
      await Promise.all(
        selectedvitals.map(async (vitalid: any) => {
          let postdaata = {
            vitalID: vitalid, // Required - ID of the vital type (e.g., Blood Pressure)
            appointmentID: apid, // Required - Existing record ID
            patientID: id, // Required - ID of the patient
            nurseID: appointmentdetails?.doctorID, // Required - ID of the nurse who recorded the vitals
            vitalResults: data[`value${vitalid}`], //Required - Vital sign results
          };

          var res = await AddpatientVital(postdaata, config);
        })
      );
      setLoading(false);
      setCreateModal(false);
      toast.success("Vitals added");
      refresh();
    } catch (error: any) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="h-screen w-screen bg-[#2020203f] flex fixed top-0 left-0 pt-10 justify-center items-center">
      <Spinner loading={loading} />
      <div className="w-[30%] bg-white shadow-md rounded-md px-4 pt-4 max-h-[500px] overflow-y-scroll">
        {/* Header */}
        <div className="w-full flex justify-between items-center">
          <h2 className="text-sm font-medium text-black">
            Take patient's vitals
          </h2>
          <FaRegTimesCircle
            className="cursor-pointer text-gray-500"
            onClick={() => setCreateModal(false)}
          />
        </div>
        {/* Form Content */}
        <div className={""}>
          <SelectField
            control={control}
            name="previtals"
            placeholder="Select"
            onselect={(val: any) => {
              setselectedvitals((prev: any) => prev.concat([val]));
            }}
            title="Add vital"
            isValue
            options={allvitals?.map((ict: any) => {
              return { label: `${ict?.name} (${ict?.unit})`, value: ict?.id };
            })}
          />
          <div className="flex flex-col p-3 border border-1 rounded border-gray-500">
            {selectedvitals.map((vitalitem: any) => (
              <div className="flex flex-col ">
                <FaRegTimesCircle
                  className="cursor-pointer text-gray-300 self-end"
                  onClick={() => {
                    setselectedvitals((prev: any) =>
                      prev.filter((ik: any) => ik != vitalitem)
                    );
                  }}
                />
                <p className="text-xs">
                  {allvitals?.filter((kl: any) => kl.id == vitalitem)[0]?.name}{" "}
                  ({allvitals?.filter((kl: any) => kl.id == vitalitem)[0]?.unit}
                  )
                </p>
                <InputField
                  control={control}
                  name={`value${vitalitem}`}
                  placeholder="Value"
                  title="Value"
                />
              </div>
            ))}
          </div>
        </div>
        {/* Button */}
        <div className="w-full flex items-center justify-end gap-5">
          <Button
            text="Cancel"
            width="100"
            OnBtnClick={() => setCreateModal(false)}
            color="black"
            outlined
            borderColor="gray-300"
          />
          <Button text="Save" width="180" OnBtnClick={handleSubmit(onSubmit)} />
        </div>
      </div>
    </div>
  );
};
