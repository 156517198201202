import React, { useState } from "react";
import { Link } from "react-router-dom";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { Button } from "../../form";
import { TableData } from "../../layouts/table/Table";
import ViewDocModal from "../../Modals/ViewDocModal";

const DeptDocumentListings = ({items}:any) => {
 

  const [showmodal,setshowmodal]=useState(false)
const [src, setsrc]=useState("")

  const tbHeader = ["No.", "Document Title", "Added Date"];
  return (
    <div className="overflow-x-auto">

      {showmodal && <ViewDocModal show={setshowmodal} src={src}/>}
      <table className="w-full bg-white">
        <thead className="w-full bg-[#EAECF0]">
          <tr className="w-full">
            {tbHeader.map((item, index) => (
              <th
                key={index}
                scope="col"
                className="px-6 py-3 uppercase text-left text-xs font-semibold whitespace-nowrap"
              >
                {item}
              </th>
            ))}
            <th
              scope="col"
              className="px-6 py-3 uppercase text-left text-xs font-semibold whitespace-nowrap"
            >
              {"Actions"}
            </th>
          </tr>
        </thead>
        {/* Table content */}
        <tbody className="w-full bg-white">
          {items?.map((item:any, index:any) => (
            <tr
              className="bg-white border-b pt-8  hover:bg-gray-50"
              key={index}
            >
              <TableData item={(index + 1).toString()} />
              <TableData item={item?.title} />
              <TableData item={item?.createdAt} />
              <td>
                <div className="flex items-center px-6 py-3">
                   
                    <MdOutlineRemoveRedEye onClick={()=>{
                      setsrc(item.documentURL)
                      setshowmodal(true)


                    }} className=" text-blue-600 mx-4 text-lg" />
                
                  <RiDeleteBin6Line className="text-red-600 text-lg" />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* Table Footer (Pagination) */}
      <div className="w-full flex items-center justify-between">
        {/* Numbers */}
        <div>
          <p className="sub-heading text-black">1 of 40 Pages</p>
        </div>
        {/* NExt and Previous Btn */}
        <div className="flex items-center justify-between space-x-5">
          <Button
            width="100"
            text="Previous"
            outlined
            borderColor="gray-600"
            color="black"
            OnBtnClick={() => {}}
          />
          <Button
            width="100"
            text="Next"
            outlined
            borderColor="gray-600"
            color="black"
            OnBtnClick={() => {}}
          />
        </div>
      </div>
    </div>
  );
};

export default DeptDocumentListings;
