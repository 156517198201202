import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HospitalProtectedRoutes from "./HospitalProtectedRoutes";
import { Dashboard, HospitalOnboarding } from "../pages/hospitals";
import {
  EditProfile,
  ForgotPassword,
  Login,
  Profile,
  ResetPasswordSetup,
} from "../pages/hospitals/Auth";
import { BaseContainer } from "../components/layouts";
import {
  AddDept,
  AddDeptStaff,
  DeptListings,
  EditDept,
} from "../pages/hospitals/Dept";
import {
  AddStaff,
  EditStaffDetails,
  StaffDetails,
  StaffListings,
} from "../pages/hospitals/Staff";
import { DeptDetails } from "../components/hospitals/dept";
import {
  AddPatient,
  PatientDetails,
  PatientListing,
} from "../pages/hospitals/Patient";
import EditPatientDetails from "../pages/hospitals/Patient/EditPatientDetails";
import { PermissionHome } from "../pages/hospitals/Permission";
import { SettingsIndex } from "../pages/hospitals/Settings";
import { InvoiceListings } from "../pages/hospitals/Invoice";
import { ServicesIndex } from "../pages/hospitals/Services";
import { Categories } from "../pages/hospitals/Category";
import EditNOK from "../pages/hospitals/Patient/EditNOK";
import EditInsurance from "../pages/hospitals/Patient/EditInsurance";
import EditFamily from "../pages/hospitals/Patient/EditFamily";
import AppointmensRecords from "../components/hospitals/patient/AppointmensRecords";
import UpdateAppointmentrecord from "../components/hospitals/patient/UpdateAppointmentrecord";
import { CreateAppointment } from "../utils/services/api/hospital/appointment";
import CreateAppointmentrecord from "../components/hospitals/patient/CreateAppointmentrecord";
import CreateInvoice from "../pages/hospitals/Invoice/CreateInvoice";
import CreateInvoicePatient from "../pages/hospitals/Invoice/CreateInvoicePatient";

const HospitalRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HospitalProtectedRoutes />}>
          <Route path="/" element={<BaseContainer />}>
            <Route index element={<Dashboard />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/profile/edit" element={<EditProfile />} />
            {/* Dept Routes */}
            <Route path="/dept" element={<DeptListings />} />
            <Route path="/hospital/dept/add" element={<AddDept />} />
            <Route path="/hospital/dept/:id" element={<DeptDetails />} />
            <Route path="/hospital/dept/edit/:id" element={<EditDept />} />
            <Route
              path="/hospital/dept/:id/add-staff"
              element={<AddDeptStaff />}
            />
            {/* Staff */}
            <Route path="/hospital/staff" element={<StaffListings />} />
            <Route path="/hospital/staff/add" element={<AddStaff />} />
            <Route path="/hospital/staff/:id" element={<StaffDetails />} />
            <Route
              path="/hospital/staff/edit/:id"
              element={<EditStaffDetails />}
            />
            {/* Patient */}
            <Route path="/hospital/patients" element={<PatientListing />} />
            <Route path="/hospital/patients/add" element={<AddPatient />} />
            <Route
              path="/hospital/patients/appointments/"
              element={<AddPatient />}
            />
            <Route
              path="/hospital/patients/:id/appointments/:appoinmentid"
              element={<AppointmensRecords />}
            />
            <Route
              path="/hospital/patients/:id/appointments/:appoinmentid/createrecord"
              element={<CreateAppointmentrecord />}
            />
            <Route
              path="/hospital/patients/:id/appointments/:appoinmentid/updaterecord/:recordid"
              element={<UpdateAppointmentrecord />}
            />
            <Route path="/hospital/patient/:id" element={<PatientDetails />} />
            <Route
              path="/hospital/patient/edit/:id"
              element={<EditPatientDetails />}
            />
            <Route path="/hospital/nok/edit/:id" element={<EditNOK />} />
            <Route
              path="/hospital/insurance/edit/:id"
              element={<EditInsurance />}
            />
            <Route path="/hospital/family/edit/:id" element={<EditFamily />} />
            {/* Permissions */}
            <Route path="/hospital/permissions" element={<PermissionHome />} />
            {/* invoice */}
            <Route path="/hospital/invoice" element={<InvoiceListings />} />
            <Route path="/hospital/invoice/create" element={<CreateInvoice />} />
            <Route path="/hospital/invoice/create/patient/:id" element={<CreateInvoicePatient />} />
            {/* categories */}
            <Route path="/hospital/categories" element={<ServicesIndex />} />
            {/* services */}
            <Route
              path="/hospital/category-services/:id"
              element={<ServicesIndex />}
            />
            {/* Permissions */}
            <Route path="/hospital/settings" element={<SettingsIndex />} />
          </Route>
        </Route>
        {/* Unprotected Routes */}
        <Route path="/hospital/login" element={<Login />} />
        <Route path="/hospital/register" element={<HospitalOnboarding />} />
        <Route path="/hospital/forgot-password" element={<ForgotPassword />} />
        <Route
          path="/hospital/reset-password/:email"
          element={<ResetPasswordSetup />}
        />
      </Routes>
    </Router>
  );
};

export default HospitalRoutes;
