import React, { useState } from "react";
import { turgl } from "../../../constants/imports";
import { Button, InputField } from "../../../components/form";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner } from "../../../components/generic";
import { ResetPassword } from "../../../utils/services/api/hospital/auth";

const ResetPasswordSetup = () => {
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit } = useForm();
  const navigate = useNavigate();
  const params = useParams();
  const { email } = params;
  const onSubmit = async (data: any) => {
    if (data?.password !== data?.confirmpassword) {
      toast.error("Passwords Doesn't Match");
    } else {
      setLoading(true);
      const postData = {
        email: email,
        resetcode: data.resetcode,
        newpass: data.password,
      };
      try {
        await ResetPassword(postData);
        toast.success(
          "Password Reset Successfully, Please Login with your new password"
        );
        navigate("/hospital/login");
      } catch (error: any) {
        toast.error(error);
      }
      setLoading(false);
    }
  };
  return (
    <div className=" bg-lightBg w-full h-[100vh] flex justify-center items-center">
      <Spinner loading={loading} />
      <div className="md:w-[35%] px-12 w-full">
        {/* Header */}
        <div className="w-full flex justify-center items-center mb-3">
          <img src={turgl} alt="Logo" className="text-center" />
        </div>
        <h1 className="text-lg mb-5 font-medium text-black text-center">
          Setup New Password
        </h1>
        {/* Email and Password Container */}
        <div className="w-full p-8 bg-darkBg rounded-lg">
          <p className="text-xs ">
            Setup your new Password, using the Password Reset Code sent your
            email
          </p>
          <InputField
            name="resetcode"
            title="Reset Code"
            placeholder="Reset Code"
            control={control}
            rules={{
              required: "Reset Code is required",
            }}
          />
          <InputField
            name="password"
            title="Password"
            placeholder="Enter Password"
            control={control}
            rules={{
              required: "Password is required",
            }}
            type={"password"}
          />
          <InputField
            name="confirmpassword"
            title=" Confirm Password"
            placeholder="Enter Password Again"
            control={control}
            rules={{
              required: "Confirm Password is required",
            }}
            type={"password"}
          />
        </div>
        {/* Button and Forgit Passwords */}
        <div className="w-full flex items-center justify-between">
          <div />
          <div className="w-[150px] my-1">
            <Button text="Reset Password" OnBtnClick={handleSubmit(onSubmit)} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordSetup;
