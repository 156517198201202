import { useState, useEffect } from "react";
import { useAuthContext } from "../context/AuthContext";
import {
  DeletePermissionByID,
  GetAllPermission,
} from "../utils/services/api/hospital/permissions";
import { toast } from "react-toastify";

const usePermissionHook = () => {
  const [permissions, setPermissions] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [isChange, setIsChange] = useState(false);
  const { config, authUser } = useAuthContext();
  const onGetAllPermission = async () => {
    try {
      setLoading(true);
      const temp = await GetAllPermission(config);
      setPermissions(temp?.data);
      setIsChange(true);
      // setTotalPages(temp?.pagination?.totalPages);
      // setTotal(temp?.pagination?.totalRecords);
      // setCurrentPage(temp.pagination?.currentPage);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setPermissions([]);
    }
  };

  const onDeletePermissionStaff = async (id: any) => {
    setLoading(true);
    try {
      await DeletePermissionByID(id, config);
      await onGetAllPermission();
      toast.success("Role Deleted Successfully");
      setIsChange(true);
    } catch (error: any) {
      toast.error(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    onGetAllPermission();
    // eslint-disable-next-line
  }, [authUser, isChange]);
  return {
    permissions,
    total,
    currentPage,
    setCurrentPage,
    totalPages,
    loading,
    setSearch,
    onGetAllPermission,
    setIsChange,
    onDeletePermissionStaff,
    setPermissions,
  };
};
export default usePermissionHook;
