import axios from "axios";
import { baseURL } from "../../../URL";
import { HospitalOnBoardingDTO } from "../../../typings/hostpital";
import { LoginDto } from "../../../typings/auth";

const OnBoardingHostpital = async (postData: HospitalOnBoardingDTO) => {
    try {
        const response = await axios.post(`${baseURL}/hospitals`, postData);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};
const HostpitalLogin = async (postData: LoginDto) => {
    try {
        const response = await axios.post(`${baseURL}/auth/login`, postData);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};

const UpdateHostpitalRecords = async (updateData: any, hostpitalId: string, config: any) => {
    try {
        const response = await axios.patch(`${baseURL}/hospitals/${hostpitalId}`, updateData, config);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};
const UserChangePassword = async (postData: any, config: any) => {
    try {
        const response = await axios.post(`${baseURL}/hospitals/changepassword`, postData, config);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};
const GetPasswordResetOTP = async (postData: any) => {
    try {
        const response = await axios.post(`${baseURL}/hospitals/resetpassword`, postData);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};
const ResetPassword = async (postData: any) => {
    try {
        const response = await axios.post(`${baseURL}/hospitals/changepasswordreset`, postData);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};
export { OnBoardingHostpital, HostpitalLogin, UpdateHostpitalRecords, UserChangePassword, GetPasswordResetOTP, ResetPassword }