import React, { useEffect, useState } from "react";
import { GoInbox, GoPlus } from "react-icons/go";
import { addrecord, empty } from "../../../constants/imports";
import { IoSearch } from "react-icons/io5";
import useStaffHook from "../../../hooks/useStaffHook";
import { StaffTable } from "../../layouts/table";
import { PulseLoader } from "react-spinners";
import { Button, InputField, SelectField } from "../../form";
import { FaRegTimesCircle } from "react-icons/fa";
import { textReg } from "../../../utils/regex";
import { useForm } from "react-hook-form";
import { MultiSelect, Spinner } from "../../generic";
import { toast } from "react-toastify";
import { useAuthContext } from "../../../context/AuthContext";
import {
  AddPermission,
  onUploadFile,
} from "../../../utils/services/api/hospital/services";
import { TbHealthRecognition } from "react-icons/tb";

import { IoIosArrowBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { FaPlus } from "react-icons/fa6";
import {
  AddpatientVital,
  CreateAppointmentrecord,
  GetAllvitals,
  GetAppointmentfulldetails,
  GetAppointmentVitals,
  GetRecordbyid,
  UpdateRecord,
} from "../../../utils/services/api/hospital/appointment";
import { MdCancel, MdCheck } from "react-icons/md";
import usePatientHook from "../../../hooks/usePatientHook";
import useFilePicker from "../../../hooks/useFilePicker";

export default () => {
  const roles = [];
  const navigate = useNavigate();
  const [appointmentdetails, setappointmentdetails] = useState<any>({});
  const { config } = useAuthContext();
  const { id, appoinmentid, recordid } = useParams();
  const [isloading, setLoading] = useState(false);
  const { patients } = usePatientHook();
  const { staff } = useStaffHook();
  const { control, handleSubmit, setValue } = useForm();
  const [recordDetails, setrecorddetails] = useState<any>({});
  const [recvitals, setrecvitals] = useState<any>([]);

  const getrecorddetails = async () => {
    setLoading(true);
    var res = await GetRecordbyid(recordid, config);
    setrecorddetails(res.data);
    setLoading(false);
  };
  var { file, setfile, refreshfile } = useFilePicker();
  const stf: any = staff;
  const pickfile = () => {
    document.getElementById("selectlogo")?.click();
  };

  const pat: any = patients;

  const getappointmentdetails = async () => {
    setLoading(true);

    try {
      var res = await GetAppointmentfulldetails(appoinmentid, config);
      setappointmentdetails(res.data);
    } catch (e: any) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const getRecordVitals = async () => {
    setLoading(true);
    try {
      var res = await GetAppointmentVitals(appoinmentid, config);
      setrecvitals(res.data);
    } catch (e: unknown) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getappointmentdetails();
    getrecorddetails();
    getRecordVitals();
  }, []);

  useEffect(() => {
    setValue("diagnosis", recordDetails?.diagnosis);
    setValue("treatment", recordDetails?.diagnosis);
    setValue("prescription", recordDetails?.diagnosis);
    setValue("notes", recordDetails?.notes);
    setValue("comment", recordDetails?.diagnosis);
  }, [recordDetails]);

  const onsubmit = async (data: any) => {
    setLoading(true);

    var postData = {
      ...data,
    };
    try {
      if (file) {
        let formData = new FormData();
        formData.append("file", file);
        let uploadFileres = await onUploadFile(formData, config);
        if (uploadFileres.success) {
          toast.success(uploadFileres.message);
          postData = { ...postData, documentURL: uploadFileres.data };
        }
      }

      var res1 = await UpdateRecord(recordid, postData, config);

      if (res1.success) {
        toast.success("Record updated successfuly");
        navigate(-1);
      }
    } catch (e: any) {
      toast.error(e);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="w-full bg-white p-5">
      {/* Header */}
      <div className="">
        <div className="flex">
          <button
            onClick={() => navigate(-1)}
            className="flex items-center justify-center  bg-blue-500 py-1 pr-2 rounded-md text-xs text-white"
          >
            <IoIosArrowBack className="inline" color="white" /> Back{" "}
          </button>
          <h1 className="text-md ml-2 font-medium text-black">
            {pat.filter((kj: any) => kj.id == id)[0]?.firstName}
            {pat.filter((kj: any) => kj.id == id)[0]?.lastName}
          </h1>
        </div>
        <p className="text-xs text-black mt-1">
          Access and manage patient information and appointments.
        </p>
      </div>
      {/* Seacrh and Filter */}
      <div className="flex justify-between my-3"></div>
      {/* COntent */}

      <div>
        {isloading ? (
          <div className=" m-12  justify-items-center justify-center">
            <PulseLoader
              className=" m-12 justify-center"
              color={"#350080"}
              loading={isloading}
              size={14}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ) : (
          <>
            <div className="w-full flex mt-4">
              <div className="w-[25%]">
                <p className="text-sm font-semibold text-black mt-1">
                  Update record
                </p>
                <p className="text-xs text-gray-500 mt-1">
                  Reason for appointment
                </p>
                <p className="text-sm text-black mt-1">
                  {appointmentdetails?.appointment?.reason}
                </p>
                <div className="w-full bg-[#E8EDFB] px-3 py-6 my-4 flex flex-col gap-4 rounded-[10px]">
                  <p className="text-sm mt-1 flex justify-between">
                    <span> Presiding Doctor/Nurse </span>{" "}
                    <span className="font-semibold">
                      {
                        stf.filter(
                          (ik: any) =>
                            ik.id == appointmentdetails?.appointment?.doctorID
                        )[0]?.firstName
                      }{" "}
                      {
                        stf.filter(
                          (ik: any) =>
                            ik.id == appointmentdetails?.appointment?.doctorID
                        )[0]?.lastName
                      }
                    </span>
                  </p>
                  <p className="text-sm semibold flex justify-between mt-1">
                    <span>Date and Time</span>
                    <span className="font-semibold">
                      {`${appointmentdetails?.appointment?.date}${appointmentdetails?.appointment?.time}`}{" "}
                    </span>
                  </p>
                </div>

                <div className="text-sm  p-3 border border-gray-400 rounded my-2">
                  <p className=" font-semibold text-gray-600 uppercase">
                    Vitals
                  </p>
                  <hr />
                  {recvitals.map((vititem: any) => (
                    <div>
                      <p>{vititem.vital.name}</p>
                      <p className="text-green-500">{vititem.vitalResults}</p>
                    </div>
                  ))}
                </div>
              </div>

              <div className="w-[75%] mx-12 ">
                <div>
                  <InputField
                    name="diagnosis"
                    title="Diagnosis"
                    placeholder="Diagnosis"
                    control={control}
                  />
                  <InputField
                    name="prognosis "
                    title="Prognosis "
                    placeholder="Prognosis "
                    control={control}
                  />

                  <InputField
                    name="treatment"
                    title="Treatment"
                    placeholder="Treatment (Optional)"
                    control={control}
                  />
                  <InputField
                    name="prescription"
                    title="Prescription"
                    placeholder="Prescription (Optional)"
                    control={control}
                  />
                  {/* <InputField
                    control={control}
                    name="recordDate"
                    placeholder="Date"
                    title="Date"
                    type="date"
                  /> */}

                  <InputField
                    name="notes"
                    title="Note"
                    placeholder="Description for record"
                    control={control}
                    isTextArea
                  />
                  <InputField
                    name="comment"
                    title="Comment"
                    placeholder="Additional comment"
                    control={control}
                  />
                  {
                    <div>
                      <p> Document </p>
                      <a
                        target="blank"
                        className="text-sm text-blue-400"
                        href={recordDetails?.documentURL}
                      >
                        Document 1{"View"}
                      </a>
                    </div>
                  }
                  <div className="bg-gray-100 rounded border flex flex-col justify-center items-center p-4">
                    <GoInbox size={40} className="text-[#1677FF]" />
                    <p className="text-gray-400 text-sm">
                      Upload document to record
                    </p>

                    <div className="bg-gray-50 rounded flex items-center w-full border justify-between">
                      <div
                        onClick={pickfile}
                        className=" border border-gray-300 rounded w-[30%] flex justify-center items-center p-2 m-1 font-light text-xs  cursor-pointer"
                      >
                        Select file
                      </div>
                      <p className="text-sm font-light jutify-self-start cursor-pointer     text-textbg  ml-3 truncate ">
                        {!(file == undefined) ? file.name : ""}
                      </p>
                      {!(file == undefined) && (
                        <MdCancel
                          onClick={() => {
                            refreshfile();
                          }}
                          size={20}
                          className="mr-2 "
                        />
                      )}
                      <input
                        onChange={(event) => {
                          const files: any = event.target.files;
                          const file = files[0];
                          setfile(file);
                        }}
                        id="selectlogo"
                        type="file"
                        className=" hidden "
                      />
                    </div>
                  </div>

                  <Button
                    text=" Save Record"
                    width="100"
                    OnBtnClick={handleSubmit(onsubmit)}
                    color="black"
                    borderColor="gray-300"
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
