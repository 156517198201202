import { resolveTxt } from "dns";
import { Button, InputField, SelectField } from "../../../components/form";
import { useForm } from "react-hook-form";
import { emailReg, textReg } from "../../../utils/regex";
import { useEffect, useState } from "react";
import { onAddDependant } from "../../../utils/services/api/hospital/patient";
import { useAuthContext } from "../../../context/AuthContext";
import { toast } from "react-toastify";
import { Spinner } from "../../../components/generic";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { FaPlus, FaStar } from "react-icons/fa6";
import { MdClose } from "react-icons/md";
import { FaRegTimesCircle } from "react-icons/fa";
import useCategoryService from "../../../query/useCategoryService";
import ReactPaginate from "react-paginate";
import { RiCloseLine } from "react-icons/ri";
import { TableData } from "../../../components/layouts/table/Table";
import usePatientHook from "../../../hooks/usePatientHook";
import { RiMailSendLine } from "react-icons/ri";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { onCreateInvoice } from "../../../utils/services/api/hospital/invoice";

export default (props: any) => {
  const { handleSubmit, control, watch } = useForm();

  const [selectedITems, setSelectedItems] = useState([]);
  const { services } = useCategoryService(1);
  const navigate = useNavigate();
  const { patients } = usePatientHook();
  const [loading, setLoading] = useState(false);
  const [totalamount, settotalamount] = useState("0");
  const { config } = useAuthContext();
  useEffect(() => {
    console.log(selectedITems);
  }, [selectedITems]);
  const [showaddModal, setshowaddmodal] = useState<boolean>(false);
  const ItemsTable = () => {
    const tbHeader = [
      "Items",
      "Description",
      "Quantity",
      "Rate",
      "Discount",
      "Amount",
      "Action",
    ];

    useEffect(() => {
      let total = 0;
      selectedITems.map((item: any) => {
        total +=
          parseInt(
            services.filter((j: any) => j.id == item?.value)[0]?.amount
          ) * parseInt(item.quantity);
      });
      let formattedAmount = new Intl.NumberFormat("en-NG", {
        style: "currency",
        currency: "NGN",
      }).format(total);
      settotalamount(formattedAmount);
      console.log(total);
    }, [selectedITems]);

    return (
      <div className="overflow-x-auto my-4">
        <table className="w-full bg-white">
          <thead className="w-full bg-gray-100">
            <tr className="w-full">
              {tbHeader.map((item, index) => (
                <th
                  key={index}
                  scope="col"
                  className="px-6 py-3 capitalize text-gray-500 text-left text-xs  "
                >
                  {item}
                </th>
              ))}
            </tr>
          </thead>
          {/* Table content */}
          <tbody className="w-full bg-white">
            {selectedITems.map((item: any, index: any) => (
              <tr
                className="bg-white border-b pt-8  hover:bg-gray-50"
                key={index}
              >
                <TableData
                  item={`${
                    services.filter((j: any) => j.id == item?.value)[0]?.ssName
                  } `}
                />
                <TableData
                  item={`${
                    services.filter((j: any) => j.id == item?.value)[0]
                      ?.description
                  }`}
                />
                <TableData item={item?.quantity} />
                <TableData
                  item={`${parseInt(
                    services.filter((j: any) => j.id == item?.value)[0]?.amount
                  )}`}
                />
                <TableData item={`0.00`} />
                <TableData
                  item={`${
                    parseInt(
                      services.filter((j: any) => j.id == item?.value)[0]
                        ?.amount
                    ) * parseInt(item.quantity)
                  }`}
                />
                <td>
                  <div className="flex items-center px-6 py-3">
                    <IoIosCloseCircleOutline
                      className="text-red-600 text-lg cursor-pointer"
                      onClick={() =>
                        setSelectedItems((prev: any) =>
                          prev.filter((kl: any) => kl != item)
                        )
                      }
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const onSubmit = async (data: any) => {
    var servicesarray = selectedITems.map((i: any) => {
      return {
        service: services.filter((j: any) => j.id == i?.value)[0]?.ssName,
        quantity: i.quantity,
        amount: parseInt(
          services.filter((j: any) => j.id == i?.value)[0]?.amount
        ),
      };
    });

    console.log(servicesarray);

    var postdata = {
      patientID: data.patient,
      dueDate: data.duedate,
      items: servicesarray,
    };
    setLoading(true);
    try {
      var response = await onCreateInvoice(postdata, config);
      response.message && toast(response.message);
      navigate(-1);
    } catch (e: any) {
      console.log(e);
      toast.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="my-5 w-full px-12 ">
      <Spinner loading={loading} />
      {showaddModal && (
        <Modal show={setshowaddmodal} setSelectedItems={setSelectedItems} />
      )}
      <h1 className="text-xl font-semibold text-black mb-3">Create invoice </h1>
      <small>Add invoice from services.</small>
      <div className="grid grid-cols-2 px-4 gap-4">
        <SelectField
          control={control}
          name="patient"
          isValue
          rules={{ required: "patient is required" }}
          placeholder="Select"
          title="Patient"
          options={patients.map((kl: any) => {
            return { label: `${kl.firstName} ${kl.lastName}`, value: kl.id };
          })}
        />

        <InputField
          control={control}
          name="duedate"
          placeholder="Select a date"
          title="Due Date"
          type="date" // Set type to "date" for a date input
          rules={{ required: "Due Date is required" }}
        />
      </div>
      {/* form Content */}
      {selectedITems.length > 0 && (
        <div className="">
          <ItemsTable />

          <div className="flex justify-between items-center mt-5 mb-10">
            <button
              className="bg-green-500 text-sm px-3 py-1 rounded text-white"
              onClick={() => {
                setshowaddmodal(true);
              }}
            >
              <FaPlus className="inline " /> Add Item{" "}
            </button>

            <div className="">
              <div className="  flex flex-col gap-2  m-2  text-sm">
                <p className="flex  gap-4 justify-between ">
                  <span className="text-gray-400 text-sm">Total</span>{" "}
                  <span className="text-black">{totalamount}</span>
                </p>
                <p className="flex gap-4  justify-between ">
                  <span className="text-yellow-400 text-sm">
                    Loyalty Discount <FaStar className="inline" />{" "}
                  </span>{" "}
                  <span>0%</span>
                </p>
                <hr />
                <p className="flex gap-4  justify-between ">
                  <span className="text-gray-400 text-sm">Total</span>{" "}
                  <span className="text-xs">{totalamount}</span>
                </p>
                <p className="flex  gap-4  justify-between ">
                  <span className="text-gray-400 text-xs">VAT (%):</span>{" "}
                  <span className="text-xs">0.00</span>
                </p>
                <span className="text-black text-sm  self-end font-semibold">
                  {totalamount}
                </span>{" "}
              </div>
              <button
                className="bg-blue-500 text-sm px-3 py-2 rounded text-white"
                onClick={handleSubmit(onSubmit)}
              >
                <RiMailSendLine className="inline " /> Save and send{" "}
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Button */}
      {selectedITems.length == 0 && (
        <div className="bg-gray-200 flex py-6 mt-4 rounded justify-center">
          <button
            className="bg-green-500 text-sm px-3 py-1 rounded text-white"
            onClick={() => {
              setshowaddmodal(true);
            }}
          >
            <FaPlus className="inline " /> Add Items
          </button>
        </div>
      )}
    </div>
  );
};

const Modal = ({ item, setSelectedItems, show }: any) => {
  const [loading, setLoading] = useState(false);
  const { config } = useAuthContext();
  const { control, handleSubmit, getValues } = useForm();
  const [selected, setselected] = useState();
  const { services } = useCategoryService(1);



  const onSubmit =(data:any)=>{
     

      setSelectedItems((prev: any) =>
        prev.concat([{ value: data.item, quantity: data.quantity }])
      );
      show(false);
 

  }
  return (
    <div className="h-full w-full fixed  top-24 left-0 pt-10">
      <Spinner loading={loading} />
      <div className="w-full h-full flex justify-center items-start">
        <div className="w-[30%] bg-white shadow-md rounded-md px-8 pt-4">
          {/* Header */}
          <div className="w-full flex justify-between items-center">
            <h2 className="text-sm font-medium text-black">Add Item</h2>
            <FaRegTimesCircle
              className="cursor-pointer text-gray-300"
              onClick={() => {
                show(false);
              }}
            />
          </div>
          <SelectField
            control={control}
            name={"item"}
            rules={{ required: "required" }}
            placeholder="Select"
            isValue
            title="Item"
            options={services.map((item, index: number) => {
              return { label: item.ssName, value: item.id };
            })}
          />

          <InputField
            control={control}
            name={`quantity`}
            placeholder="Enter quntity"
            title="Quantity"
            type="number"
            rules={{ required: "required" }}
          />
          {/* Button */}
          <div className="w-full flex items-center justify-end gap-5">
            <Button
              text="Cancel"
              width="100"
              OnBtnClick={() => {
                show(false);
              }}
              color="black"
              outlined
              borderColor="gray-300"
            />
            <Button
              text={"Add"}
              width="100"
              OnBtnClick={handleSubmit(onSubmit)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
