import React, { useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { Button, InputField, SelectField } from "../../../components/form";
import { useForm } from "react-hook-form";
import { emailReg, textReg } from "../../../utils/regex";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuthContext } from "../../../context/AuthContext";
import { Spinner } from "../../../components/generic";
import countries from "../../../constants/data/countries.json";
import { UpdatePatientByID } from "../../../utils/services/api/hospital/patient";
import usePatientDetails from "../../../query/usePatientDetails";

interface StateDto {
  code: string;
  name: string;
  subdivision: null;
}

interface CountryDto {
  code2: string;
  code3: string;
  name: string;
  capital: string;
  region: string;
  subregion: string;
  states: StateDto[];
}
const EditPatientDetails = () => {
  const [stateNames, setStateNames] = useState<string[]>([]);
  const [temp, setTemp] = useState<StateDto[] | null>(null);
  const [AllCountries, setAllCountries] = useState<CountryDto[]>([]);
  const [loading, setLoading] = useState(false);
  const { control, watch, getValues, handleSubmit, setValue } = useForm();
  const { config } = useAuthContext();
  const params = useParams();
  const { id } = params;
  const { patient } = usePatientDetails(id);
  const row = "w-full block md:flex justify-between items-center";
  const col = "w-full md:w-[30%]";
  const countryNames: string[] = [];
  for (const item of (countries || []) as CountryDto[]) {
    if (item?.name !== undefined && item?.name !== "") {
      countryNames.push(item.name);
    }
  }
  useEffect(() => {
    setValue("firstName", patient?.firstName);
    setValue("lastName", patient?.lastName);
    setValue("phone", patient?.phoneNumber);
    setValue("email", patient?.email);
    setValue("gender", patient?.gender);
    setValue("dob", patient?.dob);
    setValue("address", patient?.address);
    setValue("emergencyphone", patient?.emergencyphone);
    setValue("state", patient?.state);
    setValue("country", patient?.country);
  
  }, [patient, setValue]);
  useEffect(() => {
    setAllCountries(countries as never);
  }, []);
  useEffect(() => {
    const selectedCountry = getValues("country");
    const filteredCountry = AllCountries.find(
      (country) => country.name === selectedCountry
    );
    setTemp(filteredCountry?.states || null);
    // eslint-disable-next-line
  }, [AllCountries, getValues, watch("country")]);
  useEffect(() => {
    const selectedCountry = getValues("nok_country");
    const filteredCountry = AllCountries.find(
      (country) => country.name === selectedCountry
    );
    setTemp(filteredCountry?.states || null);
    // eslint-disable-next-line
  }, [AllCountries, getValues, watch("nok_country")]);

  useEffect(() => {
    const names = (temp || []).map((state) => state.name).filter(Boolean);
    setStateNames(names);
  }, [temp]);
  const navigate = useNavigate();
  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      await UpdatePatientByID(id, data, config);
      toast.success("Patient Details Updated");
      navigate(-1);
    } catch (error: any) {
      toast.error(error);
    }
    setLoading(false);
  };
  return (
    <>
      {patient && (
        <div className="w-full bg-lightBg p-5">
          <Spinner loading={loading} />
          {/* Header */}
          <div className="flex  justify-start items-start">
            {/* Back Button */}
            <button
              onClick={() => navigate(-1)}
              className="flex items-center justify-center bg-blue-500 py-1 pr-2 rounded-md"
            >
              <IoIosArrowBack color="white" />
              <p className="text-xs text-white">Back</p>
            </button>
            <div className="ml-4">
              <h1 className="text-lg font-medium text-black">{`${patient?.firstName} ${patient?.lastName}`}</h1>
              <p className="text-xs text-gray-600 mt-1">
                Manage and update this patient details
              </p>
            </div>
          </div>
          {/* Form Container */}
          <div className="my-8 w-full px-8">
            {/* form Content */}
            <div className="bg-darkBg py-4 px-8 rounded-sm w-full">
              {/* Form Row One (FirstName, LastName, Email) */}
              <div className={row}>
                {/* col 1 */}
                <div className={col}>
                  <InputField
                    name="firstName"
                    title="First Name"
                    placeholder="First Name"
                    control={control}
                    rules={{
                      required: "First Name is required",
                      pattern: {
                        value: textReg,
                        message: "Text Only",
                      },
                    }}
                  />
                </div>
                {/* Col 2 */}
                <div className={col}>
                  <InputField
                    name="lastName"
                    title="Last Name"
                    placeholder="Last Name"
                    control={control}
                    rules={{
                      required: "Last Name is required",
                      pattern: {
                        value: textReg,
                        message: "Text Only",
                      },
                    }}
                  />
                </div>
                {/* Col 3 */}
                <div className={col}>
                  <InputField
                    name="email"
                    title="Email"
                    placeholder="Email"
                    control={control}
                    rules={{
                      required: "Email is required",
                      pattern: {
                        value: emailReg,
                        message: "Invalid Email",
                      },
                    }}
                    readonly
                  />
                </div>
              </div>
              {/* Form Row Two (Phone Number, Gender, DOB) */}
              <div className={row}>
                {/* Col 1*/}
                <div className={col}>
                  <InputField
                    name="phone"
                    title="Phone Number"
                    placeholder="Phone Number"
                    control={control}
                    rules={{
                      required: "Phone Number is required",
                    }}
                    readonly
                  />
                </div>
                {/* Col 2 */}
                <div className={col}>
                  <SelectField
                    control={control}
                    name="gender"
                    rules={{ required: "Gender is required" }}
                    placeholder="Select"
                    title="Gender"
                    options={["Male", "Female"]}
                  />
                </div>
                {/* COl 3 DOB */}
                <div className={col}>
                  <InputField
                    control={control}
                    name="dob"
                    placeholder="Select a date"
                    title="Date of Birth"
                    type="date" // Set type to "date" for a date input
                    rules={{ required: "Date of Birth is required" }}
                  />
                </div>
              </div>

              {/* Form Row Three (Country and  States, Address) */}
              <div className={row}>
                {/* Col 1 */}
                <div className={col}>
                  <SelectField
                    control={control}
                    name="country"
                    placeholder="Select"
                    title="country"
                    options={countryNames}
                  />
                </div>
                {/* col 2 */}
                <div className={col}>
                  <SelectField
                    control={control}
                    name="state"
                    placeholder="Select"
                    title="State"
                    options={stateNames}
                  />
                </div>
                {/* Col 3 */}
                <div className={col}>
                  <InputField
                    name="address"
                    title="Address"
                    placeholder="Address"
                    control={control}
                  />
                </div>
              </div>
              {/* Form Row (EMegency Phone Number) */}
              <div className={row}>
                {/* Col 1 */}
                <div className={col}>
                  <InputField
                    name="emergencyphone"
                    title="Emergency Phone Contact"
                    placeholder="Emergency Phone Contact"
                    control={control}
                  />
                </div>
              </div>
            </div>  <div className="flex justify-end items-center  mb-10">
              <div className="md:w-[180px]">
                <Button
                  text="Save Changes"
                  OnBtnClick={handleSubmit(onSubmit)}
                />
              </div></div>
          </div>
        
        </div>
      )}
    </>
  );
};

export default EditPatientDetails;
