import React from "react";
import { FaCheck } from "react-icons/fa6";
import { Button } from "../../components/form";
const PricingContainer = ({ setShowPrice, setSelected }: any) => {
  const data = [
    {
      plan: "Starter",
      amount: "Free",
      items: [
        "10 Staff",
        "10 Staff",
        "10 Staff",
        "10 Staff",
        "10 Staff",
        "10 Staff",
        "10 Staff",
        "10 Staff",
        "10 Staff",
        "10 Staff",
        "10 Staff",
      ],
    },
    {
      plan: "Pro",
      amount: "N10,000",
      items: [
        "10 Staff",
        "10 Staff",
        "10 Staff",
        "10 Staff",
        "10 Staff",
        "10 Staff",
        "10 Staff",
        "10 Staff",
        "10 Staff",
        "10 Staff",
        "10 Staff",
        "10 Staff",
        "10 Staff",
        "10 Staff",
      ],
    },
    {
      plan: "Teams",
      amount: "N55,000",
      items: [
        "10 Staff",
        "10 Staff",
        "10 Staff",
        "10 Staff",
        "10 Staff",
        "10 Staff",
        "10 Staff",
        "10 Staff",
        "10 Staff",
        "10 Staff",
        "10 Staff",
      ],
    },
  ];
  return (
    <div className="w-full block md:flex justify-between items-center md:px-56 px-28 h-[100vh]">
      {data.map((item, index) => (
        <div
          className={`md:w-[31%] w-full rounded-md drop-shadow-md px-10 py-5 ${
            index === 1 ? "text-white bg-primary" : "bg-white text-black"
          }`}
          key={index}
        >
          <h1 className="text-xs">{item.plan}</h1>
          <h1 className="text-2xl font-bold mt-1">{item.amount}</h1>
          <p className="text-[10px] mb-3">Per Month</p>
          <div>
            <ul>
              {item.items.map((item, index) => (
                <li key={index} className="flex">
                  <FaCheck className="text-green-400" />
                  <p className="text-xs ml-2">{item}</p>
                </li>
              ))}
            </ul>
          </div>
          {/* Button */}
          <Button
            outlined={index === 0 ? true : false}
            text="Get"
            OnBtnClick={() => {
              setSelected(item);
              setShowPrice(false);
            }}
            color={index === 1 ? "black" : "primary"}
          />
        </div>
      ))}
    </div>
  );
};

export default PricingContainer;
