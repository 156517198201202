import React, { useEffect, useState } from "react";
import { GoPlus } from "react-icons/go";
import { empty } from "../../../constants/imports";
import { IoSearch } from "react-icons/io5";
import useStaffHook from "../../../hooks/useStaffHook";
import { StaffTable } from "../../../components/layouts/table";
import { PulseLoader } from "react-spinners";
import { Button, InputField, SelectField } from "../../../components/form";
import { FaRegTimesCircle } from "react-icons/fa";
import { textReg } from "../../../utils/regex";
import { useForm } from "react-hook-form";
import { MultiSelect, Spinner } from "../../../components/generic";
import { toast } from "react-toastify";
import { useAuthContext } from "../../../context/AuthContext";
import { AddPermission } from "../../../utils/services/api/hospital/services";
import { RiHealthBookLine } from "react-icons/ri";
import AppointmentTable from "../../layouts/table/AppointmentTable";
import { GetallHostpitalShifts } from "../../../utils/services/api/hospital/shifts";
import {
  CreateAppointment,
  GetPatientAppointment,
} from "../../../utils/services/api/hospital/appointment";
import { useParams } from "react-router-dom";

const AppointmentContainer = () => {
  const [createModal, setCreateModal] = useState(false);
  const roles = [];

  const [patientAppoinments, setpatientAppoinments] = useState([]);
  const { id } = useParams();
  const { config } = useAuthContext();
  const [loading, setLoading] = useState(false);

  const ongetPatientAppointments = async () => {
    setLoading(true);
    try {
      var res = await GetPatientAppointment(id, config);
      setpatientAppoinments(res.data);
    } catch (e: any) {
      toast.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    ongetPatientAppointments();
  }, []);
  return (
    <div className="w-full bg-white p-5">
      {/* Header */}
      {/* Seacrh and Filter */}
      <div className="flex justify-between my-3">
        <div className="flex gap-2">
          <div className="border-2 border-primary p-2 rounded-lg">
            <p className="text-sm">
              Completed Appointments{" "}
              <RiHealthBookLine size={30} className="inline text-red-400" />
            </p>
            <p className=" font-bold text-xl ">
              {
                patientAppoinments.filter((ik: any) => ik.status != "pending")
                  .length
              }
            </p>
          </div>

          <div className="border-2 border-primary p-2 rounded-lg">
            <p className="text-sm">
              Upcoming Appointments
              <RiHealthBookLine size={30} className="inline text-green-400" />
            </p>
            <p className=" font-bold text-xl ">
              {
                patientAppoinments.filter((ik: any) => ik.status == "pending")
                  .length
              }
            </p>
          </div>
        </div>

        <div className="flex items-center justify-end space-x-4 mb-5">
          {/* Search Field */}
          <div className="relative w-[300px]">
            <IoSearch className="absolute top-2 left-2 text-xl text-zinc-400" />
            <input
              type={"text"}
              placeholder={"Search"}
              onChange={(e: any) => {
                // setSearch(e.target.value)
              }}
              className={`pl-8 block bg-white text-xs py-2 px-4 rounded-md w-full shadow-xs border border-gray-300}`}
            />
          </div>
          <Button
            text="Create Appointment"
            width="180"
            OnBtnClick={() => setCreateModal(true)}
            color="white"
            Icon={GoPlus}
          />
        </div>
      </div>
      {/* COntent */}

      <div>
        {loading ? (
          <div className=" m-12  justify-items-center justify-center">
            <PulseLoader
              className=" m-12 justify-center"
              color={"#350080"}
              loading={loading}
              size={14}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ) : (
          <>
            {true ? (
              <AppointmentTable patientAppoinments={patientAppoinments} />
            ) : (
              <div className="w-full h-full flex justify-center items-center">
                <div>
                  <img
                    src={empty}
                    className="h-[100px] w-[100px] mx-auto"
                    alt="Empty"
                  />
                  <p className="sub-heading text-center">
                    No appointment yet for this patient
                  </p>
                  <Button
                    text="Create Appointment"
                    width="180"
                    OnBtnClick={() => setCreateModal(true)}
                    color="white"
                    Icon={GoPlus}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {/* Add Modal */}
      {createModal && (
        <Modal
          refresh={ongetPatientAppointments}
          setCreateModal={setCreateModal}
        />
      )}
    </div>
  );
};

export default AppointmentContainer;

const Modal = ({ setCreateModal, refresh }: any) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const { config } = useAuthContext();
  const { staff } = useStaffHook();
  const [allshifts, setallshifts] = useState([]);
  const { control, handleSubmit, getValues, watch } = useForm();
  const [availablestaffs, setavailablestaffs] = useState([]);
  const shiftdate = watch("date");
  const { id } = useParams();
  const [error, setError] = useState("");

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      var postdata = {
        reason: data.reason,
        date: data.date,
        time: data.time + ":00",
        status: "pending",
        doctorID: data.doctor,
        patientID: id,
      };
      var res = await CreateAppointment(postdata, config);
      toast.success("Appointment created!");
      setCreateModal(false);
      refresh(true);
    } catch (error: any) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getallshifts = async () => {
    setLoading(true);
    var res = await GetallHostpitalShifts(config);
    setallshifts(res.data);
    setLoading(false);
  };

  useEffect(() => {
    getallshifts();
  }, []);

  useEffect(() => {
    var date = getValues("date");
    var emptarr: any = [];

    var validstaffs = allshifts.filter((k: any) => k.shiftDate == date);
    setavailablestaffs(() => {
      validstaffs.map((sd: any) => {
        emptarr.push(sd.staffID);
      });

      let ret = staff.filter((m: any) => emptarr.includes(m.id));

      if (ret.length == 0) {
        // setError("No staff shift available on this date")
      }
      return ret;
    });
  }, [shiftdate]);

  return (
    <div className="h-screen w-screen bg-[#202020d1] flex fixed top-0 left-0 pt-10 justify-center items-center">
      <Spinner loading={loading} />
      <div className="w-screen h-screen  self-center flex justify-center items-start">
        <div className="w-[30%] bg-white shadow-md rounded-md px-4 pt-4">
          {/* Header */}
          <div className="w-full flex justify-between items-center">
            <h2 className="text-sm font-medium text-black">
              Create Appointment
            </h2>
            <FaRegTimesCircle
              className="cursor-pointer text-gray-300"
              onClick={() => setCreateModal(false)}
            />
          </div>
          {/* Form Content */}
          <div className={""}>
            <InputField
              name="reason"
              title="Reason for Appointment"
              placeholder="Reason"
              control={control}
              isTextArea
            />

            <InputField
              control={control}
              name="date"
              placeholder="Date"
              title="Date"
              type="date"
            />
            <InputField
              control={control}
              name="time"
              placeholder="Time"
              title="Time"
              type="time"
            />

            <SelectField
              control={control}
              name="doctor"
              placeholder="Select"
              isValue
              title="Assign Doctor/Nurse"
              options={availablestaffs.map((it: any) => {
                return {
                  value: it.id,
                  label: `${it.firstName} ${it.lastName}`,
                };
              })}
            />
          </div>

          <p className="text-xs text-red-400 flex justify-center items-center ">
            {error}
          </p>
          {/* Button */}
          <div className="w-full flex items-center justify-end gap-5">
            <Button
              text="Cancel"
              width="100"
              OnBtnClick={() => setCreateModal(false)}
              color="black"
              outlined
              borderColor="gray-300"
            />
            <Button
              text="Create Appointment"
              width="180"
              OnBtnClick={handleSubmit(onSubmit)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
