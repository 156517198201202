import axios from "axios";
import { baseURL } from "../../../URL";

const AddPermission = async (postData: any, config: any) => {
  try {
    const response = await axios.post(
      `${baseURL}/permissions`,
      postData,
      config
    );
    return response.data;
  } catch (error: any) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const GetAllPatients = async (currentPage: any, search: any, config: any) => {
  try {
    const response = await axios.get(
      `${baseURL}/patients?page=${currentPage}`,
      config
    );
    return response.data;
  } catch (error: any) {
    console.log();
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const GetShifts = async ( config: any) => {
  try {
    const response = await axios.get(
      `${baseURL}/shifts/hospital`,
      config
    );
    return response.data;
  } catch (error: any) {
    console.log();
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const GetFilteredPatient = async (params: any, config: any) => {
  try {
    const response = await axios.get(
      `${baseURL}/patients/search/${params}`,
      config
    );
    console.log("response.data", response.data);
    return response.data;
  } catch (error: any) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const GetPatientByID = async (id: any, config: any) => {
  try {
    const response = await axios.get(`${baseURL}/patients/${id}`, config);
    return response.data;
  } catch (error: any) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const UpdatePatientByID = async (
  patientID: any,
  updateData: any,
  config: any
) => {
  try {
    const response = await axios.patch(
      `${baseURL}/patients/${patientID}`,
      updateData,
      config
    );
    return response.data;
  } catch (error: any) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const DeletePatientByID = async (id: any, config: any) => {
  try {
    const response = await axios.delete(`${baseURL}/patients/${id}`, config);
    return response.data;
  } catch (error: any) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};

const onUploadFile = async (postData: any, config: any) => {
  try {
    const response = await axios.post(`${baseURL}/upload`, postData, config);
    return response.data;
  } catch (error: any) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const onUploadStaffDoc = async (postData: any, config: any) => {
  try {
    const response = await axios.post(`${baseURL}/staffs/document/upload`, postData, config);
    return response.data;
  } catch (error: any) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const onUploadDeptDoc = async (postData: any, config: any) => {
  try {
    const response = await axios.post(`${baseURL}/departments/document/upload`, postData, config);
    return response.data;
  } catch (error: any) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};


const onGetDeptDocuments = async (id: any,  config: any) => {
  try {
    const response = await axios.get(
      `${baseURL}/departments/document/${id}`,
      config
    );
    return response.data;
  } catch (error: any) {
    console.log();
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const onGetstaffDocuments = async (id: any, config: any) => {
  try {
    const response = await axios.get(
      `${baseURL}/staffs/document/${id}`,
      config
    );
    return response.data;
  } catch (error: any) {
    console.log();
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
export {
  AddPermission,
  GetAllPatients,
  GetPatientByID,
  onGetDeptDocuments,
  onUploadStaffDoc,
  onUploadDeptDoc,
  UpdatePatientByID,
  onGetstaffDocuments,
  GetShifts,
  DeletePatientByID,
  onUploadFile,
  GetFilteredPatient,
};
