import { CiEdit } from "react-icons/ci";
import { PrimaryLink } from "../../../components/links";
import ProfileItem from "../../../components/generic/ProfileItem";
import { useEffect, useState } from "react";
import { GetPatientInsurance } from "../../../utils/services/api/hospital/patient";
import { useParams } from "react-router-dom";
import { useAuthContext } from "../../../context/AuthContext";
import { Spinner } from "../../../components/generic";

export default ({ patient }: any) => {
  const [insrancedetails, setinsrancedetails] = useState<any>({});
  const [loading, setLoading] = useState(false);

  const { id } = useParams();
  const { config } = useAuthContext();

  const getInsurance = async () => {
    try {

      setLoading(true)
      var res = await GetPatientInsurance(id, config);
      setinsrancedetails(res.data);
    } catch (e: any) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getInsurance();
  }, []);
  return (
    <div className="bg-black p-6 rounded-lg my-5">
      <Spinner loading={loading} />

      {/* Container Header */}
      <div className="w-full flex justify-between items-start">
        {/* Logo Cont */}

        <div>
          <h1 className="text-white text-lg font-medium">Insurance Details</h1>
        </div>
        {/* Edit Profile */}
        <div className="w-[100px]">
          <PrimaryLink
            outlined
            path={`/hospital/insurance/edit/${patient.id}`}
            color="white"
            text="Edit"
            Icon={CiEdit}
            borderColor="white"
          />
        </div>
      </div>
      {/* Container Content */}
      <div>
        {/* Description */}
        <div className="w-full flex justify-between items-start">
          {/* Col 1 */}
          <div className="w-1/3">
            <ProfileItem
              title="Company Name"
              content={insrancedetails?.companyName}
            />
            <ProfileItem
              title="Insurance ID"
              content={insrancedetails?.issuanceID}
            />
            <ProfileItem
              title="Expiry Date"
              content={insrancedetails?.expiryDate}
            />
          </div>
          {/* Col 2 */}

           <div className=" mt-3 smallText text-white flex gap-3 flex-col">Front View

<img  className="w-[200px] h-[200px] rounded" src={insrancedetails.frontViewDoc}/>
          </div>
          {/* Col 3 */}
          <div className=" mt-3 smallText text-white flex flex-col">Back View


          <img  className="w-[200px] h-[200px] rounded" src={insrancedetails.backViewDoc}/>

          </div>

        </div>
      </div>
    </div>
  );
};
