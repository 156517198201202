import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Header from "./Header";
import HeaderMenu from "./HeaderMenu";
import { useAuthContext } from "../../context/AuthContext";

const BaseContainer = () => {
  const { setToken, setAuthUser } = useAuthContext();
  const navigate = useNavigate();
  const isLoggedIn = async () => {
    const user = localStorage.getItem(`hosData`);
    const token = localStorage.getItem(`hosToken`);
    if (user && token) {
      setAuthUser(JSON.parse(user));
      setToken(token);
    } else {
      navigate("/hostpital/login");
    }
  };
  useEffect(() => {
    isLoggedIn();
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <Header />
      <HeaderMenu />
      <Outlet />
    </div>
  );
};

export default BaseContainer;
