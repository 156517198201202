import React, { useState } from "react";
import { Button, InputField } from "../../../components/form";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useAuthContext } from "../../../context/AuthContext";
import { UserChangePassword } from "../../../utils/services/api/hospital/auth";
import { Spinner } from "../../generic";

const ChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit } = useForm();
  const { config, authUser } = useAuthContext();
  const onSubmit = async (data: any) => {
    if (data?.newpass !== data?.confirmpassword) {
      toast.error("Passwords Doesn't Match");
    } else {
      setLoading(true);
      const postData = {
        email: authUser?.contactEmail,
        oldpass: data.oldpass,
        newpass: data.newpass,
      };
      try {
        await UserChangePassword(postData, config);
        window.location.reload();
        toast.success("Password Changed Successfully");
      } catch (error: any) {
        toast.error(error);
      }
      setLoading(false);
    }
  };
  return (
    <div className=" bg-lightBg w-full h-[100vh] flex justify-center items-start">
      <Spinner loading={loading} />
      <div className="md:w-[40%] px-12 w-full">
        <div className="w-full p-8 bg-darkBg rounded-lg">
          <h1 className="text-lg mb-5 font-medium text-black">
            Change Password
          </h1>
          <InputField
            name="oldpass"
            title="Current Password"
            placeholder="Enter Current Password"
            control={control}
            rules={{
              required: "Current Password is required",
            }}
            type={"password"}
          />
          <InputField
            name="newpass"
            title=" New Password"
            placeholder="Enter New  Password Again"
            control={control}
            rules={{
              required: "Confirm Password is required",
            }}
            type={"password"}
          />
          <InputField
            name="confirmpassword"
            title=" Confirm Password"
            placeholder="Enter  Password Again"
            control={control}
            rules={{
              required: "Confirm Password is required",
            }}
            type={"password"}
          />
        </div>
        {/* Button and Forgit Passwords */}
        <div className="w-full flex items-center justify-between">
          <div />
          <div className="w-[140px] my-1">
            <Button text="Save Changes" OnBtnClick={handleSubmit(onSubmit)} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
