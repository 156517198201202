import { useState, useEffect } from "react";
import { useAuthContext } from "../context/AuthContext";
import { toast } from "react-toastify";
import {
  DeletePatientByID,
  GetAllPatients,
  GetFilteredPatient,
} from "../utils/services/api/hospital/patient";

const usePatientHook = () => {
  const [patients, setPatients] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [isDeleted, setIsDeleted] = useState(false);
  const { config, authUser } = useAuthContext();

  const onGetFilteredPatients = async () => {
    try {
      setLoading(true);
      let temp = await GetFilteredPatient(search, config);
      setPatients(temp.data);
      setLoading(false);
      console.log(temp.data)
    } catch (error: any) {
      console.log(error);
      setLoading(false);
    }
  };

  const onGetAllPatients = async () => {
    try {
      setLoading(true);
      let temp;
      let tempCurrent = currentPage === undefined ? 1 : currentPage;
      temp = await GetAllPatients(tempCurrent, search, config);
      setPatients(temp?.data);
      setTotalPages(temp?.pagination?.totalPages);
      setTotal(temp?.pagination?.totalRecords);
      setCurrentPage(temp.pagination?.currentPage);
      setLoading(false);
    } catch (error: any) {
      console.log(error);
      setLoading(false);
    }
  };

 
  useEffect(() => {
    onGetAllPatients();
    // eslint-disable-next-line
  }, [authUser, currentPage, isDeleted]);
  
  const onDeletePatient = async (id: any) => {
    setLoading(true);
    try {
      await DeletePatientByID(id, config);
      toast.success("Patient Deleted Successfully");
      setIsDeleted(true);
    




      
    } catch (error: any) {

      toast.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
      if (search != "") {
         onGetFilteredPatients();
      }
  }, [search]);
  return {
    patients,
    total,
    currentPage,
    setCurrentPage,
    totalPages,
    loading,
    setSearch,
    onDeletePatient,
  };
};
export default usePatientHook;
