import React, { useState } from "react";
import { turgl } from "../../../constants/imports";
import { Button, InputField } from "../../../components/form";
import { emailReg } from "../../../utils/regex";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { GetPasswordResetOTP } from "../../../utils/services/api/hospital/auth";
import { Spinner } from "../../../components/generic";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit } = useForm();
  const navigate = useNavigate();
  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      await GetPasswordResetOTP(data);
      navigate(`/hospital/reset-password/${data.email}`);
    } catch (error: any) {
      toast.error(error);
    }
    setLoading(false);
  };
  return (
    <div className=" bg-lightBg w-full h-[100vh] flex justify-center items-center">
      <Spinner loading={loading} />
      <div className="md:w-[35%] px-12 w-full">
        {/* Header */}
        <div className="w-full flex justify-center items-center mb-3">
          <img src={turgl} alt="Logo" className="text-center" />
        </div>
        <h1 className="text-lg mb-5 font-medium text-black text-center">
          Reset Password
        </h1>
        {/* Email and Password Container */}
        <div className="w-full p-8 bg-darkBg rounded-lg">
          <p className="text-xs ">
            Enter your email address to recieve a code to reset your password
          </p>
          <InputField
            name="email"
            title="Email"
            placeholder="Email"
            control={control}
            rules={{
              required: "Email is required",
              pattern: {
                value: emailReg,
                message: "Invalid Email Address",
              },
            }}
          />
        </div>
        {/* Button and Forgit Passwords */}
        <div className="w-full flex items-center justify-between">
          <div />
          <div className="w-[140px] my-1">
            <Button text="Get Reset Code" OnBtnClick={handleSubmit(onSubmit)} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
