import axios from "axios";
import { baseURL } from "../../../URL";

const AddPermission = async (postData: any, config: any) => {
    try {
        const response = await axios.post(`${baseURL}/permissions`, postData, config);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};
const GetAllPermission = async (config: any) => {
    try {
        const response = await axios.get(`${baseURL}/permissions`, config);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};
const DeletePermissionByID = async (id: any, config: any) => {
    try {
        const response = await axios.delete(`${baseURL}/permissions/${id}`, config);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};
export { AddPermission, GetAllPermission, DeletePermissionByID }