import React, { useState } from "react";
import { useAuthContext } from "../../../context/AuthContext";
import { PrimaryLink } from "../../../components/links";
import { CiEdit } from "react-icons/ci";
import { profile } from "../../../constants/imports";
import { ChangePassword } from "../../../components/hospitals/profile";

const Profile = () => {
  const [active, setActive] = useState("Details");
  const { authUser } = useAuthContext();
  const data = ["Details", "Documents", "Subscription", "Security", "Billing"];
  return (
    <div className="p-5">
      {/* Header */}
      <div>
        <h1 className="text-md font-medium text-black">Profile</h1>
        <p className="text-xs text-black mt-1">
          View and edit hospital information and details
        </p>
      </div>
      {/* Tab Container */}
      <div className="flex">
        <div className="bg-white flex items-center border border-gray-200 rounded-md my-5">
          {data.map((item, index) => (
            <p
              onClick={() => setActive(item)}
              className={` cursor-pointer
            ${index === 0 ? " rounded-tl-md rounded-bl-md" : ""}
            ${index === data.length - 1 ? "  rounded-tr-md rounded-br-md" : ""}
            ${
              active === item ? "bg-primary text-white" : " text-black"
            } text-xs font-medium py-2 px-3`}
              key={item}
            >
              {item}
            </p>
          ))}
        </div>
      </div>
      {/* Profile COntainer */}
      {active === "Details" && (
        <div className="bg-primary p-6 rounded-lg my-4">
          {/* Container Header */}
          <div className="w-full flex justify-between items-start">
            {/* Logo Cont */}
            <div>
              <img
                src={profile}
                className="w-[50px] h-[50px] mx-auto"
                alt="Profile"
              />
              <p className="text-[10px] text-black bg-white font-medium px-1 py-[.5px] rounded-full">
                Change Logo
              </p>
            </div>
            {/* Edit Profile */}
            <div className="w-[140px]">
              <PrimaryLink
                outlined
                path="/profile/edit"
                color="white"
                text="Edit Profile"
                Icon={CiEdit}
              />
            </div>
          </div>
          {/* Container Content */}
          <div>
            {/* Account Status */}
            <div className="flex flex-col justify-center items-start my-2">
              <h1 className="bigText text-white">Account Status</h1>
              <p className="text-white bg-green-600 px-2 py-1 text-xs rounded-full ml-3">
                Active
              </p>
            </div>
            {/* Description */}
            <div className="w-full my-5">
              <div className="w-full flex items-center my-8">
                <div className="w-[30%]">
                  <p className="smallText text-white">Hospital Name</p>
                  <h1 className="bigText text-white">
                    {authUser?.hospitalName}
                  </h1>
                </div>
                <div className="w-[30%]">
                  <p className="smallText text-white">Hospital Type</p>
                  <h1 className="bigText text-white">
                    {authUser?.hospitalType}
                  </h1>
                </div>
                <div className="w-[30%]">
                  <p className="smallText text-white">Hospital Address</p>
                  <h1 className="bigText text-white">
                    {authUser?.hospitalAddress}
                  </h1>
                </div>
              </div>
              <div className="w-full flex items-center my-5">
                <div className="w-[30%]">
                  <p className="smallText text-white">Contact Person Email</p>
                  <h1 className="bigText text-white">
                    {authUser?.contactEmail}
                  </h1>
                </div>
                <div className="w-[30%]">
                  <p className="smallText text-white">Contact Person’s Phone</p>
                  <h1 className="bigText text-white">
                    {authUser?.contactPhone}
                  </h1>
                </div>
                <div className="w-[30%]">
                  <p className="smallText text-white">Contact Person’s Name</p>
                  <h1 className="bigText text-white">
                    {authUser?.contactName}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* CHange Password */}
      {active === "Security" && <ChangePassword />}
    </div>
  );
};

export default Profile;
