import { useState, useEffect } from "react";
import { useAuthContext } from "../context/AuthContext";
import {
  DeleteDeptByID,
  GetAllDepts,
  GetFilteredDept,
} from "../utils/services/api/hospital/dept";
import { toast } from "react-toastify";
import { onGetDeptDocuments } from "../utils/services/api/hospital/services";

const useDeptHook = () => {
  const [depts, setDepts] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [isDeleted, setIsDeleted] = useState(false);

  const { config, authUser } = useAuthContext();
  const GetAllDept = async () => {
    try {
      setLoading(true);
      let temp;
      let tempCurrent = currentPage === undefined ? 1 : currentPage;

      // if (search === "") {

      temp = await GetAllDepts(tempCurrent, config);
      setDepts(temp?.data);
      setTotalPages(temp?.pagination?.totalPages);
      setTotal(temp?.pagination?.totalRecords);
      setCurrentPage(temp.pagination?.currentPage);
      // } else {
      //   temp = await GetFilteredDept(search, tempCurrent, config);
      //   setDepts(temp?.data);
      // }
      setLoading(false);
    } catch (error: any) {
      console.log(error);
      setLoading(false);
      setDepts([]);
    }
  };
const getDeptDocuments = async (id:any)=>{
 
  var res =await onGetDeptDocuments(id, config)
return res.data
}
  useEffect(() => {
    GetAllDept();
    // eslint-disable-next-line
  }, [authUser, currentPage, search, isDeleted]);
  const onDeleteDept = async (id: any) => {
    setLoading(true);
    try {
      await DeleteDeptByID(id, config);
      toast.success("Staff Deleted Successfully");
      setIsDeleted(true);
    } catch (error: any) {
      toast.error(error);
    }
    setLoading(false);
  };
  return {
    depts,
    total,
    currentPage,getDeptDocuments,
    setCurrentPage,
    totalPages,
    loading,
    setSearch,
    onDeleteDept,
  };
};
export default useDeptHook;
