import { resolveTxt } from "dns";
import { Button, InputField, SelectField } from "../../../components/form";
import { useForm } from "react-hook-form";
import { emailReg, stringnum, textReg } from "../../../utils/regex";
import { useEffect, useState } from "react";
import { empty, user } from "../../../constants/imports";
import { onUploadFile } from "../../../utils/services/api/hospital/services";
import { useAuthContext } from "../../../context/AuthContext";
import { toast } from "react-toastify";
import {
  GetPatientInsurance,
  onAddInsurance,
  onUpdateInsurance,
} from "../../../utils/services/api/hospital/patient";
import { Spinner } from "../../../components/generic";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import usePatientDetails from "../../../query/usePatientDetails";

export default (props: any) => {
  const [insrancedetails, setinsrancedetails] = useState<any>({});
  const { id } = useParams();
  const [imagePreview1, setImagePreview1] = useState<
    string | ArrayBuffer | null
  >(null);
  const [imagePreview2, setImagePreview2] = useState<
    string | ArrayBuffer | null
  >(null);
  const [imageFile1, setImageFile1] = useState<any>(null);
  const [imageFile2, setImageFile2] = useState<any>(null);
  const { config } = useAuthContext();
  const { patient } = usePatientDetails(id);
  const handleImageChange1 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    setImageFile1(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview1(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleImageChange2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    setImageFile2(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview2(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const [loading, setLoading] = useState(false);

  const getInsurance = async () => {
    try {
      setLoading(true);
      var res = await GetPatientInsurance(id, config);
      setinsrancedetails(res.data);
    } catch (e: any) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getInsurance();
  }, []);
  const onSubmit = async (data: any) => {
    var postdata = {
      companyName: data.co_name, // Required
      issuanceID: data.insureerid, // Required
      expiryDate: data.expirydate, // Required
      frontViewDoc: insrancedetails.frontViewDoc, // Required
      backViewDoc: insrancedetails.backViewDoc, // Optional
    };

    if (imageFile1) {
      //Upload image and get url
      const formdata = new FormData();
      formdata.append("file", imageFile1, imageFile1?.name);
      try {
        var fileUploadres = await onUploadFile(formdata, config);

        if (fileUploadres.success) {
          postdata = { ...postdata, frontViewDoc: fileUploadres.data };
        }
      } catch (e: any) {
        toast.error(e);
        console.log(e);
      }
    }
    if (imageFile2) {
      //Upload image and get url
      const formdata = new FormData();
      formdata.append("file", imageFile2, imageFile2?.name);
      try {
        var fileUploadres = await onUploadFile(formdata, config);

        if (fileUploadres.success) {
          postdata = { ...postdata, backViewDoc: fileUploadres.data };
        }
      } catch (e: any) {
        toast.error(e);
        console.log(e);
      }
    }

    try {
      setLoading(true);

      let res = await onUpdateInsurance(insrancedetails.id, postdata, config);
      toast.success(res.message);
      
    } catch (error: any) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };
  const row = "w-full block md:flex justify-between items-center";
  const col = "w-full md:w-[30%]";
  const { handleSubmit, control, setValue } = useForm();
  const [stateNames, setStateNames] = useState<string[]>([]);
  const countryNames: string[] = [];

  const navigate = useNavigate();

  useEffect(() => {
    setValue("co_name", insrancedetails?.companyName);
    setValue("insureerid", insrancedetails?.issuanceID);
    setValue("expirydate", insrancedetails?.expiryDate);
  }, [patient, setValue]);

  return (
    <div className="my-5 w-full px-8">
      <Spinner loading={loading} />

      <div className="flex  justify-start items-start">
        {/* Back Button */}
        <button
          onClick={() => navigate(-1)}
          className="flex items-center justify-center bg-blue-500 py-1 pr-2 rounded-md"
        >
          <IoIosArrowBack color="white" />
          <p className="text-xs text-white">Back</p>
        </button>
        <div className="ml-4">
          <h1 className="text-lg font-medium text-black">
            Edit Inurance details
          </h1>
        </div>
      </div>
      {/* form Content */}
      <div className="bg-darkBg py-4 px-8 rounded-sm w-full">
        {/* Form Row One (FirstName, LastName, Email) */}
        <div className={row}>
          {/* col 1 */}
          <div className={col}>
            <InputField
              name="co_name"
              title="Insurance Company Name"
              placeholder="Company name"
              control={control}
              rules={{
                required: "company name is required",
                pattern: {
                  value: textReg,
                  message: "Text Only",
                },
              }}
            />
          </div>
          {/* Col 2 */}
          <div className={col}>
            <InputField
              name="insureerid"
              title="Insurance ID"
              placeholder="Insurance ID"
              control={control}
              rules={{
                required: "Insurance ID is required",
                pattern: {
                  value: stringnum,
                  message: "Text Only",
                },
              }}
            />
          </div>
          {/* Col 3 */}
          <div className={col}>
            <InputField
              control={control}
              name="expirydate"
              placeholder="Select a date"
              title="Expiry date"
              type="date" // Set type to "date" for a date input
              rules={{ required: "Expiry date is required" }}
            />
          </div>
        </div>

        <p className="text-sm font-light mt-2">
          Insurance Company Card/Document
        </p>

        <div className="flex ">
          <div className="md:mr-5 md:w-[20%]">
            {imagePreview1 ? (
              <img
                src={imagePreview1.toString()}
                alt="Preview"
                className="h-[100px] w-[100px] mx-auto mb-2 rounded-full"
              />
            ) : (
              <img
                src={insrancedetails.frontViewDoc}
                className="h-[100px] w-[100px] mx-auto mb-2 rounded-full"
                alt="Profile"
              />
            )}
            <div className="flex flex-col items-center justify-center">
              <label
                htmlFor="imageUpload"
                className="text-center text-blue-600 text-[11px] font-light mt-3 cursor-pointer"
              >
                Upload Front image{" "}
              </label>
              <input
                type="file"
                id="imageUpload"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleImageChange1}
              />
            </div>
          </div>

          {/* Back image */}

          <div className="md:mr-5 md:w-[20%]">
            {imagePreview2 ? (
              <img
                src={imagePreview2.toString()}
                alt="Preview"
                className="h-[100px] w-[100px] mx-auto mb-2 rounded-full"
              />
            ) : (
              <img
                src={insrancedetails.backViewDoc}
                className="h-[100px] w-[100px] mx-auto mb-2 rounded-full"
                alt="Profile"
              />
            )}
            <div className="flex flex-col items-center justify-center">
              <label
                htmlFor="imageUpload2"
                className="text-center text-blue-600 text-[11px] font-light mt-3 cursor-pointer"
              >
                Upload Back image{" "}
              </label>
              <input
                type="file"
                id="imageUpload2"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleImageChange2}
              />
            </div>
          </div>
        </div>
      </div>
      {/* Button */}
      <div className="flex justify-end items-center mt-5 mb-10">
        <div className=" flex gap-4 ">
          <Button text="Save" OnBtnClick={handleSubmit(onSubmit)} />
        </div>
      </div>
    </div>
  );
};
