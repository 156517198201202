import React from "react";
import { MdDashboard } from "react-icons/md";
import useDeptHook from "../../hooks/useDeptHook";
import useStaffHook from "../../hooks/useStaffHook";
import useCategoryService from "../../query/useCategoryService";
import usePatientHook from "../../hooks/usePatientHook";
import { useNavigate } from "react-router-dom";
import { Spinner } from "../../components/generic";
import { FcDepartment } from "react-icons/fc";
import { FaCircleUser, FaKitMedical, FaUserDoctor } from "react-icons/fa6";

interface DashboardItemProp {
  action: () => void;
  icon: JSX.Element;
  name: string;
  analytic: string;
}

const DashboardItem: React.FC<DashboardItemProp> = ({
  action,
  analytic,
  icon,
  name,
}) => {
  return (
    <div
      onClick={() => {
        action();
      }}
      className="flex flex-col justify-center items-center border-2 rounded-[10px]  border-gray-300 max-h-[300px] p-3 "
    >
      {icon}
      <p className="text font-bold ">{name}</p>
      <p className="text-[30px] font-bold">{analytic}</p>
    </div>
  );
};

const Dashboard = () => {
  const { depts } = useDeptHook();
  const { staff } = useStaffHook();
  const { services } = useCategoryService(1);
  const { patients, loading } = usePatientHook();
  const navigate = useNavigate();
  const dashboaritemData = [
    {
      name: "Departments",
      path: "/dept",
      icon: <FcDepartment size={30} className="inline  text-[#37C6A4] " />,
      number: depts.length,
    },
    {
      name: "Staff",
      path: "/hospital/staff",
      icon: <FaUserDoctor size={30} className="inline  text-[#37C6A4]" />,
      number: staff.length,
    },
    {
      name: "Patients",
      path: "/hospital/patients",
      icon: <FaCircleUser size={30} className="inline  text-[#37C6A4]" />,
      number: patients.length,
    },
    {
      name: "Services",
      path: "/hospital/categories",
      icon: <FaKitMedical size={30} className="inline text-[#37C6A4] " />,
      number: services.length,
    },
  ];
  return (
    <div className="flex gap-4 m-4">
      <Spinner loading={loading} />
      <div className="w-[20vw] h-[100%]   rounded-[10px] border-2  border-gray-300 min-h-[500px]">
        <p className="text-sm p-4 ">Newly registered patients</p>
        {patients.map((pat: any) => (
          <div className="bg-blue-100 rounded font-light p-2 mx-2 text-sm">
            <p className="font-semibold text-xs">
              {pat.firstName} {pat.lastName}
            </p>
            <p className="">
              Card type:{pat.cardType} {new Date(pat.createdAt).toDateString()}
            </p>
          </div>
        ))}
      </div>

      <div className=" w-[80vw]  ">
        <div className="grid grid-cols-4 gap-4 px-6 py-4 ">
          {dashboaritemData.map((ee: any) => (
            <DashboardItem
              analytic={ee.number}
              icon={ee.icon}
              action={() => {
                navigate(ee.path);
              }}
              name={ee.name}
            />
          ))}
        </div>

        <div className="w-full bg-gray-100 p-3 rounded-[10px] min-h-[300px]">
          <p>Recent activities</p>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
