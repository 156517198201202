import { resolveTxt } from "dns";
import { Button, InputField, SelectField } from "../../../components/form";
import { useForm } from "react-hook-form";
import { emailReg, textReg } from "../../../utils/regex";
import { useEffect, useState } from "react";
import { AddNOK } from "../../../utils/services/api/hospital/patient";
import { useAuthContext } from "../../../context/AuthContext";
import { Spinner } from "../../../components/generic";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import countries from "../../../constants/data/countries.json";
import usePatientDetails from "../../../query/usePatientDetails";
import { IoIosArrowBack } from "react-icons/io";
interface StateDto {
  code: string;
  name: string;
  subdivision: null;
}

interface CountryDto {
  code2: string;
  code3: string;
  name: string;
  capital: string;
  region: string;
  subregion: string;
  states: StateDto[];
}
export default (props: any) => {
  const { config } = useAuthContext();
  const row = "w-full block md:flex justify-between items-center";
  const col = "w-full md:w-[30%]";
  const { handleSubmit, control, watch, getValues, setValue } = useForm();
  const [stateNames, setStateNames] = useState<string[]>([]);
  const countryNames: string[] = [];
  const { id } = useParams();
  const [isloading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [temp, setTemp] = useState<StateDto[] | null>(null);

  const { patient, loading } = usePatientDetails(id);
  const [AllCountries, setAllCountries] = useState<CountryDto[]>([]);

  for (const item of (countries || []) as CountryDto[]) {
    if (item?.name !== undefined && item?.name !== "") {
      countryNames.push(item.name);
    }
  }

  useEffect(() => {
    setValue("nok_firstname", patient?.nokFirstname);
    setValue("nok_lastname", patient?.nokLastname);
    setValue("nok_email", patient?.nokEmail);
    setValue("nok_phonenumber", patient?.nokPhoneNumber);
    setValue("nok_gender", patient?.nokGender);
    setValue("nok_relationship", patient?.nokRelationship);
    setValue("nok_country", patient?.nokCountry);
    setValue("nok_state", patient?.nokState);
    setValue("nok_address", patient?.nokAddress);
  }, [patient, setValue]);

  useEffect(() => {
    setAllCountries(countries as never);
  }, []);
  useEffect(() => {
    const selectedCountry = getValues("country");
    const filteredCountry = AllCountries.find(
      (country) => country.name === selectedCountry
    );
    setTemp(filteredCountry?.states || null);
    // eslint-disable-next-line
  }, [AllCountries, getValues, watch("country")]);
  useEffect(() => {
    const selectedCountry = getValues("nok_country");
    const filteredCountry = AllCountries.find(
      (country) => country.name === selectedCountry
    );
    setTemp(filteredCountry?.states || null);
    // eslint-disable-next-line
  }, [AllCountries, getValues, watch("nok_country")]);

  useEffect(() => {
    const names = (temp || []).map((state) => state.name).filter(Boolean);
    setStateNames(names);
  }, [temp]);
  const onSubmit = async (data: any) => {
    const postdata = {
      nokAddress: data.nok_address,
      nokState: data.nok_state,
      nokCountry: data.nok_country,
      nokRelationship: data.nok_relationship,
      nokGender: data.nok_gender,
      nokPhoneNumber: data.nok_phonenumber,
      nokEmail: data.nok_email,
      nokLastname: data.nok_lastname,
      nokFirstname: data.nok_firstname,
    };

    console.log(postdata)
    try {
      setLoading(true);

      let res = await AddNOK(id, postdata, config);
      toast.success(res.message);
      navigate(-1);
    } catch (error: any) {
      toast.error(error);
      setLoading(false);
    }  
    finally{
      setLoading(false);
    }

 
  };
  return (
    <div className="my-5 w-full px-8">
      <Spinner loading={loading  } />
      <Spinner loading={isloading} />

      <div className="flex  justify-start items-start">
        {/* Back Button */}
        <button
          onClick={() => navigate(-1)}
          className="flex items-center justify-center bg-blue-500 py-1 pr-2 rounded-md"
        >
          <IoIosArrowBack color="white" />
          <p className="text-xs text-white">Back</p>
        </button>
        <div className="ml-4">
          <h1 className="text-lg font-medium text-black">Edit Next of Kin</h1>
        </div>
      </div>

      {/* form Content */}
      <div className="bg-darkBg py-4 px-8 rounded-sm w-full">
        {/* Form Row One (FirstName, LastName, Email) */}
        <div className={row}>
          {/* col 1 */}
          <div className={col}>
            <InputField
              name="nok_firstname"
              title="First Name"
              placeholder="First Name"
              control={control}
              rules={{
                required: "First Name is required",
                pattern: {
                  value: textReg,
                  message: "Text Only",
                },
              }}
            />
          </div>
          {/* Col 2 */}
          <div className={col}>
            <InputField
              name="nok_lastname"
              title="Last Name"
              placeholder="Last Name"
              control={control}
              rules={{
                required: "Last Name is required",
                pattern: {
                  value: textReg,
                  message: "Text Only",
                },
              }}
            />
          </div>
          {/* Col 3 */}
          <div className={col}>
            <InputField
              name="nok_email"
              title="Email"
              placeholder="Email"
              control={control}
               />
          </div>
        </div>
        {/* Form Row Two (Phone Number, Gender, DOB) */}
        <div className={row}>
          {/* Col 1*/}
          <div className={col}>
            <InputField
              name="nok_phonenumber"
              title="Phone Number"
              placeholder="Phone Number"
              control={control}
              rules={{
                required: "Phone Number is required",
              }}
            />
          </div>
          {/* Col 2 */}
          <div className={col}>
            <SelectField
              control={control}
              name="nok_gender"
              rules={{ required: "Gender is required" }}
              placeholder="Select"
              title="Gender"
              options={["Male", "Female"]}
            />
          </div>
          {/* COl 3 DOB */}
          <div className={col}>
            <SelectField
              control={control}
              name="nok_relationship"
              rules={{ required: "Relationship is required" }}
              placeholder="Select"
              title="Relationship"
              options={[
                "Wife",
                "Son",
                "Daugther",
                "Mother",
                "Father",
                "Sister",
                "Others",
              ]}
            />
          </div>
        </div>

        {/* Form Row Three (Country and  States, Address) */}
        <div className={row}>
          {/* Col 1 */}
          <div className={col}>
            <SelectField
              control={control}
              name="nok_country"
              placeholder="Select"
              title="country"
              options={countryNames}
              rules={{ required: "Country is required" }}
            />
          </div>
          {/* col 2 */}
          <div className={col}>
            <SelectField
              control={control}
              name="nok_state"
              placeholder="Select"
              title="State"
              options={stateNames}
              rules={{ required: "State is required" }}
            />
          </div>
          {/* Col 3 */}
          <div className={col}>
            <InputField
              name="nok_address"
              title="Address"
              placeholder="Address"
              control={control}
              rules={{ required: "Address is required" }}
            />
          </div>
        </div>
      </div>
      {/* Button */}
      <div className="flex justify-end items-center mt-5 mb-10">
        <div className=" flex gap-4 ">
          <Button text="Save" OnBtnClick={handleSubmit(onSubmit)} />
        </div>
      </div>
    </div>
  );
};
