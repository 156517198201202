import React, { useEffect, useState } from "react";
import { GoPlus } from "react-icons/go";
import { empty } from "../../../constants/imports";
import { IoSearch } from "react-icons/io5";
import { PermissionTable } from "../../../components/layouts/table";
import { PulseLoader } from "react-spinners";
import { Button, InputField } from "../../../components/form";
import { FaRegTimesCircle } from "react-icons/fa";
import { textReg } from "../../../utils/regex";
import { useForm } from "react-hook-form";
import { MultiSelect, Spinner } from "../../../components/generic";
import { toast } from "react-toastify";
import { useAuthContext } from "../../../context/AuthContext";
import { AddPermission } from "../../../utils/services/api/hospital/services";
import usePermissionHook from "../../../hooks/usePermissionHook";
import { GetAllPermission } from "../../../utils/services/api/hospital/permissions";

const PermissionHome = () => {
  const [createModal, setCreateModal] = useState(false);
  const { loading, setSearch, permissions } = usePermissionHook();
  return (
    <div className="w-full bg-white p-5">
      {/* Header */}
      <div className="w-full flex items-start justify-between">
        {/* Header Desc */}
        <div className="">
          <h1 className="heading">Roles and Permissions</h1>
          <p className="sub-heading">
            Define and manage user roles and access rights for the hospital
            application.
          </p>
        </div>
      </div>
      {/* Seacrh and Filter */}
      <div className="flex items-center justify-end space-x-4 mb-5">
        {/* Search Field */}
        <div className="relative w-[300px]">
          <IoSearch className="absolute top-2 left-2 text-xl text-zinc-400" />
          <input
            type={"text"}
            placeholder={"Search"}
            onChange={(e: any) => setSearch(e.target.value)}
            className={`pl-8 block bg-white text-xs py-2 px-4 rounded-md w-full shadow-xs border border-gray-300}`}
          />
        </div>
        <Button
          text="Create Permission"
          width="180"
          OnBtnClick={() => setCreateModal(true)}
          color="white"
          Icon={GoPlus}
        />
      </div>
      {/* COntent */}
      <div>
        {loading ? (
          <div className=" m-12  justify-items-center justify-center">
            <PulseLoader
              className=" m-12 justify-center"
              color={"#350080"}
              loading={loading}
              size={14}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ) : (
          <>
            {permissions.length > 0 ? (
              <PermissionTable />
            ) : (
              <div className="w-full h-full flex justify-center items-center">
                <div>
                  <img
                    src={empty}
                    className="h-[100px] w-[100px] mx-auto"
                    alt="Empty"
                  />
                  <p className="sub-heading text-center">
                    you are yet to add a Roles
                  </p>
                  <Button
                    text="Create Permission"
                    width="180"
                    OnBtnClick={() => setCreateModal(true)}
                    color="white"
                    Icon={GoPlus}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {/* Add Modal */}
      {createModal && <Modal setCreateModal={setCreateModal} />}
    </div>
  );
};

export default PermissionHome;
const Modal = ({ setCreateModal }: any) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const { config } = useAuthContext();
  const { control, handleSubmit } = useForm();
  const options = [
    { label: "View Staff", value: "View Staff" },
    { label: "Add Staff", value: "Add Staff" },
    { label: "Edit Staff", value: "Edit Staff" },
    { label: "Delete Staff", value: "Delete Staff" },
    { label: "View Dept", value: "View Dept" },
    { label: "Add Dept", value: "Add Dept" },
    { label: "Edit Dept", value: "Edit Dept" },
    { label: "Delete Dept", value: "Delete Dept" },
    // { label: "View Patient", value: "View Patient" },
    // { label: "Add Patient", value: "Add Patient" },
    // { label: "Edit Patient", value: "Edit Patient" },
    // { label: "Delete Patient", value: "Delete Patient" },
    { label: "Settings", value: "Settings" },
  ];
  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      data.access = selectedOptions.toString();
      await AddPermission(data, config);
      window.location.reload();
      toast.success("Permission Added Successfully!");
      setCreateModal(false);
    } catch (error: any) {
      toast.error(error);
    }
    setLoading(false);
  };
  return (
    <div className="h-full w-full fixed top-0 left-0 pt-10">
      <Spinner loading={loading} />
      <div className="w-full h-full flex justify-center items-start">
        <div className="w-[30%] bg-white shadow-md rounded-md px-4 pt-4">
          {/* Header */}
          <div className="w-full flex justify-between items-center">
            <h2 className="text-sm font-medium text-black">
              Create Permission
            </h2>
            <FaRegTimesCircle
              className="cursor-pointer text-gray-300"
              onClick={() => setCreateModal(false)}
            />
          </div>
          {/* Form Content */}
          <div className={""}>
            <InputField
              name="permissionName"
              title="Permission Name"
              placeholder="Permission Name"
              control={control}
              rules={{
                required: "Permission Name is required",
                pattern: {
                  value: textReg,
                  message: "Text Only",
                },
              }}
            />
          </div>
          <div className={""}>
            <InputField
              name="description"
              title="Description"
              placeholder="Description"
              control={control}
            />
          </div>
          <MultiSelect
            options={options}
            setSelectedOptions={setSelectedOptions}
            selectedOptions={selectedOptions}
          />
          {/* Button */}
          <div className="w-full flex items-center justify-end gap-5">
            <Button
              text="Cancel"
              width="100"
              OnBtnClick={() => setCreateModal(false)}
              color="black"
              outlined
              borderColor="gray-300"
            />
            <Button
              text="Create"
              width="100"
              OnBtnClick={handleSubmit(onSubmit)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
