import axios from "axios";
import { baseURL } from "../../../URL";
import { CreateServiceDTO, UpdateServiceDTO } from "../../../typings/service";

const GetPatientAppointment = async (patientID: any, config: any) => {
  try {
    const response = await axios.get(
      `${baseURL}/appointment/patient/${patientID}`,
      config
    );
    return response.data;
  } catch (error: any) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const GetAppointmentfulldetails = async (id: any, config: any) => {
  try {
    const response = await axios.get(
      `${baseURL}/appointment/all/${id}`,
      config
    );
    return response.data;
  } catch (error: any) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const GetRecordbyid = async (id: any, config: any) => {
  try {
    const response = await axios.get(`${baseURL}/record/${id}`, config);
    return response.data;
  } catch (error: any) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const GetAllvitals = async (config: any) => {
  try {
    const response = await axios.get(`${baseURL}/vitals`, config);
    return response.data;
  } catch (error: any) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const GetAppointmentVitals = async (appointmentID: any, config: any) => {
  try {
    const response = await axios.get(
      `${baseURL}/patient-vital/appointment/${appointmentID}`,
      config
    );
    return response.data;
  } catch (error: any) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const GetAppointmentRecords = async (appointmentID: any, config: any) => {
  try {
    const response = await axios.get(
      `${baseURL}/record/appointment/${appointmentID}`,
      config
    );
    return response.data;
  } catch (error: any) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const GetPatientsRecords = async (patientID: any, config: any) => {
  try {
    const response = await axios.get(
      `${baseURL}/record/patient/${patientID}`,
      config
    );
    return response.data;
  } catch (error: any) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const CreateAppointment = async (postData: any, config: any) => {
  try {
    const response = await axios.post(
      `${baseURL}/appointment`,
      postData,
      config
    );
    return response.data;
  } catch (error: any) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const UpdateRecord = async (id: any, postData: any, config: any) => {
  try {
    const response = await axios.patch(
      `${baseURL}/record/${id}`,
      postData,
      config
    );
    return response.data;
  } catch (error: any) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const CompleteAppointment = async (id: any, postData: any, config: any) => {
  try {
    const response = await axios.patch(
      `${baseURL}/appointment/${id}`,
      postData,
      config
    );
    return response.data;
  } catch (error: any) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const CreateAppointmentrecord = async (postData: any, config: any) => {
  try {
    const response = await axios.post(`${baseURL}/record`, postData, config);
    return response.data;
  } catch (error: any) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const AddpatientVital = async (postData: any, config: any) => {
  try {
    const response = await axios.post(
      `${baseURL}/patient-vital`,
      postData,
      config
    );
    return response.data;
  } catch (error: any) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};



export {
  AddpatientVital,
  GetAppointmentVitals,
  GetRecordbyid,
  UpdateRecord,
  GetPatientAppointment,
  GetAllvitals,
  CreateAppointmentrecord,
  GetAppointmentfulldetails,


  GetPatientsRecords,
  CompleteAppointment,
  GetAppointmentRecords,
  CreateAppointment,
};
