import React from "react";
import { Link } from "react-router-dom";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import useStaffHook from "../../../hooks/useStaffHook";
import { zeroPad } from "../../../utils/functions/generic";
import ReactPaginate from "react-paginate";
import { Spinner } from "../../generic";
import { TableData } from "./Table";

const StaffTable = ({ IsSame }: any) => {
  const { staff, setCurrentPage, totalPages, currentPage, onDeleteStaff } =
    useStaffHook();
  const tbHeader = [ "Staff ID", "Fullname", "Phone", "Email", "Role"];
  const handlePageClick = async (data: any) => {
    setCurrentPage(data?.selected + 1);
  };

  return (
    <div className="overflow-x-auto">
      <table className="w-full bg-white">
        <thead className="w-full bg-[#EAECF0]">
          <tr className="w-full">
            {tbHeader.map((item, index) => (
              <th
                key={index}
                scope="col"
                className="px-6 py-3 uppercase text-left text-xs font-semibold whitespace-nowrap"
              >
                {item}
              </th>
            ))}
            {!IsSame && (
              <th
                scope="col"
                className="px-6 py-3 uppercase text-left text-xs font-semibold whitespace-nowrap"
              >
                {"Department"}
              </th>
            )}
            <th
              scope="col"
              className="px-6 py-3 uppercase text-left text-xs font-semibold whitespace-nowrap"
            >
              {"Actions"}
            </th>
          </tr>
        </thead>
        {/* Table content */}
        <tbody className="w-full bg-white">
          {staff.map((item: any, index) => (
            <tr
              className="bg-white border-b pt-8  hover:bg-gray-50"
              key={index}
            >
            
              <TableData item={`${zeroPad(item?.id, 4)}`} />
              <td className="px-6 py-3 flex items-center">
                {item?.photo ? (
                  <img
                    src={item?.photo}
                    className="w-[30px] h-[30px] rounded-full mr-3"
                    alt="Staff"
                  />
                ) : (
                  <div className="w-[30px] h-[30px] rounded-full mr-3 bg-primary flex items-center justify-center">
                    <p className="text-white font-medium">{`${item?.firstName?.slice(
                      0,
                      1
                    )}${item?.lastName?.slice(0, 1)}`}</p>
                  </div>
                )}
                <p className="text-xs text-black">{`${item?.firstName} ${item.lastName}`}</p>
              </td>
              <TableData item={item?.phone} />
              <TableData item={item?.email} />
              <TableData item={item?.role} />
              {!IsSame && <TableData item={item?.departmentName} />}
              <td>
                <div className="flex items-center px-6 py-3">
                  <Link to={`/hospital/staff/${item.id}`}>
                    <MdOutlineRemoveRedEye className=" text-blue-600 mx-4 text-lg" />
                  </Link>
                  <RiDeleteBin6Line
                    className="text-red-600 text-lg cursor-pointer"
                    onClick={() => onDeleteStaff(item.id)}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* Table Footer (Pagination) */}
      {/* Table Footer (Pagination) */}
      <div className="w-full flex items-center justify-between">
        {/* Numbers */}
        <div>
          <p className="sub-heading text-black">{`${currentPage} of ${totalPages} Pages`}</p>
        </div>
        {/* NExt and Previous Btn */}
        {/* Pagination */}
        <div className="px-6 py-6">
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"inline-flex items-center -space-x-px"}
            pageClassName={
              "px-3 py-2 leading-tight text-sm text-gray-500 border border-gray-300 hover:text-gray-700 hover:bg-purple-300 hover:text-white"
            }
            pageLinkClassName={""}
            previousClassName={
              "block px-3 py-2 text-sm ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-purple-400 hover:text-white"
            }
            previousLinkClassName={""}
            nextClassName={
              "block px-3 py-2 leading-tight text-sm text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-purple-400 hover:text-white"
            }
            nextLinkClassName={""}
            breakClassName={
              "px-3 py-2 leading-tight text-sm text-gray-500 bg-white border border-gray-300 hover:bg-purple-400 hover:text-white"
            }
            breakLinkClassName={
              "px-3 py-2 leading-tight text-sm text-gray-500 bg-white border border-gray-300 hover:bg-purple-400 hover:text-white"
            }
            activeClassName={
              "z-10 px-3 py-2 leading-tight text-white border border-primary bg-primary hover:bg-purple-100 hover:text-primary"
            }
          />
        </div>
      </div>
    </div>
  );
};

export default StaffTable;
