import React, { MouseEventHandler } from "react";

interface ButtonProps {
  text: JSX.Element |string;
  OnBtnClick: MouseEventHandler<HTMLButtonElement>;
  outlined?: boolean;
  width?: string;
  color?: string;
  bgColor?: string;
  borderColor?: string;
  Icon?: React.ComponentType<{ size: number; className: any }>;
}

const Button: React.FC<ButtonProps> = ({
  text,
  OnBtnClick,
  outlined,
  width,
  color,
  Icon,
  bgColor,
  borderColor,
}) => {
  return (
    <button
      className={`flex items-center justify-center font-medium text-xs ${
        outlined
          ? "bg-transparent"
          : bgColor
          ? `bg-${bgColor}`
          : "bg-primary text-white"
      }
      ${color ? "text-" + color : "text-primary"}
      ${
        width ? "w-[" + width + "px]" : "w-full px-5"
      } my-5 py-2 rounded-md text-center border ${
        borderColor ? "border-" + borderColor : "border-primary"
      }`}
      onClick={OnBtnClick}
    >
      {Icon && (
        <Icon
          size={20}
          className={`${color ? "text-" + color : "text-white "} mr-2`}
        />
      )}
      {text}
    </button>
  );
};

export default Button;
