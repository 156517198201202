import React from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import ReactPaginate from "react-paginate";
import { TableData } from "./Table";
import { PulseLoader } from "react-spinners";
import { CiEdit } from "react-icons/ci";
import { ServiceDTO } from "../../../utils/typings/service";
import useCategoryService from "../../../query/useCategoryService";
import { money } from "../../../utils/functions/generic";
interface TableProps {
  id: number;
  setActiveItem: (activeItem: ServiceDTO) => void;
  services:ServiceDTO []
}
const ServiceTable: React.FC<TableProps> = ({ id, services, setActiveItem }) => {
  const {
    
    setCurrentPage,
    totalPages,
    currentPage,
    onDeleteService,
    loading,
  } = useCategoryService(id);

  const tbHeader = ["Service Name", "Description", "Price", "Action"];
  const handlePageClick = async (data: any) => {
    setCurrentPage(data?.selected + 1);
  };
  if (loading) {
    return (
      <div className=" m-12  justify-items-center justify-center">
        <PulseLoader
          className=" m-12 justify-center"
          color={"#350080"}
          loading={loading}
          size={14}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  }
  return (
    <div className="overflow-x-auto">
      <table className="w-full bg-white">
        <thead className="w-full bg-[#EAECF0]">
          <tr className="w-full">
            {tbHeader.map((item, index) => (
              <th
                key={index}
                scope="col"
                className="px-6 py-3 uppercase text-left text-xs font-semibold whitespace-nowrap"
              >
                {item}
              </th>
            ))}
          </tr>
        </thead>
        {/* Table content */}
        <tbody className="w-full bg-white">
          {services.map((item: ServiceDTO, index: number) => {
            return (
              <tr
                className="bg-white border-b pt-8   hover:bg-gray-50"
                key={index}
              >
                <TableData color={"text-blue-500"} item={item?.ssName} />
                <TableData ismain={true} item={item?.description} />
                <TableData item={`N${money(item?.amount)}`} />
                <td className="self-end">
                  <div className="flex items-center px-6 py-3 gap-4">
                    <CiEdit
                      className="text-primary text-lg font-bold cursor-pointer"
                      onClick={() => setActiveItem(item)}
                    />
                    <RiDeleteBin6Line
                      className="text-red-600 text-lg cursor-pointer"
                      onClick={() => onDeleteService(item.id)}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* Table Footer (Pagination) */}
      {/* <div className="w-full flex items-center justify-between">
        
        <div>
          <p className="sub-heading text-black">{`${currentPage} of ${totalPages} Pages`}</p>
        </div>
      
        <div className="px-6 py-6">
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"inline-flex items-center -space-x-px"}
            pageClassName={
              "px-3 py-2 leading-tight text-sm text-gray-500 border border-gray-300 hover:text-gray-700 hover:bg-purple-300 hover:text-white"
            }
            pageLinkClassName={""}
            previousClassName={
              "block px-3 py-2 text-sm ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-purple-400 hover:text-white"
            }
            previousLinkClassName={""}
            nextClassName={
              "block px-3 py-2 leading-tight text-sm text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-purple-400 hover:text-white"
            }
            nextLinkClassName={""}
            breakClassName={
              "px-3 py-2 leading-tight text-sm text-gray-500 bg-white border border-gray-300 hover:bg-purple-400 hover:text-white"
            }
            breakLinkClassName={
              "px-3 py-2 leading-tight text-sm text-gray-500 bg-white border border-gray-300 hover:bg-purple-400 hover:text-white"
            }
            activeClassName={
              "z-10 px-3 py-2 leading-tight text-white border border-primary bg-primary hover:bg-purple-100 hover:text-primary"
            }
          />
        </div>
      </div> */}
    </div>
  );
};

export default ServiceTable;
