import React from "react";

interface DaysSelectorProps {
  selectedDays: string[];
  onChange: (days: string[]) => void;
  title: string;
}

const DaysSelector: React.FC<DaysSelectorProps> = ({
  selectedDays,
  onChange,
  title,
}) => {
  const days: string[] = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const handleCheckboxChange = (day: string) => {
    const updatedDays = selectedDays.includes(day)
      ? selectedDays.filter((selectedDay) => selectedDay !== day)
      : [...selectedDays, day];
    onChange(updatedDays);
  };

  return (
    <div className="my-2">
      <p className="text-xs mb-2">{title}</p>
      <div className=" flex items-center flex-wrap">
        {days.map((day) => (
          <label key={day} className="flex items-center mx-3 my-1">
            <input
              type="checkbox"
              value={day}
              checked={selectedDays.includes(day)}
              onChange={() => handleCheckboxChange(day)}
            />
            <span className="ml-1 text-xs">{day}</span>
          </label>
        ))}
      </div>
    </div>
  );
};

export default DaysSelector;
