import { useForm } from "react-hook-form";
import { Button, InputField, SelectField } from "../form";
import { textReg } from "../../utils/regex";
import { GoInbox } from "react-icons/go";
import useFilePicker from "../../hooks/useFilePicker";
import { MdCancel } from "react-icons/md";
import { useState } from "react";
import { Spinner } from "../generic";
import { toast } from "react-toastify";
import {
  CreateShift,
  CreateStaff,
} from "../../utils/services/api/hospital/staff";
import { useAuthContext } from "../../context/AuthContext";

export default ({ show, id, refresh, departmentId }: any) => {
  const { control, handleSubmit } = useForm();
  var { file, setfile, refreshfile } = useFilePicker();
  const [loading, setLoading] = useState(false);
  const { config, authUser } = useAuthContext();

  const ShiftTypes = [
    "Regular",
    "Weekend",
    "Holiday",
    "Night",
    "Morning",
    "Evening",
    "Split",
    "Rotational",
    "Overtime",
    "On-call",
    "Emergency",
    "Part-Time",
  ];

  const onSubmit = async (data: any) => {
    var postData = {
      staffID: id,
      deptID: departmentId,
      shiftDate: data.shiftdate,
      shiftEndDate: data.shiftenddate,
      shiftType: data.shifttype,
      startTime: data.startTime + ":00",
      endTime: data.endTime + ":00",
      brkStartTime: data.brkstartTime + ":00",
      brkEndTime: data.brkendTime + ":00",
      brkDetails: data.brkdetails,
      notes: data.notes,
      authorID: authUser.id,
    };

    setLoading(true);

    try {
      var res = await CreateShift(postData, config);
      if (res.success) {
        toast.success("Shift added");
      }
      // toast(res.message);
      show(false);
      setLoading(false);
      refresh();
    } catch (e: any) {
      console.log(e);
      toast.error(e);

      setLoading(false);
    }
  };

  return (
    <div className=" fixed top-0 left-0 w-screen flex h-screen z-[50] bg-[#3f3f3f5d] justify-center items-center">
      <Spinner loading={loading} />
      <div className=" min-w-[30%]  h-[80vh] overflow-y-scroll bg-white  shadow rounded px-6 py-4">
        <p className="font-bold text-black text-sm">Add staff shift</p>
        <div className="w-full">
          <SelectField
            control={control}
            name="shifttype"
            rules={{ required: "Shift type is required" }}
            placeholder="Select"
            title="Select Shift type"
            options={ShiftTypes}
          />
        </div>
        <div className="grid grid-cols-2 gap-3 ">
          <InputField
            control={control}
            name="shiftdate"
            rules={{ required: "Date is required" }}
            placeholder="Select a date"
            title="Shift start date"
            type="date" // Set type to "date" for a date input
          />
          <InputField
            control={control}
            name="shiftenddate"
            rules={{ required: "Date is required" }}
            placeholder="Select a date"
            title="Shift end date"
            type="date" // Set type to "date" for a date input
          />

          <InputField
            control={control}
            name="startTime"
            rules={{ required: "Required" }}
            placeholder="Start Time"
            title="Start Time"
            type="time"
          />

          <InputField
            control={control}
            name="endTime"
            rules={{ required: "Required" }}
            placeholder="End Time"
            title="End Time"
            type="time"
          />

          <InputField
            control={control}
            name="brkstartTime"
            placeholder="Break Start Time"
            title="Break Start Time"
            rules={{ required: "Required" }}
            type="time"
          />

          <InputField
            control={control}
            name="brkendTime"
            placeholder="Break End Time"
            rules={{ required: "Required" }}
            title=" Break End Time"
            type="time"
          />
        </div>
        <div className="w-full ">
          <InputField
            name="brkdetails"
            title="Break details"
            placeholder="Break details"
            control={control}
            rules={{
              required: "Break details",
              pattern: {
                value: textReg,
                message: "Text Only",
              },
            }}
          />
          <InputField
            name="notes"
            title="Note"
            placeholder="Note"
            control={control}
            rules={{
              required: "Note is required",
            }}
            isTextArea
          />
        </div>
        <div className="flex justify-end gap-4">
          <Button
            text="Cancel"
            outlined
            borderColor="gray-600"
            color="black"
            width="100"
            OnBtnClick={() => {
              show(false);
            }}
          />

          <Button
            text="Add"
            borderColor="gray-600"
            color="black"
            width="100"
            OnBtnClick={handleSubmit(onSubmit)}
          />
        </div>
      </div>
    </div>
  );
};
