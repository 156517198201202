import axios from "axios";
import { baseURL } from "../../../URL";
import { CreateServiceDTO, UpdateServiceDTO } from "../../../typings/service";

 
const GetallHostpitalShifts = async ( config: any) => {
    try {
        const response = await axios.get(`${baseURL}/shifts/hospital`, config);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};
 
export {   GetallHostpitalShifts }