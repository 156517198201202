import { useEffect, useState } from "react";
import { GetPatientByID } from "../utils/services/api/hospital/patient";
import { useAuthContext } from "../context/AuthContext";
const usePatientDetails = (id: any) => {
  const [patient, setPatient] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { config } = useAuthContext();
  const GetDetails = async (id: string) => {
    try {
      setLoading(true);

      let res = await GetPatientByID(id?.toString() as string, config);
      setPatient(res?.data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      GetDetails(id);
    }
  }, [id]);

  return { patient, loading };
};

export default usePatientDetails;
