import React, { useEffect, useState } from "react";
import { PricingContainer } from "../../components/hospitals";
import { turgl } from "../../constants/imports";
import { Button, InputField, SelectField } from "../../components/form";
import { useForm } from "react-hook-form";
import { emailReg, textReg } from "../../utils/regex";
import countries from "../../constants/data/countries.json";
import { PrimaryLink } from "../../components/links";
import { BsImage } from "react-icons/bs";
import { OnBoardingHostpital } from "../../utils/services/api/hospital/auth";
import { toast } from "react-toastify";
import { Spinner } from "../../components/generic";
import { useNavigate } from "react-router-dom";

interface selectedDto {
  plan: string;
  amount: string;
  subcriptionId: string;
  items: [];
}
interface StateDto {
  code: string;
  name: string;
  subdivision: null;
}

interface CountryDto {
  code2: string;
  code3: string;
  name: string;
  capital: string;
  region: string;
  subregion: string;
  states: StateDto[];
}

const HospitalOnboarding = () => {
  const [stateNames, setStateNames] = useState<string[]>([]);
  const [temp, setTemp] = useState<StateDto[] | null>(null);
  const [AllCountries, setAllCountries] = useState<CountryDto[]>([]);
  const [showPrice, setShowPrice] = useState(false);
  const [selected, setSelected] = useState<selectedDto>({
    subcriptionId: "1",
    plan: "Starter",
    amount: "Free",
    items: [],
  });
  const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState<string | ArrayBuffer | null>(
    null
  );

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  }; 
  const { control, watch, getValues, handleSubmit } = useForm();
  const row = "w-full block md:flex justify-between items-center";
  const col = "w-full md:w-[45%]";
  const countryNames: string[] = [];

  for (const item of (countries || []) as CountryDto[]) {
    if (item?.name !== undefined && item?.name !== "") {
      countryNames.push(item.name);
    }
  }
  useEffect(() => {
    setAllCountries(countries as never);
  }, []);
  useEffect(() => {
    const selectedCountry = getValues("hospitalCountry");
    const filteredCountry = AllCountries.find(
      (country) => country.name === selectedCountry
    );
    setTemp(filteredCountry?.states || null);
    // eslint-disable-next-line
  }, [AllCountries, getValues, watch("hospitalCountry")]);

  useEffect(() => {
    const names = (temp || []).map((state) => state.name).filter(Boolean);
    setStateNames(names);
  }, [temp]);
  const navigate = useNavigate();
  const onSubmit = async (data: any) => {
    if (data?.password !== data?.confirmpassword) {
      toast.error("Passwords Doesn't Match");
    } else {
      setLoading(true);
      try {
        setLoading(true);
        data.subcriptionId = "1";
        delete data.confirmpassword;
        await OnBoardingHostpital(data);
        navigate("/hospital/login");
        toast.success(
          "Account Created, Verification Link has been sent to your Email, Please verify your email to access your dashboard."
        );
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        toast.error(error);
      }
      setLoading(false);
    }
  };
  return (
    <div className="w-full bg-lightBg">
      <Spinner loading={loading} />
      <>
        {showPrice ? (
          <PricingContainer
            setShowPrice={setShowPrice}
            setSelected={setSelected}
          />
        ) : (
          <div className=" w-full flex justify-center items-start">
            <div className="md:w-[75%] md:px-12 px-8 w-full">
              {/* Logo */}
              <div className="w-full flex justify-center items-center my-10">
                <img src={turgl} alt="Logo" className="text-center" />
              </div>
              {/* Form Header */}
              <div className="my-5 w-[30%]">
                {/* Plan Details */}
                <div
                  className="bg-purple-100 flex justify-between items-center rounded-md p-3 cursor-pointer mb-5"
                  // onClick={() => setShowPrice(true)}
                >
                  <p className="font-medium text-sm">Selected Plan</p>
                  <p className="font-medium text-sm">
                    {selected?.plan}({selected?.amount})
                  </p>
                </div>
                <h1 className="text-xl font-semibold text-black">
                  Hospital Registration
                </h1>
                <p className="text-xs text-gray-600 mt-1">
                  Start your hospital registration process
                </p>
              </div>
              {/* Form COntainer */}
              <div className="flex items-start w-full">
                {/* Hospital Logo COl 1 */}
                <div className="w-[15%] flex flex-col justify-center items-center">
                  <div className="h-[100px] w-[100px] rounded-full bg-zinc-200 flex items-center justify-center">
                    {imagePreview ? (
                      <img
                        src={imagePreview.toString()}
                        alt="Preview"
                        style={{ width: "100%", height: "100%" }}
                      />
                    ) : (
                      <BsImage size={50} color="gray" />
                    )}
                  </div>
                  <label
                    htmlFor="imageUpload"
                    className=" cursor-pointer text-xs text-blue-500 text-center mt-3"
                  >
                    Upload
                  </label>
                  <input
                    type="file"
                    id="imageUpload"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                  />
                </div>
                {/* COl 2 */}
                <div className="mb-5 w-[85%]">
                  {/* form Content */}
                  <div className="bg-darkBg py-5 md:px-8 px-5 rounded-sm w-full">
                    {/* Form Row One (Hopital Name and Contact Person Name) */}
                    <div className={row}>
                      {/* col 1 */}
                      <div className={col}>
                        <InputField
                          name="hospitalName"
                          title="Organization Name"
                          placeholder="Organization Name"
                          control={control}
                          rules={{
                            required: "Organization Name is required",
                            pattern: {
                              value: textReg,
                              message: "Text Only",
                            },
                          }}
                        />
                      </div>
                      {/* Col 2 */}
                      <div className={col}>
                        <InputField
                          name="contactName"
                          title="Contact Name"
                          placeholder="Contact Name"
                          control={control}
                          rules={{
                            required: "First and Last Name is required",
                            pattern: {
                              value: textReg,
                              message: "Text Only",
                            },
                          }}
                        />
                      </div>
                    </div>
                    {/* Form Row Two (Contact Name and Phone Number) */}
                    <div className={row}>
                      {/* col 1 */}
                      <div className={col}>
                        <InputField
                          name="contactEmail"
                          title="Email"
                          placeholder="Email"
                          control={control}
                          rules={{
                            required: "Email is required",
                            pattern: {
                              value: emailReg,
                              message: "Invalid Email",
                            },
                          }}
                        />
                      </div>
                      {/* Col 2 */}
                      <div className={col}>
                        <InputField
                          name="contactPhone"
                          title="Phone Number"
                          placeholder="Phone Number"
                          control={control}
                          rules={{
                            required: "Phone Number is required",
                          }}
                        />
                      </div>
                    </div>
                    {/* password and Hostpital Type */}
                    <div className={row}>
                      {/* col 1 */}
                      <div className={col}>
                        <InputField
                          name="password"
                          title="Password"
                          placeholder="Enter Password"
                          control={control}
                          rules={{
                            required: "Password is required",
                          }}
                          type={"password"}
                        />
                      </div>
                      {/* Col 2 */}
                      <div className={col}>
                        <InputField
                          name="confirmpassword"
                          title="Confirm Password"
                          placeholder="Confirm Password"
                          control={control}
                          rules={{
                            required: "Confirm Password is required",
                          }}
                          type={"password"}
                        />
                      </div>
                    </div>
                    {/* Form Row Three (Country and  States) */}
                    <div className={row}>
                      {/* col 1 */}
                      <div className={col}>
                        <SelectField
                          control={control}
                          name="hospitalType"
                          rules={{ required: "Organization Type is required" }}
                          placeholder="Select"
                          title="Organization Type"
                          options={["Public", "Private"]}
                        />
                      </div>
                      {/* Col 2 */}
                      <div className={col}>
                        <SelectField
                          control={control}
                          name="hospitalCountry"
                          rules={{ required: "country is required" }}
                          placeholder="Select"
                          title="country"
                          options={countryNames}
                        />
                      </div>
                    </div>
                    {/* Form Row Four (Hosptal Address and Hospital  Area) */}
                    <div className={row}>
                      {/* col 1 */}
                      <div className={col}>
                        <SelectField
                          control={control}
                          name="hospitalState"
                          rules={{ required: "State is required" }}
                          placeholder="Select"
                          title="State"
                          options={stateNames}
                        />
                      </div>
                      {/* Col 2 */}
                      <div className={col}>
                        <InputField
                          name="hospitalArea"
                          title="Area"
                          placeholder="Area"
                          control={control}
                          rules={{
                            required: "Area is required",
                          }}
                        />
                      </div>
                    </div>
                    {/* Form Row Fivr */}
                    <div>
                      <InputField
                        name="hospitalAddress"
                        title="Organization Address"
                        placeholder="Organization Address"
                        control={control}
                        rules={{
                          required: "Organization Address is required",
                        }}
                      />
                    </div>
                  </div>
                  {/* Button */}
                  <div className="md:flex block justify-between items-center mt-5 mb-10">
                    <div className="md:flex block items-center justify-center">
                      <h1 className="text-black text-xs font-medium text-center mr-2">
                        Already have an account?
                      </h1>
                      <PrimaryLink
                        width={"100"}
                        text="Signin"
                        path="/hospital/login"
                        outlined
                      />
                    </div>
                    <div className="md:w-[180px] w-full">
                      <Button
                        text="Complete Registration"
                        OnBtnClick={handleSubmit(onSubmit)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default HospitalOnboarding;
