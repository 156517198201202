import React from "react";
import { Controller } from "react-hook-form";

interface SelectFieldProps {
  control: any;
  name: string;
  rules?: object;
  placeholder: string;
  setIsDisabled?: any;
  title: string;
  options: any;
  onselect?: any;
  isValue?: boolean;
}

const SelectField: React.FC<SelectFieldProps> = ({
  control,
  name,
  rules = {},
  placeholder,
  onselect,
  title,
  options,
  isValue,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <div className="my-2 ">
          <div className="flex items-center  justify-between">
            <p className="text-xs mb-2">{title}</p>
          </div>
          <select
            value={value}
            onChange={(e) => {
              onChange(e.target.value);

              try {
                onselect(e.target.value);
              } catch (e: unknown) {
                console.log(e);
              }
            }}
            className={`block bg-white text-xs py-2 px-4 rounded-md w-full shadow-xs border ${
              error ? "border-red-500" : "border-gray-200"
            }`}
          >
            <option value="" className="text-gray-500">
              {placeholder}
            </option>
            {options.map((option: any, index: number) => (
              <>
                {isValue ? (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ) : (
                  <option key={option} value={option}>
                    {option}
                  </option>
                )}
              </>
            ))}
          </select>
          {error && (
            <span className="text-red-500 text-xs self-stretch">
              {error.message || "Error"}
            </span>
          )}
        </div>
      )}
    />
  );
};

export default SelectField;
