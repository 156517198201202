import axios from "axios";
import { baseURL } from "../../../URL";

const RegisterPatient = async (postData: any, config: any) => {
  try {
    const response = await axios.post(`${baseURL}/patient`, postData, config);
    return response.data;
  } catch (error: any) {
    console.log("Patient Error", error);
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};

const onAddInsurance = async (postData: any, config: any) => {
  try {
    const response = await axios.post(`${baseURL}/insurance`, postData, config);
    return response.data;
  } catch (error: any) {
    console.log("Patient Error", error);
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const onUpdateInsurance = async (id:any,postData: any, config: any) => {
  try {
    const response = await axios.patch(`${baseURL}/insurance/${id}`, postData, config);
    return response.data;
  } catch (error: any) {
    console.log("Patient Error", error);
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const onAddDependant = async (postData: any, config: any) => {
  try {
    const response = await axios.post(`${baseURL}/dependant`, postData, config);
    return response.data;
  } catch (error: any) {
    console.log("Patient Error", error);
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const AddNOK = async (id: any, postData: any, config: any) => {
  try {
    const response = await axios.patch(
      `${baseURL}/patient/${id}`,
      postData,
      config
    );
    return response.data;
  } catch (error: any) {
    console.log("Patient Error", error);
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const GetAllPatients = async (currentPage: any, search: any, config: any) => {
  try {
    const response = await axios.get(
      `${baseURL}/patient?page=${currentPage}`,
      config
    );
    return response.data;
  } catch (error: any) {
    console.log();
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const GetFilteredPatient = async (params: any, config: any) => {
  try {
    const response = await axios.get(
      `${baseURL}/patient/search/${params}`,
      config
    );
    console.log("response.data", response.data);
    return response.data;
  } catch (error: any) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const GetPatientByID = async (id: any, config: any) => {
  try {
    const response = await axios.get(`${baseURL}/patient/${id}`, config);
    return response.data;
  } catch (error: any) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const GetPatientInsurance= async (id: any, config: any) => {
  try {
    const response = await axios.get(`${baseURL}/insurance/patient/${id}`, config);
    return response.data;
  } catch (error: any) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const GetPatientDependants= async (id: any, config: any) => {
  try {
    const response = await axios.get(`${baseURL}/dependant/patient/${id}`, config);
    return response.data;
  } catch (error: any) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const UpdatePatientByID = async (
  patientID: any,
  updateData: any,
  config: any
) => {
  try {
    const response = await axios.patch(
      `${baseURL}/patient/${patientID}`,
      updateData,
      config
    );
    return response.data;
  } catch (error: any) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const DeletePatientByID = async (id: any, config: any) => {
  try {
    const response = await axios.delete(`${baseURL}/patient/${id}`, config);
    return response.data;
  } catch (error: any) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};

export {

  GetPatientInsurance,
  RegisterPatient,
  onAddDependant,
  onAddInsurance,
  GetPatientDependants,
  AddNOK,
  GetAllPatients,
  onUpdateInsurance,
  GetPatientByID,
  UpdatePatientByID,
  DeletePatientByID,
  GetFilteredPatient,
};
