import { resolveTxt } from "dns";
import { Button, InputField, SelectField } from "../../../components/form";
import { useForm } from "react-hook-form";
import { emailReg, textReg } from "../../../utils/regex";
import { useEffect, useState } from "react";
import { onAddDependant } from "../../../utils/services/api/hospital/patient";
import { useAuthContext } from "../../../context/AuthContext";
import { toast } from "react-toastify";
import { Spinner } from "../../../components/generic";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa6";
import { MdClose } from "react-icons/md";

export default (props: any) => {
  const row = "w-full block md:flex justify-between items-center";
  const col = "w-full md:w-[30%]";
  const { handleSubmit, control, watch } = useForm();
  const [stateNames, setStateNames] = useState<string[]>([]);
  const countryNames: string[] = [];
  const [familymembers, setfamilymembers] = useState([]);
  const [familydataObject, setfamilydataObject] = useState({});
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { config } = useAuthContext();

  useEffect(() => {
    console.log(familymembers, familydataObject);
  }, [familymembers, familydataObject]);

  const AddFamily = async (data: any) => {
    setfamilydataObject(data);
    setfamilymembers((prev: any) => prev.concat([familymembers.length + 1]));
  };

  const onSubmit = async (data: any) => {
    console.log(data,"fami-----");
    setfamilydataObject(data);
    props.setpostdata({
      ...props.postdata,
      familymembers: familymembers,
      ...data,
    });
    try {
      await props.onRegister({
        ...props.postdata,
        familymembers: familymembers,
        ...data,
      });
      props.nextStep();
      props.setCurrentStepIndex(2);
    } catch (e: any) {
      toast.error(e);
      console.log(e);
    }
  };
  return (
    <div className="my-5 w-full px-8">
      <Spinner loading={loading} />

      <h1 className="text-xl font-semibold text-black mb-3">
        Add Family details
      </h1>
      <div className="flex  justify-start items-center my-2">
        {/* Back Button */}
        <button
          onClick={handleSubmit(AddFamily)}
          className="flex items-center justify-center bg-blue-500 p-2 rounded-md"
        >
          <FaPlus color="white" />
          <p className="text-xs text-white">Add Family</p>
        </button>
      </div>

      {/* form Content */}
      <div className="bg-darkBg py-4 px-8 rounded-sm w-full">
        {/* Form Row One (FirstName, LastName, Email) */}
        <div className={row}>
          {/* col 1 */}
          <div className={col}>
            <InputField
              name="fam_firstname0"
              title="First Name"
              placeholder="First Name"
              control={control}
              rules={{
                required: "First Name is required",
                pattern: {
                  value: textReg,
                  message: "Text Only",
                },
              }}
            />
          </div>
          {/* Col 2 */}
          <div className={col}>
            <InputField
              name="fam_lastname0"
              title="Last Name"
              placeholder="Last Name"
              control={control}
              rules={{
                required: "Last Name is required",
                pattern: {
                  value: textReg,
                  message: "Text Only",
                },
              }}
            />
          </div>
          {/* Col 3 */}
          <div className={col}>
            <InputField
              name="fam_phonenumber0"
              title="Phone Number"
              placeholder="Phone Number"
              control={control}
              // rules={{
              //   required: "Phone Number is required",
              // }}
            />
          </div>
        </div>
        {/* Form Row Two (Phone Number, Gender, DOB) */}
        <div className={row}>
          {/* Col 1*/}
          <div className={col}>
            <SelectField
              control={control}
              name="fam_gender0"
              rules={{ required: "Gender is required" }}
              placeholder="Select"
              title="Gender"
              options={["Male", "Female"]}
            />
          </div>
          {/* Col 2 */}
          <div className={col}>
            <SelectField
              control={control}
              name="fam_relationship0"
              rules={{ required: "Relationship is required" }}
              placeholder="Select"
              title="Relationship"
              options={[
                "Wife",
                "Son",
                "Daugther",
                "Mother",
                "Father",
                "Sister",
                "Others",
              ]}
            />
          </div>
          {/* COl 3 DOB */}
          <div className={col}>
            <InputField
              control={control}
              name="fam_dob0"
              placeholder="Select a date"
              title="Date of Birth"
              type="date" // Set type to "date" for a date input
              rules={{ required: "Date of Birth is required" }}
            />
          </div>
        </div>
      </div>
      {familymembers.map((member: any, key: number) => (
        <div className="bg-darkBg py-4 px-8 rounded-sm w-full">
          {/* Form Row One (FirstName, LastName, Email) */}
          <MdClose
            onClick={() => {
              setfamilymembers((prev: any) =>
                prev.filter((ele: any) => ele != member)
              );
            }}
            size={20}
            className="inline "
          />
          <div className={row}>
            {/* col 1 */}
            <div className={col}>
              <InputField
                name={`fam_firstname${key + 1}`}
                title="First Name"
                placeholder="First Name"
                control={control}
                rules={{
                  required: "First Name is required",
                  pattern: {
                    value: textReg,
                    message: "Text Only",
                  },
                }}
              />
            </div>
            {/* Col 2 */}
            <div className={col}>
              <InputField
                name={`fam_lastname${key + 1}`}
                title="Last Name"
                placeholder="Last Name"
                control={control}
                rules={{
                  required: "Last Name is required",
                  pattern: {
                    value: textReg,
                    message: "Text Only",
                  },
                }}
              />
            </div>
            {/* Col 3 */}
            <div className={col}>
              <InputField
                name={`fam_phonenumber${key + 1}`}
                title="Phone Number"
                placeholder="Phone Number"
                control={control}
                // rules={{
                //   required: "Phone Number is required",
                // }}
              />
            </div>
          </div>
          {/* Form Row Two (Phone Number, Gender, DOB) */}
          <div className={row}>
            {/* Col 1*/}
            <div className={col}>
              <SelectField
                control={control}
                name={`fam_gender${key + 1}`}
                rules={{ required: "Gender is required" }}
                placeholder="Select"
                title="Gender"
                options={["Male", "Female"]}
              />
            </div>
            {/* Col 2 */}
            <div className={col}>
              <SelectField
                control={control}
                name={`fam_relationship${key + 1}`}
                rules={{ required: "Relationship is required" }}
                placeholder="Select"
                title="Relationship"
                options={[
                  "Wife",
                  "Son",
                  "Daugther",
                  "Mother",
                  "Father",
                  "Sister",
                  "Others",
                ]}
              />
            </div>
            {/* COl 3 DOB */}
            <div className={col}>
              <InputField
                control={control}
                name={`fam_dob${key + 1}`}
                placeholder="Select a date"
                title="Date of Birth"
                type="date" // Set type to "date" for a date input
                rules={{ required: "Date of Birth is required" }}
              />
            </div>
          </div>
        </div>
      ))}
      {/* Button */}

      <div className="flex justify-end items-center mt-5 mb-10">
        <div className=" flex gap-4 ">
          <Button
            outlined
            text="Previous"
            OnBtnClick={() => {
              props.setCurrentStepIndex(1);
              props.previousStep();
            }}
          />
          <Button text="Complete" OnBtnClick={handleSubmit(onSubmit)} />
        </div>
      </div>
    </div>
  );
};
