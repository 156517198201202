import React, { useState } from "react";
import { turgl } from "../../../constants/imports";
import { Button, InputField } from "../../../components/form";
import { emailReg } from "../../../utils/regex";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { PrimaryLink } from "../../../components/links";
import { toast } from "react-toastify";
import { HostpitalLogin } from "../../../utils/services/api/hospital/auth";
import { Spinner } from "../../../components/generic";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit } = useForm();
  const navigate = useNavigate();
  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      let res = await HostpitalLogin(data);
      localStorage.setItem(`hosData`, JSON.stringify(res?.data));
      localStorage.setItem(`hosToken`, res?.token);
      navigate(`/`);
    } catch (error: any) {
      toast.error(error);
    }
    setLoading(false);
  };
  return (
    <div className=" bg-lightBg w-full h-[100vh] flex justify-center items-center">
      <Spinner loading={loading} />
      <div className="md:w-[40%] px-12 w-full">
        {/* Header */}
        <div className="w-full flex justify-center items-center mb-3">
          <img src={turgl} alt="Logo" className="text-center" />
        </div>
        <h1 className="text-lg mb-5 font-medium text-black text-center">
          Login
        </h1>
        {/* Email and Password Container */}
        <div className="w-full p-8 bg-darkBg rounded-lg">
          <InputField
            name="email"
            title="Email"
            placeholder="Email"
            control={control}
            rules={{
              required: "Email is required",
              pattern: {
                value: emailReg,
                message: "Invalid Email Address",
              },
            }}
          />
          <InputField
            name="password"
            title="Password"
            placeholder="Password"
            control={control}
            rules={{
              required: "Password is required",
            }}
            type="password"
          />
        </div>
        {/* Button and Forgit Passwords */}
        <div className="w-full flex items-center justify-between">
          <Link
            to={"/hospital/forgot-password"}
            className="font-semibold text-blue-600 text-sm"
          >
            Forgot Password?
          </Link>
          <div className="w-[120px] my-1">
            <Button text="Login" OnBtnClick={handleSubmit(onSubmit)} />
          </div>
        </div>
        {/* Register Link */}
        <h1 className="text-black font-medium text-center">
          Don't have an account?
        </h1>
        <div className="w-full flex items-center justify-center">
          <div className="w-[200px]">
            <PrimaryLink
              text="Create Hospital Account"
              path="/hospital/register"
              outlined
              color="primary"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
