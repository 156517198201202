import { resolveTxt } from "dns";
import { Button, InputField, SelectField } from "../../../components/form";
import { useForm } from "react-hook-form";
import { emailReg, textReg } from "../../../utils/regex";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaCheck } from "react-icons/fa6";

export default (props: any) => {
  const row = "w-full block md:flex justify-between items-center";
  const col = "w-full md:w-[30%]";
  const { handleSubmit, control } = useForm();
  const [stateNames, setStateNames] = useState<string[]>([]);
  const countryNames: string[] = [];
  const navigate = useNavigate();

  return (
    <div className="my-5 w-full px-8">
     
      {/* form Content */}
      <div className="bg-darkBg py-4 px-8 rounded-sm w-full">


      <h1 className="text-xl font-semibold text-black mb-3">
       <FaCheck size={30} className="text-green-400 inline "/> Patient Registered Successfully!
      </h1>

       </div>
      {/* Button */}
      <div className="flex justify-end items-center mt-5 mb-10">
        <div className=" flex gap-4 ">

          <Button
            text="Done"
            OnBtnClick={() => {
              navigate("/hospital/patients");
            }}
          />
        </div>
      </div>
    </div>
  );
};
