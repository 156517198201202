import { CiEdit } from "react-icons/ci"
import { PrimaryLink } from "../../../components/links"
import ProfileItem from "../../../components/generic/ProfileItem"
import { GetPatientDependants } from "../../../utils/services/api/hospital/patient";
import { useParams } from "react-router-dom";
import { useAuthContext } from "../../../context/AuthContext";
import { useEffect, useState } from "react";

export default ({patient}:any)=>{
const [dependants, setdependants] =useState([])

  const [loading, setLoading] = useState(false);
const {id}=useParams()
const {config} =useAuthContext()


  const GetDependants =async ()=>{
    try {

      setLoading(true)
      var res = await GetPatientDependants(id, config);
      setdependants(res.data);
    } catch (e: any) {
      console.log(e);
    } finally {
      setLoading(false);
    }



  }

  
useEffect(()=>{
  GetDependants()

},[])

    return (  
        <div className="bg-black p-6 rounded-lg my-5">
          {/* Container Header */}
          <div className="w-full flex justify-between items-start">
            {/* Logo Cont */}

            
            <div>
              <h1  className="text-white text-lg font-medium">
               Patient's Dependatnt/Family
              </h1>
            </div>
            {/* Edit Profile */}
            <div className="w-[100px]">
              <PrimaryLink
                outlined
                path={`/hospital/family/edit/${patient.id}`}
                color="white"
                text="Edit"
                Icon={CiEdit}
                borderColor="white"
              />
            </div>
          </div>
          {/* Container Content */}
      {dependants.map((item:any)=>(

<div>
{/* Description */}
<div className="w-full flex justify-between items-start">
  {/* Col 1 */}
  <div className="w-1/3">
    <ProfileItem
      title="Name"
      content={item?.name}
    />
  
    <ProfileItem
      title="Phone Number"
      content={item?.phoneNumber}
    />
  </div>
  {/* Col 2 */}
  <div className="w-1/3">
   
    
    <ProfileItem
      title="Gender"
      content={
        item?.gender ? item?.gender : "-"
      }
    />
  </div>
  {/* Col 3 */}
  <div className="w-1/3">
    <ProfileItem
      title="Relationship"
      content={
        item?.relationship
          ?  item?.relationship
          : "-"
      }
    />
  </div>
</div>
</div>
      ))} 
        </div>)
}