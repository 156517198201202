import React, { useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { Button, InputField, SelectField } from "../../../components/form";
import { useForm } from "react-hook-form";
import { emailReg, textReg } from "../../../utils/regex";
import { user } from "../../../constants/imports";
import { useNavigate, useParams } from "react-router-dom";
import {
  GetStaffByID,
  UpdateStaffByID,
} from "../../../utils/services/api/hospital/staff";
import { useAuthContext } from "../../../context/AuthContext";
import useDeptHook from "../../../hooks/useDeptHook";
import { toast } from "react-toastify";
import { Spinner } from "../../../components/generic";
const EditStaffDetails = () => {
  const [staff, setStaff] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { control, handleSubmit, setValue } = useForm();
  const { config } = useAuthContext();
  const { depts } = useDeptHook();
  const params = useParams();
  const { id } = params;
  const row = "w-full block md:flex justify-between items-center";
  const col = "w-full md:w-[45%]";
  // Extracting departmentName and id properties
  const departments: any = depts.map((item: any) => {
    return {
      value: item.id,
      label: item.departmentName,
    };
  });
  const onGetStaffDetails = async () => {
    setLoading(true);
    try {
      let res = await GetStaffByID(id?.toString() as string, config);
      setStaff(res?.data);
    } catch (error: any) {}
    setLoading(false);
  };
  useEffect(() => {
    onGetStaffDetails();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setValue("firstName", staff?.firstName);
    setValue("lastName", staff?.lastName);
    setValue("phone", staff?.phone);
    setValue("email", staff?.email);
    setValue("gender", staff?.gender);
    setValue("dob", staff?.dob);
    setValue("role", staff?.role);
    setValue("departmentId", staff?.departmentId);
    setValue("address", staff?.address);
  }, [staff, setValue]);
  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      await UpdateStaffByID(data, id, config);
      toast.success("Staff Details Updated Successfully!");
      navigate(-1);
    } catch (error: any) {
      toast.error(error);
    }
    setLoading(false);
  };
  return (
    <div className="w-full bg-lightBg p-5">
      <Spinner loading={loading} />
      {/* Header */}
      <div className="flex  justify-start items-start">
        {/* Back Button */}
        <button
          onClick={() => navigate(-1)}
          className="flex items-center justify-center bg-blue-500 py-1 pr-2 rounded-md"
        >
          <IoIosArrowBack color="white" />
          <p className="text-xs text-white">Back</p>
        </button>
        <div className="ml-4">
          <h1 className="text-lg font-medium text-black">{`${staff?.firstName} ${staff?.lastName}`}</h1>
          <p className="text-xs text-gray-600 mt-1">
            Manage and update this personnel details{" "}
          </p>
        </div>
      </div>
      <div className=" w-full flex justify-center mt-10 items-start">
        <div className="md:w-[70%] md:flex items-start  md:px-12 px-8 w-full">
          {/* Change Profile */}
          <div className="md:mr-5 md:w-[20%]">
            <img
              src={user}
              className="h-[100px] w-[100px] mx-auto mb-2 rounded-full"
              alt="Profile"
            />
            <p className="text-center text-blue-600 text-[11px] font-light">
              Change Profile
            </p>
          </div>
          {/* form Content */}
          <div className="md:w-[80%]">
            <div className=" bg-darkBg py-5 md:px-8 px-5 rounded-sm mb-5">
              {/* Form Row One (Hopital Name and Contact Person Name) */}
              <div className={row}>
                {/* col 1 */}
                <div className={col}>
                  <InputField
                    name="firstName"
                    title="First Name"
                    placeholder="First Name"
                    control={control}
                    rules={{
                      required: "First Name is required",
                      pattern: {
                        value: textReg,
                        message: "Text Only",
                      },
                    }}
                  />
                </div>
                {/* Col 2 */}
                <div className={col}>
                  <InputField
                    name="lastName"
                    title="Last Name"
                    placeholder="Last Name"
                    control={control}
                    rules={{
                      required: "Last Name is required",
                      pattern: {
                        value: textReg,
                        message: "Text Only",
                      },
                    }}
                  />
                </div>
              </div>
              {/* Form Row Two (Email and Phone Number) */}
              <div className={row}>
                {/* Col 1 */}
                <div className={col}>
                  <InputField
                    name="phone"
                    title="Phone Number"
                    placeholder="Phone Number"
                    control={control}
                    rules={{
                      required: "Phone Number is required",
                    }}
                  />
                </div>
                {/* col 2 */}
                <div className={col}>
                  <InputField
                    name="email"
                    title="Email"
                    placeholder="Email"
                    control={control}
                    rules={{
                      required: "Email is required",
                      pattern: {
                        value: emailReg,
                        message: "Invalid Email",
                      },
                    }}
                  />
                </div>
              </div>
              {/* Form Row Three (Gender and DOB) */}
              <div className={row}>
                {/* col 1 */}
                <div className={col}>
                  <SelectField
                    control={control}
                    name="gender"
                    rules={{ required: "Gender is required" }}
                    placeholder="Select"
                    title="Gender"
                    options={["Male", "Female"]}
                  />
                </div>
                {/* Col 2 */}
                <div className={col}>
                  <InputField
                    control={control}
                    name="dob"
                    placeholder="Select a date"
                    title="Date of Birth"
                    type="date" // Set type to "date" for a date input
                  />
                </div>
              </div>
              {/* Form Row Four (ROle and staff) */}
              <div className={row}>
                {/* col 1 */}
                <div className={col}>
                  <InputField
                    name="role"
                    title="Role"
                    placeholder="Role"
                    control={control}
                    rules={{
                      required: "Role is required",
                    }}
                  />
                </div>
                {/* Col 2 */}
                <div className={col}>
                  <SelectField
                    control={control}
                    name="departmentId"
                    placeholder="Select"
                    title="Department"
                    isValue
                    options={departments}
                  />
                </div>
              </div>
              {/* Form Row Five Address */}
              <div>
                <InputField
                  name="address"
                  title="Residential Address"
                  placeholder="Residential Address"
                  control={control}
                  rules={{
                    required: "Residential Address is required",
                  }}
                />
              </div>
            </div>
            {/* Button */}
            <div className="md:flex block justify-end items-center mt-3 mb-10">
              <div className="md:w-[150px] w-full">
                <Button
                  text="Save Changes"
                  OnBtnClick={handleSubmit(onSubmit)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditStaffDetails;
