import React from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import ReactPaginate from "react-paginate";
import { TableData } from "./Table";
import usePermissionHook from "../../../hooks/usePermissionHook";
import { PulseLoader } from "react-spinners";

const PermissionTable = () => {
  const {
    permissions,
    setCurrentPage,
    totalPages,
    currentPage,
    onDeletePermissionStaff,
    loading,
  } = usePermissionHook();
  const tbHeader = ["No.", "Roles", "Description", "Access", "Action"];
  const handlePageClick = async (data: any) => {
    setCurrentPage(data?.selected + 1);
  };
  if (loading) {
    return (
      <div className=" m-12  justify-items-center justify-center">
        <PulseLoader
          className=" m-12 justify-center"
          color={"#350080"}
          loading={loading}
          size={14}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  }
  return (
    <div className="overflow-x-auto">
      <table className="w-full bg-white">
        <thead className="w-full bg-[#EAECF0]">
          <tr className="w-full">
            {tbHeader.map((item, index) => (
              <th
                key={index}
                scope="col"
                className="px-6 py-3 uppercase text-left text-xs font-semibold whitespace-nowrap"
              >
                {item}
              </th>
            ))}
          </tr>
        </thead>
        {/* Table content */}
        <tbody className="w-full bg-white">
          {permissions.map((item: any, index) => {
            let access = item?.access?.split(",");
            return (
              <tr
                className="bg-white border-b pt-8  hover:bg-gray-50"
                key={index}
              >
                <TableData item={(index + 1).toString()} />
                <TableData item={item?.permissionName} />
                <TableData item={item?.description} />
                <td className="flex flex-row flex-wrap items-center ">
                  {access?.map((data: any, index: any) => (
                    <span
                      className="m-2 text-[9px] font-medium text-blue-600 bg-blue-50 rounded-lg py-1 px-2"
                      key={index}
                    >
                      {data}
                    </span>
                  ))}
                </td>
                <td>
                  <div className="flex items-center px-6 py-3">
                    {/* <Link to={`/hospital/staff/${item.id}`}>
                      <MdOutlineRemoveRedEye className=" text-blue-600 mx-4 text-lg" />
                    </Link> */}
                    <RiDeleteBin6Line
                      className="text-red-600 text-lg cursor-pointer"
                      onClick={() => onDeletePermissionStaff(item.id)}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* Table Footer (Pagination) */}
      {/* Table Footer (Pagination) */}
      <div className="w-full flex items-center justify-between">
        {/* Numbers */}
        <div>
          <p className="sub-heading text-black">{`${currentPage} of ${totalPages} Pages`}</p>
        </div>
        {/* NExt and Previous Btn */}
        {/* Pagination */}
        <div className="px-6 py-6">
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"inline-flex items-center -space-x-px"}
            pageClassName={
              "px-3 py-2 leading-tight text-sm text-gray-500 border border-gray-300 hover:text-gray-700 hover:bg-purple-300 hover:text-white"
            }
            pageLinkClassName={""}
            previousClassName={
              "block px-3 py-2 text-sm ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-purple-400 hover:text-white"
            }
            previousLinkClassName={""}
            nextClassName={
              "block px-3 py-2 leading-tight text-sm text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-purple-400 hover:text-white"
            }
            nextLinkClassName={""}
            breakClassName={
              "px-3 py-2 leading-tight text-sm text-gray-500 bg-white border border-gray-300 hover:bg-purple-400 hover:text-white"
            }
            breakLinkClassName={
              "px-3 py-2 leading-tight text-sm text-gray-500 bg-white border border-gray-300 hover:bg-purple-400 hover:text-white"
            }
            activeClassName={
              "z-10 px-3 py-2 leading-tight text-white border border-primary bg-primary hover:bg-purple-100 hover:text-primary"
            }
          />
        </div>
      </div>
    </div>
  );
};

export default PermissionTable;
