import React, { useState } from "react";
import { items } from "../../constants/data/menuItems";
import { Link } from "react-router-dom";

const HeaderMenu = () => {
  const [active, setActive] = useState("Dashboard");
  return (
    <div className="flex items-center  bg-[#E8EDFB]">
      {items.map((item, index) => (
        <Link
          key={index}
          to={item.path}
          onClick={() => setActive(item.label)}
          className={`flex flex-col items-center px-5 py-3 ${
            item.label === active ? "bg-white" : ""
          }`}
        >
          {/* Icon */}
          <img src={item.image} className="w-[25px] h-[25px]" alt="Logo" />
          {/* Label */}
          <p className="text-xs text-black font-medium mt-2 capitalize text-center">
            {item.label}
          </p>
        </Link>
      ))}
    </div>
  );
};

export default HeaderMenu;
