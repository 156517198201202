import { useEffect, useState } from "react";
import { onGetAllinvoices, onGetIvoiceDetails, onGetPatientinvoices } from "../utils/services/api/hospital/invoice";
import { useAuthContext } from "../context/AuthContext";

export default () => {
  const [invoices, setinvoices] = useState([]);
  const [isloading, setisLoding] = useState(false);
  const { config, authUser } = useAuthContext();
  const [totalPages, settotalPages] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const getAllInvoices = async () => {

    setisLoding(true);

    var response = await onGetAllinvoices(currentPage, config);

    setisLoding(false);

    setinvoices(response.data);

    settotalPages(response.totalPages);
  };
const getInvoiceDetails =async (id:number)=>{
var response = await onGetIvoiceDetails(id,config)
return response.data
}
  const getPatientInvoice =async (id:number) => {


    var response =await onGetPatientinvoices(id, config)

    
  }
  useEffect(() => {
    getAllInvoices();
  }, []);

  return { isloading,getPatientInvoice, getInvoiceDetails, setCurrentPage, currentPage, totalPages, invoices };
};
