import { useForm } from "react-hook-form";
import { Button, InputField, SelectField } from "../form";
import { textReg } from "../../utils/regex";
import { GoInbox } from "react-icons/go";
import useFilePicker from "../../hooks/useFilePicker";
import { MdCancel } from "react-icons/md";
import { useState } from "react";
import { Spinner } from "../generic";
import { toast } from "react-toastify";
import {
  CreateShift,
  CreateStaff,
} from "../../utils/services/api/hospital/staff";
import { useAuthContext } from "../../context/AuthContext";
import { onCreateTransaction } from "../../utils/services/api/hospital/invoice";

export default ({ show, item, selecteditem,refresh  }: any) => {
  const { control, handleSubmit } = useForm();
  var { file, setfile, refreshfile } = useFilePicker();
  const [loading, setLoading] = useState(false);
  const { config, authUser } = useAuthContext();

 const Modesofpay =["Cash", "Transfer", "POS","Account Deposit"]

  const onSubmit = async (data: any) => {
    var postData = {
        patientInvoiceID: item.id,
        amount: data.amount,
        paymentMethod: data.mode
      };

     
 if(Number(data.amount)>Number(selecteditem.amount)){

  toast.error("Amount exceeds invoice fee")

  return

 }

 
    setLoading(true);

    try {
      var res = await onCreateTransaction(postData, config);
      if (res.success) {
        toast.success("Transaction added");
      }
       show(false);
      setLoading(false);
      refresh();
    } catch (e: any) {
      console.log(e);
      toast.error(e);
      setLoading(false);
    }
  };

  return (
    <div className=" fixed top-0 left-0 w-screen flex h-screen z-[50] bg-[#3f3f3f5d] justify-center items-center">
      <Spinner loading={loading} />
      <div className=" min-w-[30%]    bg-white  shadow rounded px-6 py-4">
        <p className="font-bold text-black text-sm">Record Payment</p>
        <div className="w-full">
        
        
        <InputField
            control={control}
            name="amount"
            rules={{ required: "amount is required" }}
            placeholder="Enter amount paid"
            title="Amount paid"
            type="text" // Set type to "date" for a date input
          />
  <SelectField
            control={control}
            name="mode"
            rules={{ required: "mode of payment is required" }}
            placeholder="Select"
            title="Select mode of payment"
            options={Modesofpay}
          />
  <InputField
            control={control}
            name="reference"
            rules={{ required: "reference is required" }}
          placeholder="Reference"
            title="Reference number"
            type="text" // Set type to "date" for a date input
          />

        </div>
      
       
       
         
 
  
     
        <div className="flex justify-end gap-4">
          <Button
            text="Cancel"
            outlined
            borderColor="gray-600"
            color="black"
            width="100"
            OnBtnClick={() => {
              show(false);
            }}
          />

          <Button
            text="Add"
            borderColor="gray-600"
            color="black"
            width="100"
            OnBtnClick={handleSubmit(onSubmit)}
          />
        </div>
      </div>
    </div>
  );
};
