import { useForm } from "react-hook-form";
import { Button, InputField } from "../form";
import { textReg } from "../../utils/regex";
import { GoInbox } from "react-icons/go";
import useFilePicker from "../../hooks/useFilePicker";
import { MdCancel } from "react-icons/md";
import { useState } from "react";
import { Spinner } from "../generic";
import { toast } from "react-toastify";
import {
  onUploadDeptDoc,
  onUploadFile,
  onUploadStaffDoc,
} from "../../utils/services/api/hospital/services";
import { useAuthContext } from "../../context/AuthContext";
import { Toast } from "react-toastify/dist/components";

export default ({ type, show, id }: any) => {
  const { control, handleSubmit } = useForm();
  var { file, setfile, refreshfile } = useFilePicker();
  const [loading, setLoading] = useState(false);
  const { config } = useAuthContext();

  const pickfile = () => {
    document.getElementById("selectlogo")?.click();
  };

  const onSubmit = async (data: any) => {
    setLoading(true);

    //Staff add document implementation
    if (type == "staff") {
      try {
        var postData = {
          staffID: id,
          title: data.title,
          documentURL: "",
        };

        if (file) {
          let formData = new FormData();
          formData.append("file", file);
          let uploadFileres = await onUploadFile(formData, config);
          if (uploadFileres.success) {
            toast.success(uploadFileres.message);
            postData = { ...postData, documentURL: uploadFileres.data };
            let uploadStaffDocres = await onUploadStaffDoc(postData, config);
            toast.success(uploadStaffDocres.message);
            show(false);
          }
        } else {
          toast.error("File cannot be empty");
        }

        setLoading(false);
      } catch (e: any) {
        setLoading(false);
        console.log(e);
        toast.error(e);
      }
    }
   else if (type == "dept") {
      try {
        var deptpostData = {
          deptID: id,
          title: data.title,
          documentURL: "",
        };

        if (file) {
          let formData = new FormData();
          formData.append("file", file);
          let uploadFileres = await onUploadFile(formData, config);
          if (uploadFileres.success) {
            toast.success(uploadFileres.message);
            deptpostData = { ...deptpostData, documentURL: uploadFileres.data };
            let uploadDeptDocres = await onUploadDeptDoc(deptpostData, config);
            toast.success(uploadDeptDocres.message);
            show(false);
          }
        } else {
          toast.error("File cannot be empty");
        }

        setLoading(false);
      } catch (e: any) {
        setLoading(false);
        console.log(e);
        toast.error(e);
      }
    }
    setLoading(false);
  };

  return (
    <div className=" fixed top-0 left-0 w-screen flex h-screen z-[50] bg-[#3f3f3f5d] justify-center items-center">
      <Spinner loading={loading} />
      <div className=" min-w-[20%]   bg-white  shadow rounded px-6 py-10">
        <p className="font-bold text-black text-sm">Upload Document</p>
        <InputField
          name="title"
          title="Title"
          placeholder="Document title"
          control={control}
          rules={{
            required: "Document title is required",
            pattern: {
              value: textReg,
              message: "Text Only",
            },
          }}
        />
        <div className="bg-gray-100 rounded border flex flex-col justify-center items-center p-4">
          <GoInbox size={40} className="text-[#1677FF]" />
          <p className="text-gray-400 text-sm">
            Click or drag document to this area to upload
          </p>

          <div className="bg-gray-50 rounded flex items-center w-full border justify-between">
            <div
              onClick={pickfile}
              className=" border border-gray-300 rounded w-[30%] flex justify-center items-center p-2 m-1 font-light text-xs  cursor-pointer"
            >
              Select file
            </div>
            <p className="text-sm font-light jutify-self-start cursor-pointer     text-textbg  ml-3 truncate ">
              {!(file == undefined) ? file.name : ""}
            </p>
            {!(file == undefined) && (
              <MdCancel
                onClick={() => {
                  refreshfile();
                }}
                size={20}
                className="mr-2 "
              />
            )}
            <input
              onChange={(event) => {
                const files: any = event.target.files;
                const file = files[0];
                setfile(file);
              }}
              id="selectlogo"
              type="file"
              className=" hidden "
            />
          </div>
        </div>
        <div className="flex justify-end gap-4">
          <Button
            text="Cancel"
            outlined
            borderColor="gray-600"
            color="black"
            width="100"
            OnBtnClick={() => {
              show(false);
            }}
          />

          <Button
            text="Upload"
            borderColor="gray-600"
            color="black"
            width="100"
            OnBtnClick={handleSubmit(onSubmit)}
          />
        </div>
      </div>
    </div>
  );
};
