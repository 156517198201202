import { useEffect, useState } from "react";
import { useAuthContext } from "../context/AuthContext";
import { ServiceDTO } from "../utils/typings/service";
import {
  DeleteServiceByID,
  GetCategoryServcices,
} from "../utils/services/api/hospital/service";
import { toast } from "react-toastify";
import { GetCategoryByID } from "../utils/services/api/hospital/categories";
const useCategoryService = (id: any) => {
  const [services, setServices] = useState<ServiceDTO[]>([]);
  const [category, setCategory] = useState<CategoryDTO | undefined>();
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const { config } = useAuthContext();
  const onGetAllCategoryServices = async () => {
    try {
      setLoading(true);
      const temp = await GetCategoryServcices(id, config);
      setServices(temp?.data);
      setTotalPages(temp?.pagination?.totalPages);
      setTotal(temp?.pagination?.totalRecords);
      setCurrentPage(temp.pagination?.currentPage);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setServices([]);
    }
  };
  const onGetCategoryByID = async () => {
    try {
      setLoading(true);
      const temp = await GetCategoryByID(id, config);
      setCategory(temp?.data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };
  useEffect(() => {
    
      onGetAllCategoryServices();
     // onGetCategoryByID();
 
  }, []);


  const onDeleteService = async (delID: any) => {
    setLoading(true);
    try {
      await DeleteServiceByID(delID, config);
      await onGetAllCategoryServices();
      toast.success("Service Deleted Successfully");
    } catch (error: any) {
      toast.error(error);
    }
    setLoading(false);
  };
  return {
    services,
    category,
    total,
    currentPage,
    setCurrentPage,
    totalPages,
    loading,
    onGetAllCategoryServices,
    onDeleteService,
    setServices,
  };
};

export default useCategoryService;
