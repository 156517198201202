import React from "react";
import { GoPlus } from "react-icons/go";
import { IoSearch } from "react-icons/io5";
import { PrimaryLink } from "../../../components/links";
import { Button } from "../../../components/form";
import { FaPlus } from "react-icons/fa6";
import useInvoiceHook from "../../../hooks/useInvoiceHook";
import { PulseLoader } from "react-spinners";
import { empty } from "../../../constants/imports";
import InvoiceTable from "../../../components/layouts/table/InvoiceTable";
import { useNavigate } from "react-router-dom";

const InvoiceListings = () => {
  const { isloading, invoices, setCurrentPage, totalPages, currentPage } =
    useInvoiceHook();
  const navigate = useNavigate();
  return (
    <div className="p-5 w-full bg-white ">
      {/* Header */}
      <div className="w-full flex items-start justify-between">
        {/* Header Desc */}
        <div className="">
          <h1 className="heading">Transactions</h1>
          <p className="sub-heading">
            Track and manage financial transactions within the hospital system.
          </p>
        </div>
        {/* Button */}
        <PrimaryLink
          Icon={GoPlus}
          text="Create invoice"
          path="/hospital/invoice/create"
          width="200"
          color="white"
        />
      </div>
      {/* Seacrh and Filter */}
      <div className="flex items-center space-x-4 mb-5">
        {/* Search Field */}
        <div className="relative w-[300px]">
          <IoSearch className="absolute top-2 left-2 text-xl text-zinc-400" />
          <input
            type={"text"}
            placeholder={"Search"}
            onChange={(e) => {
              // if (e.target.value.length === 0) {
              //   setSearch("");
              // }
              // setSearch(e.target.value);
            }}
            className={`pl-8 block bg-white text-xs py-2 px-4 rounded-md w-full shadow-xs border border-gray-300}`}
          />
        </div>
      </div>

      {/* COntent */}
      <div>
        {isloading ? (
          <div className=" m-12  justify-items-center justify-center">
            <PulseLoader
              className=" m-12 justify-center"
              color={"#350080"}
              loading={isloading}
              size={14}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ) : (
          <>
            {invoices.length > 0 ? (
              <InvoiceTable
                loading={isloading}
                invoices={invoices}
                totalPages={totalPages}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
              />
            ) : (
              <div className="w-full h-full flex justify-center items-center">
                <div>
                  <img
                    src={empty}
                    className="h-[100px] w-[100px] mx-auto"
                    alt="Empty"
                  />
                  <p className="sub-heading text-center">
                    You are yet to create an invoice
                  </p>
                  <PrimaryLink
                    Icon={GoPlus}
                    text="Create invoice"
                    path="/hospital/invoice/create"
                    width="200"
                    color="white"
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default InvoiceListings;
