import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HospitalRoutes from "./routes/HospitalRoutes";
import AuthContextProvider from "./context/AuthContext";

const App = () => {
  return (
    <AuthContextProvider>
      <HospitalRoutes />
      <ToastContainer
        toastStyle={{ backgroundColor: "white", color: "black" }}
      />
    </AuthContextProvider>
  );
};

export default App;
