import React, { useEffect, useState } from "react";
import { GoPlus } from "react-icons/go";
import { empty } from "../../../constants/imports";
import { ServiceTable } from "../../../components/layouts/table";
import { PulseLoader } from "react-spinners";
import { Button, InputField } from "../../../components/form";
import { FaRegTimesCircle } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { Spinner } from "../../../components/generic";
import { toast } from "react-toastify";
import { useAuthContext } from "../../../context/AuthContext";
import useCategoryService from "../../../query/useCategoryService";
import { useParams } from "react-router-dom";
import { ServiceDTO } from "../../../utils/typings/service";
import { numReg } from "../../../utils/regex";
import {
  CreateService,
  UpdateService,
} from "../../../utils/services/api/hospital/service";
import { IoSearch } from "react-icons/io5";

const ServicesIndex = () => {
  const [createModal, setCreateModal] = useState(false);
  const [activeItem, setActiveItem] = useState<ServiceDTO | null>(null);
  const [search, setSearch] = useState("");
  const params = useParams();
  const { id } = params;
  const { loading, services, setServices, category, onGetAllCategoryServices } =
    useCategoryService(id);
  useEffect(() => {
    if (!createModal) {
      onGetAllCategoryServices();
    }
  }, [createModal]);

  useEffect(() => {
    console.log(services, "hhh");
  }, [services]);
  useEffect(() => {
    if (activeItem !== null) {
      setCreateModal(true);
    } else {
      setCreateModal(false);
    }
  }, [activeItem]);
  return (
    <div className="w-full bg-white p-5">
      {/* Header */}
      <div className="w-full flex items-start justify-between">
        {/* Header Desc */}
        <div className="">
          <h1 className="heading">{"Services"}</h1>
          <div className="">
            {/* Seacrh and Filter */}
            <div className="flex   justify-between items-center space-x-4 w-[100vw] px-10 mb-5">
              {/* Search Field */}
              <div className="relative w-[300px]">
                <IoSearch className="absolute top-2 left-2 text-xl text-zinc-400" />
                <input
                  type={"text"}
                  placeholder={"Search"}
                  onChange={(e: any) => {
                    if (e.target.value == "") {
                      onGetAllCategoryServices();
                    } else {
                      setServices((prev) =>
                        prev.filter((i) =>
                          i.ssName
                            .toLowerCase()
                            .includes(e.target.value.toLowerCase())
                        )
                      );
                    }
                  }}
                  className={`pl-8 block bg-white text-xs py-2 px-4 rounded-md w-full shadow-xs border border-gray-300}`}
                />
              </div>

              {/* Create Service */}
              <Button
                text="Create Service"
                width="180"
                OnBtnClick={() => setCreateModal(true)}
                color="white"
                Icon={GoPlus}
              />
            </div>
          </div>
        </div>
      </div>
      {/* COntent */}
      <div>
        {loading ? (
          <div className=" m-12  justify-items-center justify-center">
            <PulseLoader
              className=" m-12 justify-center"
              color={"#350080"}
              loading={loading}
              size={14}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ) : (
          <>
            {services.length > 0 ? (
              <ServiceTable
                services={services}
                id={id as any}
                setActiveItem={setActiveItem}
              />
            ) : (
              <div className="w-full h-full flex justify-center items-center">
                <div>
                  <img
                    src={empty}
                    className="h-[100px] w-[100px] mx-auto"
                    alt="Empty"
                  />
                  <p className="sub-heading text-center">
                    you are yet to create a service under
                  </p>
                  <Button
                    text="Create Service"
                    width="180"
                    OnBtnClick={() => setCreateModal(true)}
                    color="white"
                    Icon={GoPlus}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {/* Modal */}
      {createModal && (
        <Modal
          activeItem={activeItem as any}
          setActiveItem={setActiveItem}
          setCreateModal={setCreateModal}
          id={id as any}
        />
      )}
    </div>
  );
};

export default ServicesIndex;

interface ModalProps {
  id: number;
  activeItem: ServiceDTO | null;
  setActiveItem: (activeItem: ServiceDTO | null) => void;
  setCreateModal: (value: boolean) => void;
}
const Modal: React.FC<ModalProps> = ({
  activeItem,
  setActiveItem,
  setCreateModal,
  id,
}) => {
  const [loading, setLoading] = useState(false);
  const { config } = useAuthContext();
  const { control, handleSubmit, setValue } = useForm();
  useEffect(() => {
    if (activeItem !== null) {
      setValue("serviceName", activeItem.ssName);
      setValue("price", activeItem.amount);
      setValue("description", activeItem.description);
    }
  }, [activeItem]);
  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      let postData = {
        ssName: data?.serviceName,
        amount: data?.price,

        description: data?.description,
      };
      if (activeItem) {
        await UpdateService(activeItem.id, postData, config);
        toast.success("Service Edited Successfully!");
        setActiveItem(null);
      } else {
        await CreateService({ ...postData, serviceID: 1 }, config);
        toast.success("Service Added Successfully!");
      }
      setCreateModal(false);
    } catch (error: any) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="h-full w-full fixed  top-24 left-0 pt-10">
      <Spinner loading={loading} />
      <div className="w-full h-full flex justify-center items-start">
        <div className="w-[30%] bg-white shadow-md rounded-md px-4 pt-4">
          {/* Header */}
          <div className="w-full flex justify-between items-center">
            <h2 className="text-sm font-medium text-black">Add Service</h2>
            <FaRegTimesCircle
              className="cursor-pointer text-gray-300"
              onClick={() => {
                setActiveItem(null);
                setCreateModal(false);
              }}
            />
          </div>
          {/* Form Content */}
          <div className={""}>
            <InputField
              name="serviceName"
              placeholder="Name of Service"
              control={control}
              rules={{
                required: "Name of Service is required",
              }}
            />
            <InputField
              name="price"
              placeholder="price"
              control={control}
              rules={{
                pattern: {
                  value: numReg,
                  message: "Numbers Only",
                },
              }}
              isTrim
            />
          </div>
          <div className={""}>
            <InputField
              name="description"
              title="Description"
              placeholder="Description"
              control={control}
              isTextArea
            />
          </div>
          {/* Button */}
          <div className="w-full flex items-center justify-end gap-5">
            <Button
              text="Cancel"
              width="100"
              OnBtnClick={() => {
                setActiveItem(null);
                setCreateModal(false);
              }}
              color="black"
              outlined
              borderColor="gray-300"
            />
            <Button
              text={activeItem ? "Update" : "Add"}
              width="100"
              OnBtnClick={handleSubmit(onSubmit)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
