import React, { FC } from "react";
interface TableDataProps {
  item: string;
  ismain?: boolean;
  color?: string;
}
const TableData: FC<TableDataProps> = ({ item, ismain, color }) => {
  return (
    <td
      className={`px-6 capitalize py-3 ${ismain ? "w-[60%]" : ""} text-xs ${
        color ? color : "text-black"
      }  ${
        item.toString().toLowerCase().includes("ongoing")
          ? "!text-blue-400"
          : ""
      } ${
        item.toString().toLowerCase().includes("complete")
          ? "!text-green-400"
          : ""
      } `}
    >
      {item}
    </td>
  );
};
export { TableData };
