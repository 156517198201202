import React from "react";
import { GoPlus } from "react-icons/go";
import { empty } from "../../../constants/imports";
import { PrimaryLink } from "../../../components/links";
import { IoSearch } from "react-icons/io5";
import usePatientHook from "../../../hooks/usePatientHook";
import { PatientTable } from "../../../components/layouts/table";
import { PulseLoader } from "react-spinners";

const PatientListing = () => {
  const { loading, patients, setSearch, setCurrentPage, totalPages, currentPage, onDeletePatient } = usePatientHook();
  return (
    <div className="w-full bg-white p-5">
      {/* Header */}
      <div className="w-full flex items-start justify-between">
        {/* Header Desc */}
        <div className="">
          <h1 className="heading">Patients</h1>
          <p className="sub-heading">
            Access and manage patient information and appointments.
          </p>
        </div>
        {/* Button */}
        <PrimaryLink
          Icon={GoPlus}
          text="Register New Patient"
          path="/hospital/patients/add"
          width="200"
          color="white"
        />
      </div>
      {/* Seacrh and Filter */}
      <div className="flex items-center space-x-4 mb-5">
        {/* Search Field */}
        <div className="relative w-[300px]">
          <IoSearch className="absolute top-2 left-2 text-xl text-zinc-400" />
          <input
            type={"text"}
            placeholder={"Search"}
            onChange={(e) => {
              if (e.target.value.length === 0) {
                setSearch("");
              }
              setSearch(e.target.value);
            }}
            className={`pl-8 block bg-white text-xs py-2 px-4 rounded-md w-full shadow-xs border border-gray-300}`}
          />
        </div>
      </div>
      {/* COntent */}
      <div>
        {loading ? (
          <div className=" m-12  justify-items-center justify-center">
            <PulseLoader
              className=" m-12 justify-center"
              color={"#350080"}
              loading={loading}
              size={14}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ) : (
          <>
            {patients.length > 0 ? (
              <PatientTable  loading ={loading} patients={patients} setSearch={setSearch} setCurrentPage ={setCurrentPage} totalPages={totalPages} currentPage={currentPage} onDeletePatient ={onDeletePatient} />
            ) : (
              <div className="w-full h-full flex justify-center items-center">
                <div>
                  <img
                    src={empty}
                    className="h-[100px] w-[100px] mx-auto"
                    alt="Empty"
                  />
                  <p className="sub-heading text-center">
                    you are yet to add a Patient
                  </p>
                  <PrimaryLink
                    Icon={GoPlus}
                    text="Register New Patient"
                    path="/hospital/patients/add"
                    width="200"
                    color="white"
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PatientListing;
