import React, { useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { Button, InputField, SelectField } from "../../../components/form";
import { useForm } from "react-hook-form";
import { emailReg, textReg } from "../../../utils/regex";
import { user } from "../../../constants/imports";
import { useNavigate } from "react-router-dom";
import useDeptHook from "../../../hooks/useDeptHook";
import { toast } from "react-toastify";
import { useAuthContext } from "../../../context/AuthContext";
import { CreateStaff } from "../../../utils/services/api/hospital/staff";
import { Spinner } from "../../../components/generic";
import usePermissionHook from "../../../hooks/usePermissionHook";
import { onUploadFile } from "../../../utils/services/api/hospital/services";

const AddStaff = () => {
  const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState<string | ArrayBuffer | null>(
    null
  );
  const [imageFile, setImageFile] = useState<any>(null);
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm();
  const { depts } = useDeptHook();
  const { permissions } = usePermissionHook();
  const { config } = useAuthContext();
  const row = "w-full block md:flex justify-between items-center";
  const col = "w-full md:w-[45%]";
  // Extracting departmentName and id properties

  const departments: any = depts.map((item: any) => {
    return {
      value: item.id,
      label: item.departmentName,
    };
  });

  // Extracting PermissionNames
  const roles: any = permissions.map((item: any) => {
    return {
      value: item.permissionName,
      label: item.permissionName,
    };
  });
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    setImageFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      var form = {
        firstName: data?.firstName,
        lastName: data?.lastName,
        phone: data?.phone,
        email: data?.email,
        gender: data?.gender,
        dob: data?.dob,
        address: data?.address,
        role: data?.role,
        departmentId: data?.departmentId,
        photo:    "https://res.cloudinary.com/dkf4jbia2/image/upload/v1725544019/t8pprlhpnrrqdcscuvfk.png",   };

      if (imageFile) {
        //Upload image and get url
        const formdata = new FormData();
        formdata.append("file", imageFile, imageFile?.name);
        try {
          var fileUploadres = await onUploadFile(formdata, config);

          if (fileUploadres.success) {
            form = { ...form, photo: fileUploadres.data };
          }
        } catch (e: any) {
          toast.error(e);
          console.log(e);
        }
      }
      await CreateStaff(form, config);
      toast.success("Staff Registered Successfully!");
      navigate("/hospital/staff");
    } catch (error: any) {
      toast.error(error);
    }
    setLoading(false);
  };
  return (
    <div className="w-full bg-lightBg p-5">
      <Spinner loading={loading} />
      {/* Header */}
      <div className="flex  justify-start items-start">
        {/* Back Button */}
        <button
          onClick={() => navigate(-1)}
          className="flex items-center justify-center bg-blue-500 py-1 pr-2 rounded-md"
        >
          <IoIosArrowBack color="white" />
          <p className="text-xs text-white">Back</p>
        </button>
        <div className="ml-4">
          <h1 className="text-lg font-medium text-black">Add Staff</h1>
        </div>
      </div>
      <div className=" w-full flex justify-center mt-10 items-start">
        <div className="md:w-[70%] md:flex items-start  md:px-12 px-8 w-full">
          {/* Change Profile */}
          <div className="md:mr-5 md:w-[20%]">
            {imagePreview ? (
              <img
                src={imagePreview.toString()}
                alt="Preview"
                className="h-[100px] w-[100px] mx-auto mb-2 rounded-full"
              />
            ) : (
              <img
                src={user}
                className="h-[100px] w-[100px] mx-auto mb-2 rounded-full"
                alt="Profile"
              />
            )}
            <div className="flex flex-col items-center justify-center">
              <label
                htmlFor="imageUpload"
                className="text-center text-blue-600 text-[11px] font-light mt-3 cursor-pointer"
              >
                Add Photograph{" "}
              </label>
              <input
                type="file"
                id="imageUpload"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
            </div>
          </div>
          {/* form Content */}
          <div className="md:w-[80%]">
            <div className=" bg-darkBg py-5 md:px-8 px-5 rounded-sm mb-5">
              {/* Form Row One */}
              <div className={row}>
                {/* col 1 */}
                <div className={col}>
                  <InputField
                    name="firstName"
                    title="First Name"
                    placeholder="First Name"
                    control={control}
                    rules={{
                      required: "First Name is required",
                      pattern: {
                        value: textReg,
                        message: "Text Only",
                      },
                    }}
                  />
                </div>
                {/* Col 2 */}
                <div className={col}>
                  <InputField
                    name="lastName"
                    title="Last Name"
                    placeholder="Last Name"
                    control={control}
                    rules={{
                      required: "Last Name is required",
                      pattern: {
                        value: textReg,
                        message: "Text Only",
                      },
                    }}
                  />
                </div>
              </div>
              {/* Form Row Two (Email and Phone Number) */}
              <div className={row}>
                {/* Col 1 */}
                <div className={col}>
                  <InputField
                    name="phone"
                    title="Phone Number"
                    placeholder="Phone Number"
                    control={control}
                    rules={{
                      required: "Phone Number is required",
                    }}
                  />
                </div>
                {/* col 2 */}
                <div className={col}>
                  <InputField
                    name="email"
                    title="Email"
                    placeholder="Email"
                    control={control}
                    rules={{
                      required: "Email is required",
                      pattern: {
                        value: emailReg,
                        message: "Invalid Email",
                      },
                    }}
                  />
                </div>
              </div>
              {/* Form Row Three (Gender and DOB) */}
              <div className={row}>
                {/* col 1 */}
                <div className={col}>
                  <SelectField
                    control={control}
                    name="gender"
                    rules={{ required: "Gender is required" }}
                    placeholder="Select"
                    title="Gender"
                    options={["Male", "Female"]}
                  />
                </div>
                {/* Col 2 */}
                <div className={col}>
                  <InputField
                    control={control}
                    name="dob"
                    rules={{ required: "Gender is required" }}
                    placeholder="Select a date"
                    title="Date of Birth"
                    type="date" // Set type to "date" for a date input
                  />
                </div>
              </div>
              {/* Form Row Four (ROle and Dept) */}
              <div className={row}>
                {/* col 1 */}
                <div className={col}>
                  <SelectField
                    control={control}
                    name="role"
                    rules={{ required: "Role is required" }}
                    placeholder="Select"
                    title="Role"
                    options={roles}
                    isValue
                  />
                </div>
                {/* Col 2 */}
                <div className={col}>
                  <SelectField
                    control={control}
                    name="departmentId"
                    placeholder="Select"
                    title="Department"
                    options={departments}
                    isValue
                  />
                </div>
              </div>
              {/* Form Row Five Address */}
              <div>
                <InputField
                  name="address"
                  title="Residential Address"
                  placeholder="Residential Address"
                  control={control}
                  rules={{
                    required: "Residential Address is required",
                  }}
                />
              </div>
            </div>
            {/* Button */}
            <div className="md:flex block justify-end items-center mt-3 mb-10">
              <div className="md:w-[150px] w-full">
                <Button text="Add Staff" OnBtnClick={handleSubmit(onSubmit)} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddStaff;
