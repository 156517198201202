import React, { useEffect, useState } from "react";
import { GoPlus } from "react-icons/go";
import { IoSearch } from "react-icons/io5";
import { PrimaryLink } from "../../../components/links";
import { Button } from "../../../components/form";
import { FaPlus } from "react-icons/fa6";
import useInvoiceHook from "../../../hooks/useInvoiceHook";
import { PulseLoader } from "react-spinners";
import { empty } from "../../../constants/imports";
import InvoiceTable from "../../../components/layouts/table/InvoiceTable";
import { useNavigate, useParams } from "react-router-dom";
import usePatientDetails from "../../../query/usePatientDetails";

export default () => {
  const { isloading, invoices, setCurrentPage, totalPages, currentPage } =
    useInvoiceHook();
  const { id } = useParams();
  const [totalinvoice_, settotalinvoice] = useState("0");
  const [totaloutstanding_, settotaloutstanding] = useState("0");
  const { loading, patient } = usePatientDetails(id);
  const [totalpaid_, settotalpaid] = useState("0");
  const [invoicesPat, setinvoicesPat] = useState([]);

  useEffect(() => {
    setinvoicesPat(
      invoices.filter((jk: any) =>
        jk?.fullName
          .toLowerCase()
          .includes(
            `${patient?.firstName.toLowerCase()} ${patient?.lastName.toLowerCase()}`
          )
      )
    );
  }, [invoices]);
  useEffect(() => {
    let totalamount = 0;
    let outstanding = 0;
    let totalpaid = 0;

    let array = [];
    invoicesPat.map((it: any) => {
      console.log(it, "items");
      totalamount += parseInt(it.amount);
      outstanding += parseInt(it.amount) - parseInt(it.paid);
      totalpaid += parseInt(it.paid);
    });

    let formattedAmount1 = new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
    }).format(totalamount);
    settotalinvoice(formattedAmount1);
    let formattedAmount2 = new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
    }).format(outstanding);

    let formattedAmount3 = new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
    }).format(totalpaid);

    settotaloutstanding(formattedAmount2);
    settotalpaid(formattedAmount3);
    console.log(id, "patid");
  }, [invoicesPat]);

  const navigate = useNavigate();
  return (
    <div className="p-5 w-full bg-white ">
      {/* Header */}
      <div className="w-full flex items-start justify-between">
        {/* Header Desc */}
        <div className=" flex gap-4">
          <div className=" px-4 py-1 bg-blue-100 border border-blue-600 rounded-[10px] ">
            <small className="text-xs text-gray-600">Total invoice </small>
            <p className="font-semibold">{totalinvoice_}</p>
          </div>
          <div className=" px-4 py-1 bg-green-100 border border-green-600 rounded-[10px] ">
            <small className="text-xs text-gray-600">Total paid </small>
            <p className="font-semibold">{totalpaid_}</p>
          </div>
          <div className=" px-4 py-1 bg-orange-100 border border-orange-600 rounded-[10px] ">
            <small className="text-xs text-gray-600">Total outstanding </small>
            <p className="font-semibold">{totaloutstanding_}</p>
          </div>
        </div>
        {/* Button */}
        <PrimaryLink
          Icon={GoPlus}
          text="Create invoice"
          path={`/hospital/invoice/create/patient/${id}`}
          width="200"
          color="white"
        />
      </div>
      {/* Seacrh and Filter */}
      <div className="flex items-center space-x-4 mb-5">
        {/* Search Field */}
        <div className="relative w-[300px]">
          <IoSearch className="absolute top-2 left-2 text-xl text-zinc-400" />
          <input
            type={"text"}
            placeholder={"Search"}
            onChange={(e) => {
              // if (e.target.value.length === 0) {
              //   setSearch("");
              // }
              // setSearch(e.target.value);
            }}
            className={`pl-8 block bg-white text-xs py-2 px-4 rounded-md w-full shadow-xs border border-gray-300}`}
          />
        </div>
      </div>

      {/* COntent */}
      <div>
        {isloading ? (
          <div className=" m-12  justify-items-center justify-center">
            <PulseLoader
              className=" m-12 justify-center"
              color={"#350080"}
              loading={isloading}
              size={14}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ) : (
          <>
            {invoicesPat.length > 0 ? (
              <InvoiceTable
                loading={isloading}
                invoices={invoicesPat}
                totalPages={totalPages}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
              />
            ) : (
              <div className="w-full h-full flex justify-center items-center">
                <div>
                  <img
                    src={empty}
                    className="h-[100px] w-[100px] mx-auto"
                    alt="Empty"
                  />
                  <p className="sub-heading text-center">
                    You are yet to create an invoice for this patient
                  </p>
                  {/* <PrimaryLink
                    Icon={GoPlus}
                    text="Create invoice"
                    path="/hospital/invoice/create"
                    width="200"
                    color="white"
                  /> */}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
