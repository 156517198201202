import React, { useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { Button, InputField, SelectField } from "../../../components/form";
import { useForm } from "react-hook-form";
import { emailReg, textReg } from "../../../utils/regex";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuthContext } from "../../../context/AuthContext";
import { Spinner } from "../../../components/generic";
import countries from "../../../constants/data/countries.json";
import {
  AddNOK,
  onAddDependant,
  onAddInsurance,
  RegisterPatient,
} from "../../../utils/services/api/hospital/patient";
import WizardStepIndicator from "wizard-step-indicator-react";
import StepWizard from "react-step-wizard";
import AddInsurance from "./AddInsurance";
import AddFamily from "./AddFamily";
import AddNok from "./AddNok";
import PatientAdded from "./PatientAdded";

interface StateDto {
  code: string;
  name: string;
  subdivision: null;
}

interface CountryDto {
  code2: string;
  code3: string;
  name: string;
  capital: string;
  region: string;
  subregion: string;
  states: StateDto[];
}
const AddPatient = () => {
  const [postdata, setpostdata] = useState<any>({});

  const [currentStepIndexState, setCurrentStepIndex] = useState(0);

  const [stateNames, setStateNames] = useState<string[]>([]);
  const [registersignal, setregistersignal] =useState(0)
  const [temp, setTemp] = useState<StateDto[] | null>(null);
  const [AllCountries, setAllCountries] = useState<CountryDto[]>([]);
  const [loading, setLoading] = useState(false);
  const { handleSubmit, control, watch, getValues } = useForm();
  const [nextStep, setNextstep] = useState(<></>);
  const { config } = useAuthContext();
  const row = "w-full block md:flex justify-between items-center";
  const col = "w-full md:w-[30%]";
  const countryNames: string[] = [];

  for (const item of (countries || []) as CountryDto[]) {
    if (item?.name !== undefined && item?.name !== "") {
      countryNames.push(item.name);
    }
  }


  useEffect(() => {
    setAllCountries(countries as never);
  }, []);
  useEffect(() => {
    const selectedCountry = getValues("country");
    const filteredCountry = AllCountries.find(
      (country) => country.name === selectedCountry
    );
    setTemp(filteredCountry?.states || null);
    // eslint-disable-next-line
  }, [AllCountries, getValues, watch("country")]);
  useEffect(() => {
    const selectedCountry = getValues("nok_country");
    const filteredCountry = AllCountries.find(
      (country) => country.name === selectedCountry
    );
    setTemp(filteredCountry?.states || null);
    // eslint-disable-next-line
  }, [AllCountries, getValues, watch("nok_country")]);

  useEffect(() => {
    const names = (temp || []).map((state) => state.name).filter(Boolean);
    setStateNames(names);
  }, [temp]);
  const navigate = useNavigate();

  const onRegister = async (postdata_:any) => {
    // Register Patient


console.log(postdata_)
var patientID:any
 
    var patientData = {
      cardType: postdata_.cardType, // Required, specific values: 'Individual', 'Family', 'Company', 'Insurance'
      firstName: postdata_.firstName, // Required
      lastName: postdata_.lastName, // Required
      phoneNumber: postdata_.phoneNumber, // Required
      email: postdata_.email, // Optional
      gender: postdata_.gender, // Required, specific values: 'Male', 'Female'
      dob: postdata_.dob, // Optional
      country: postdata_.country, // Optional
      state: postdata_.state, // Optional
      address: postdata_.address, // Optional
      emergencyphone: postdata_.emergencyphone, // Optional
    };
    try {
      setLoading(true);
      let res = await RegisterPatient(patientData, config);
      setpostdata({ ...postdata_, patientID: res.data.id });
      patientID= res.data.id
      toast.success(res.message);
    } catch (error: any) {
      toast.error(error);
      throw new Error(error);

    } finally {
      setLoading(false);
    }

    switch (postdata.cardType) {
      case "Family":

     var familymemberscount :[] = postdata_.familymembers.concat([100])
    await Promise.all( familymemberscount.map( async(item, key)=>{

console.log(postdata_,"fami____")
      var dependantdata = {
        patientID:  patientID, // Required: ParentID
        name: `${postdata_[`fam_firstname${key}`]} ${postdata[`fam_lastname${key}`]}`, // Required: Full name of the patient
        gender: `${postdata_[`fam_gender${key}`]}`, // Required: Gender of the patient, must be 'Male' or 'Female'
        dob: `${postdata_[`fam_dob${key}`]} `, // Required: Date of birth in YYYY-MM-DD format
        phoneNumber: `${postdata_[`fam_phonenumber${key}`]}`, // Optional: Phone number of the patient (can be omitted or empty)
        relationship: `${postdata_[`fam_relationship${key}`]}`, // Required: Relationship to the patient
      };

       

      try {
        setLoading(true);
        let res = await onAddDependant(dependantdata, config);
        toast.success(res.message);
      } catch (error: any) {
        toast.error(error);
        throw new Error(error);
      } finally {
        setLoading(false);
      }

     }))
       

        break;

      case "Insurance":
        const insurancepostdata = {
          patientID:  patientID, // Required
          companyName: postdata_.companyName, // Required
          issuanceID: postdata_.issuanceID, // Required
          expiryDate: postdata_.expiryDate, // Required
          frontViewDoc: postdata_.frontViewDoc, // Required
          backViewDoc: postdata_.backViewDoc, // Optional
        };

        try {
          setLoading(true);

          let res = await onAddInsurance(insurancepostdata, config);
          toast.success(res.message);
        } catch (error: any) {
          toast.error(error);
          throw new Error(error);

        } finally {
          setLoading(false);
        }

        break;

      case "Individual":
        try {
          setLoading(true);
          const nokdata = {
            nokAddress: postdata_.nokAddress,
            nokState: postdata_.nokState,
            nokCountry: postdata_.nokCountry,
            nokRelationship: postdata_.nokRelationship,
            nokGender: postdata_.nokGender,
            nokPhoneNumber: postdata_.nokPhoneNumber,
            nokEmail: postdata_.nokEmail,
            nokLastname: postdata_.nokLastname,
            nokFirstname: postdata_.nokFirstname,
          };
          let res = await AddNOK( patientID, nokdata, config);
          toast.success(res.message);
        } catch (error: any) {
          toast.error(error);
          throw new Error(error);

        } finally {
          setLoading(false);
        }

        break;
    }
  };

  useEffect(() => {
    switch (postdata.cardType) {
      case "Family":
        setNextstep(
          <AddFamily
          onRegister={onRegister}
            setCurrentStepIndex={setCurrentStepIndex}
            postdata={postdata}
            setpostdata={setpostdata}
          />
        );
        break;

      case "Insurance":
        setNextstep(
          <AddInsurance
          onRegister={onRegister}
            setCurrentStepIndex={setCurrentStepIndex}
            postdata={postdata}
            setpostdata={setpostdata}
          />
        );
        break;

      case "Individual":
        setNextstep(
          <AddNok
          onRegister={onRegister}
            setCurrentStepIndex={setCurrentStepIndex}
            postdata={postdata}
            setpostdata={setpostdata}
          />
        );

        break;
    }
  }, [postdata]);
  const AddPatientsdetails = (props: any) => {
    const onSubmit = async (data: any) => {
      props.setpostdata({
        ...props.postdata,
        ...data,
      });
      props.nextStep();
      props.setCurrentStepIndex(1);
    };

    return (
      <div className="my-8 w-full px-8">
        {/* form Content */}
        <div className="bg-darkBg py-4 px-8 rounded-sm w-full">
          {/* Form Row One (FirstName, LastName, Email) */}
          <div className={row}>
            {/* col 1 */}
            <div className={col}>
              <InputField
                name="firstName"
                title="First Name"
                placeholder="First Name"
                control={control}
                rules={{
                  required: "First Name is required",
                  pattern: {
                    value: textReg,
                    message: "Text Only",
                  },
                }}
              />
            </div>
            {/* Col 2 */}
            <div className={col}>
              <InputField
                name="lastName"
                title="Last Name"
                placeholder="Last Name"
                control={control}
                rules={{
                  required: "Last Name is required",
                  pattern: {
                    value: textReg,
                    message: "Text Only",
                  },
                }}
              />
            </div>
            {/* Col 3 */}
            <div className={col}>
              <InputField
                name="email"
                title="Email"
                placeholder="Email"
                control={control}
              />
            </div>
          </div>
          {/* Form Row Two (Phone Number, Gender, DOB) */}
          <div className={row}>
            {/* Col 1*/}
            <div className={col}>
              <InputField
                name="phoneNumber"
                title="Phone Number"
                placeholder="Phone Number"
                control={control}
                // rules={{
                //   required: "Phone Number is required",
                // }}
              />
            </div>
            {/* Col 2 */}
            <div className={col}>
              <SelectField
                control={control}
                name="gender"
                rules={{ required: "Gender is required" }}
                placeholder="Select"
                title="Gender"
                options={["Male", "Female"]}
              />
            </div>
            {/* COl 3 DOB */}
            <div className={col}>
              <InputField
                control={control}
                name="dob"
                placeholder="Select a date"
                title="Date of Birth"
                type="date" // Set type to "date" for a date input
                rules={{ required: "Date of Birth is required" }}
              />
            </div>
          </div>

          {/* Form Row Three (Country and  States, Address) */}
          <div className={row}>
            {/* Col 1 */}
            <div className={col}>
              <SelectField
                control={control}
                name="country"
                placeholder="Select"
                title="country"
                options={countryNames}
                rules={{ required: "Country is required" }}
              />
            </div>
            {/* col 2 */}
            <div className={col}>
              <SelectField
                control={control}
                name="state"
                placeholder="Select"
                title="State"
                options={stateNames}
                rules={{ required: "State is required" }}
              />
            </div>
            {/* Col 3 */}
            <div className={col}>
              <InputField
                name="address"
                title="Address"
                placeholder="Address"
                control={control}
                rules={{ required: "Address is required" }}
              />
            </div>
          </div>
          {/* Form Row (Emegency Phone Number) */}
          <div className={"w-full block md:flex gap-14 items-center"}>
            {/* Col 1 */}
            <div className={col}>
              <InputField
                name="emergencyphone"
                title="Emergency Phone Contact"
                placeholder="Emergency Phone Contact"
                control={control}
                rules={{ required: "Emergency Contact is required" }}
              />
            </div>
            {/* Col 2 */}
            <div className={col}>
              <SelectField
                control={control}
                name="cardType"
                rules={{ required: "Card Type is required" }}
                placeholder="Select"
                title="Card Type"
                options={["Individual", "Family", "Insurance"]}
              />
            </div>
          </div>
        </div>

        <div className=" flex gap-4 justify-end">
          <Button width="200" text="Next" OnBtnClick={handleSubmit(onSubmit)} />
        </div>
      </div>
    );
  };

  
  return (
    <div className="w-full bg-lightBg p-5">
      <Spinner loading={loading} />
      {/* Header */}
      <div className="flex  justify-start items-start">
        {/* Back Button */}
        <button
          onClick={() => navigate(-1)}
          className="flex items-center justify-center bg-blue-500 py-1 pr-2 rounded-md"
        >
          <IoIosArrowBack color="white" />
          <p className="text-xs text-white">Back</p>
        </button>
        <div className="ml-4">
          <h1 className="text-lg font-medium text-black">
            Register New Patient
          </h1>
        </div>
      </div>
      <WizardStepIndicator
        stepsCount={3}
        currentStepIndex={currentStepIndexState}
        onClick={(index) => {
          setCurrentStepIndex(index);
        }}
      />

      <StepWizard>

      

        <AddPatientsdetails
          setCurrentStepIndex={setCurrentStepIndex}
          postdata={postdata}
          setpostdata={setpostdata}
        />

        {nextStep}

        <PatientAdded />
      </StepWizard>
    </div>
  );
};

export default AddPatient;
