interface ItemProps {
  title: string;
  content: string;
}
const ProfileItem: React.FC<ItemProps> = ({ title, content }) => {
  return (
    <div className="my-4">
      <p className="smallText text-white">{title}</p>
      <h1 className="bigText text-white">{content}</h1>
    </div>
  );
};

export default ProfileItem;
