import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../../context/AuthContext";
import { PrimaryLink } from "../../../components/links";
import { CiEdit } from "react-icons/ci";
import { profile } from "../../../constants/imports";
import { useParams } from "react-router-dom";
import { GetStaffByID } from "../../../utils/services/api/hospital/staff";
import { Spinner } from "../../../components/generic";
import { DeptDocumentListings } from "../../../components/hospitals/dept";
import { GoPlus } from "react-icons/go";
import { IoFilter, IoSearch } from "react-icons/io5";
import { Button } from "../../../components/form";
import AddDocModal from "../../../components/Modals/AddDocModal";
import { CiTimer } from "react-icons/ci";
import { CiGrid2H } from "react-icons/ci";
import { PiNoteThin } from "react-icons/pi";
import useStaffHook from "../../../hooks/useStaffHook";
import AddShiftModal from "../../../components/Modals/AddShiftModal";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { TableData } from "../../../components/layouts/table/Table";
import { GetShifts } from "../../../utils/services/api/hospital/services";

const StaffDetails = () => {
  const [active, setActive] = useState("Details");
  const data = ["Details", "Documents", "Schedules"];
  const [renderedTab, setrenderedTab] = useState<JSX.Element | undefined>(
    undefined
  );

  const weekdays = [
    "Monday",
    "Tuesdays",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const [showadddoc, setshowadddoc] = useState(false);
  const [staff, setStaff] = useState<any>(null);
  const [staffdoc, setstaffdoc] = useState([]);
  const [showaddshift,setshowaddshift] =useState(false)
  const [loading, setLoading] = useState(true);
const [Shiftitems, setShiftitems]=useState([])
  const tbHeaderDoc = ["Shift type", "Shift date",  "Shift end date", "Shift Start Time", "Shift End Time"];

  const { getStaffdocuments } = useStaffHook();
  const days = [
    {
      date: "date",
      isShift: false,
      staffID: 1,
      shiftDate: "2024-09-08",
      shiftType: "Morning",
      startTime: "08:00:00",
      endTime: "16:00:00",
      brkStartTime: "12:00:00",
      brkEndTime: "12:30:00",
    },
    {
      date: "date",
      isShift: false,
      staffID: 1,
      shiftDate: "2024-09-08",
      shiftType: "Morning",
      startTime: "08:00:00",
      endTime: "16:00:00",
      brkStartTime: "12:00:00",
      brkEndTime: "12:30:00",
    },

    {
      date: "date",
      isShift: false,
      staffID: 1,
      shiftDate: "2024-09-08",
      shiftType: "Morning",
      startTime: "08:00:00",
      endTime: "16:00:00",
      brkStartTime: "12:00:00",
      brkEndTime: "12:30:00",
    },

    {
      date: "date",
      isShift: true,
      staffID: 1,
      shiftDate: "2024-09-08",
      shiftType: "Morning",
      startTime: "08:00:00",
      endTime: "16:00:00",
      notes: "First shift of the day",
      brkStartTime: "12:00:00",
      brkEndTime: "12:30:00",
    },

    {
      date: "date",
      isShift: false,
      staffID: 1,
      shiftDate: "2024-09-08",
      shiftType: "Morning",
      startTime: "08:00:00",
      endTime: "16:00:00",
      brkStartTime: "12:00:00",
      brkEndTime: "12:30:00",
    },
    {
      date: "date",
      isShift: false,
      staffID: 1,
      shiftDate: "2024-09-08",
      shiftType: "Morning",
      startTime: "08:00:00",
      endTime: "16:00:00",
      brkStartTime: "12:00:00",
      brkEndTime: "12:30:00",
    },
    {
      date: "date",
      isShift: false,
      staffID: 1,
      shiftDate: "2024-09-08",
      shiftType: "Morning",
      startTime: "08:00:00",
      endTime: "16:00:00",
      brkStartTime: "12:00:00",
      brkEndTime: "12:30:00",
    },
  ];
  const { config } = useAuthContext();
  const params = useParams();
  const { id } = params;
  const onGetStaffDetails = async () => {
    setLoading(true);
    try {
      let res = await GetStaffByID(id?.toString() as string, config);
      setStaff(res?.data);
    } catch (error: any) {}
    setLoading(false);
  };


  const getHospitalShifts =async ()=>{

var res =await GetShifts(config)
setShiftitems(res.data)

  }
  useEffect(() => {
    const init = async () => {
      await onGetStaffDetails();
      await getHospitalShifts()
      // eslint-disable-next-line

      setstaffdoc(await getStaffdocuments(id));
    };

    init();
  }, []);

  const ProfileDeatils = () => (
    <div>
      {staff && (
        <div className="bg-primary p-6 rounded-lg my-4">
          {/* Container Header */}
          <div className="w-full flex justify-between items-start">
            {/* Logo Cont */}
            <div>
              <img
                src={staff?.photo}
                className="w-[100px] h-[100px] rounded-full"
                alt="Staff"
              />
            </div>
            {/* Edit Profile */}
            <div className="w-[140px]">
              <PrimaryLink
                outlined
                path={`/hospital/staff/edit/${id}`}
                color="white"
                text="Edit Staff"
                Icon={CiEdit}
                borderColor="white"
              />
            </div>
          </div>
          {/* Container Content */}
          <div>
            {/* Description */}
            <div className="w-full my-5 flex justify-between items-start">
              {/* Col 1 */}
              <div className="w-1/2">
                <ProfileItem title="First Name" content={staff?.firstName} />
                <ProfileItem title="Last Name" content={staff?.lastName} />
                <ProfileItem title="Email Addess" content={staff?.email} />
                <ProfileItem title="Phone Number" content={staff?.phone} />
              </div>
              {/* Col 2 */}
              <div className="w-1/2">
                <ProfileItem title="Address" content={staff?.address} />
                <ProfileItem
                  title="Department"
                  content={staff?.departmentName}
                />
                <ProfileItem title="Role" content={staff?.role} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
  const Documents = () => (
    <div>
      {showadddoc && (
        <AddDocModal type={"staff"} id={id}  show={setshowadddoc} />
      )}
      <div className="flex items-center justify-end">
        {/* Seacrh and Filter */}
        <div className="flex items-center space-x-4 mb-1">
          {/* Search Field */}
          <div className="relative w-[300px]">
            <IoSearch className="absolute top-2 left-2 text-xl text-zinc-400" />
            <input
              type={"text"}
              // value={value}
              placeholder={"Search"}
              // onChange={(text) => onChange(text)}
              className={`pl-8 block bg-white text-xs py-2 px-4 rounded-md w-full shadow-xs border border-gray-300}`}
            />
          </div>
          {/* Filter Btn */}
          <div className="w-[120px]">
            <Button
              text="Filter"
              outlined
              borderColor="gray-600"
              color="black"
              OnBtnClick={() => {}}
              Icon={IoFilter}
            />
          </div>
          {/* Button */}
          <Button
            text="Add document"
            borderColor="gray-600"
            color="white"
            OnBtnClick={() => {
              setshowadddoc(true);
            }}
            width="200"
            Icon={GoPlus}
          />
        </div>
      </div>
      <DeptDocumentListings items={staffdoc} />
    </div>
  );

  const DayItem = ({ item, key_ }: any) => (
    <div
      className={`${
        item.isShift ? "bg-[#fff8e5]" : "bg-transparent"
      } rounded cursor-pointer border-2 border-spacing-1 p-3 border-white w-[400px] h-[100px] flex flex-col justify-start items-start `}
    >
      <p className="text-xs">{weekdays[key_]}</p>
      {item.isShift ? (
        <>
          <p className="text-xs">
            <CiGrid2H className="inline mr-1" />
            {item.shiftType}
          </p>
          <p className="text-xs">
            {" "}
            <CiTimer className="inline mr-1 " />
            {item.startTime} - {item.endTime}
          </p>
          <p className="text-xs font-light truncate">
            {" "}
            <PiNoteThin className="inline mr-1" />
            {item.notes}
          </p>
        </>
      ) : (
        ""
      )}
    </div>
  );

  const Schedules = () => (
    <div className="bg-[#E3E7F5] p-6 text-gray-800 rounded-lg my-4">
   {showaddshift&&   <AddShiftModal  refresh ={getHospitalShifts} departmentId={staff.departmentId}  id={id} show={setshowaddshift}/>}
      <div className="w-full flex justify-between items-center">
        <p className="text-lg  font-bold text-gray-600">
          Weekly shifts calender
        </p>
        <Button
          text="Add Shift"
          borderColor="gray-600"
          color="white"
          OnBtnClick={() => {setshowaddshift(true)}}
          Icon={GoPlus}
          width="150"
        />
      </div>

      {/* <div className="flex gap-3 border border-white justify-between p-4 rounded">
        {days.map((i, key) => (
          <DayItem key_={key} item={i} />
        ))}
      </div> */}

<table className="w-full bg-white">
        <thead className="w-full bg-[#EAECF0]">
          <tr className="w-full">
            {tbHeaderDoc.map((item, index) => (
              <th
                key={index}
                scope="col"
                className="px-6 py-3 uppercase text-left text-xs font-semibold whitespace-nowrap"
              >
                {item}
              </th>
            ))}
           
          </tr>
        </thead>
        {/* Table content */}
        <tbody className="w-full bg-white">
          {Shiftitems?.map((item:any, index:any) => (
            <tr
              className="bg-white border-b pt-8  hover:bg-gray-50"
              key={index}
            >
               <TableData item={item?.shiftType} />
              <TableData item={item?.shiftDate} />
              <TableData item={item?.shiftEndDate} />
              <TableData item={item?.startTime} />
              <TableData item={item?.endTime} />
             
            </tr>
          ))}
        </tbody>
      </table>



    </div>
  );

  useEffect(() => {
    setrenderedTab(<ProfileDeatils />);
  }, []);

  return (
    <div className="p-5">
      {/* SPinner */}
      <Spinner loading={loading} />
      {/* Header */}
      <div>
        <h1 className="text-md font-medium text-black">Profile</h1>
        <p className="text-xs text-black mt-1">
          Manage and Update this personal details
        </p>
      </div>
      {/* Tab Container */}
      <div className="flex">
        <div className="bg-white flex items-center border border-gray-200 rounded-md my-5">
          {data.map((item, index) => (
            <p
              onClick={() => setActive(item)}
              className={` cursor-pointer
            ${index === 0 ? " rounded-tl-md rounded-bl-md" : ""}
            ${index === data.length - 1 ? "  rounded-tr-md rounded-br-md" : ""}
            ${
              active === item ? "bg-primary text-white" : " text-black"
            } text-xs font-medium py-2 px-3`}
              key={item}
            >
              {item}
            </p>
          ))}
        </div>
      </div>

      {/* Dynamic renderedTab */}

      {active == data[0] && <ProfileDeatils />}
      {active == data[1] && <Documents />}
      {active == data[2] && <Schedules />}
    </div>
  );
};

export default StaffDetails;

interface ItemProps {
  title: string;
  content: string;
}
const ProfileItem: React.FC<ItemProps> = ({ title, content }) => {
  return (
    <div className="my-4">
      <p className="smallText text-white">{title}</p>
      <h1 className="bigText text-white">{content}</h1>
    </div>
  );
};
