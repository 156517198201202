import axios from "axios";
import { baseURL } from "../../../URL";
import { CreateServiceDTO, UpdateServiceDTO } from "../../../typings/service";

const CreateService = async (postData: CreateServiceDTO, config: any) => {
    try {
        const response = await axios.post(`${baseURL}/subservices`, postData, config);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};
const UpdateService = async (id: number, postData: UpdateServiceDTO, config: any) => {
    try {
        const response = await axios.patch(`${baseURL}/subservices/${id}`, postData, config);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};
const GetCategoryServcices = async (id: number, config: any) => {
    try {
        const response = await axios.get(`${baseURL}/subservices/service/1`, config);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};
const DeleteServiceByID = async (id: number, config: any) => {
    try {
        const response = await axios.delete(`${baseURL}/services/${id}`, config);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};
export { CreateService, UpdateService, GetCategoryServcices, DeleteServiceByID }