import axios from "axios";
import { baseURL } from "../../../URL";

const CreateStaff = async (postData: any, config: any) => {
    try {
        const response = await axios.post(`${baseURL}/staffs`, postData, config);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};
const CreateShift = async (postData: any, config: any) => {
    try {
        const response = await axios.post(`${baseURL}/shifts`, postData, config);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};
const GetAllStaff = async (currentPage: any, config: any) => {
    try {
        const response = await axios.get(`${baseURL}/staffs?page=${currentPage}`, config);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};

const onGetStaffDocuments = async (staffID: any, currentPage: any, config: any) => {
    try {
        const response = await axios.get(`${baseURL}/staffs/document/${staffID}`, config);
        // const response = await axios.get(`${baseURL}/staffs?page=${currentPage}`, config);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};
const GetFilteredstaff = async (search: any, currentPage: any, config: any) => {
    try {
        const response = await axios.get(`${baseURL}/staffs/search/${search}`, config);
        // const response = await axios.get(`${baseURL}/staffs?page=${currentPage}`, config);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};
const GetStaffByID = async (id: any, config: any) => {
    try {
        const response = await axios.get(`${baseURL}/staffs/${id}`, config);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};
const UpdateStaffByID = async (updateData: any, staffID: any, config: any) => {
    try {
        const response = await axios.patch(`${baseURL}/staffs/${staffID}`, updateData, config);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};
const DeleteStaffByID = async (id: any, config: any) => {
    try {
        const response = await axios.delete(`${baseURL}/staffs/${id}`, config);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};

export { CreateStaff,CreateShift,  onGetStaffDocuments, GetAllStaff, GetFilteredstaff, GetStaffByID, DeleteStaffByID, UpdateStaffByID }