import React, { ReactElement } from "react";
import { Link } from "react-router-dom";

interface PrimaryLinkProps {
  width?: string;
  text: string;
  path: string;
  outlined?: boolean;
  Icon?: React.ComponentType<{ size: number; className: any }>;
  color?: string;
  bgColor?: string;
  borderColor?: string;
}

const PrimaryLink: React.FC<PrimaryLinkProps> = ({
  width,
  text,
  path,
  outlined,
  Icon,
  color,
  bgColor,
  borderColor,
}: PrimaryLinkProps): ReactElement => {
  return (
    <div>
      <Link
        className={`flex items-center justify-center text-xs ${
          outlined
            ? "bg-transparent"
            : bgColor
            ? `bg-${bgColor}`
            : "bg-primary text-white"
        }v
      ${color ? "text-" + color : "text-primary"}
      ${
        width ? "w-[" + width + "px]" : "w-full px-5"
      } my-5 py-2 rounded-md text-center border ${
          borderColor ? "border-" + borderColor : "border-primary"
        }`}
        to={path}
      >
        {Icon && (
          <Icon
            size={20}
            className={`${color ? "text-" + color : "text-white "} mr-2`}
          />
        )}
        {text}
      </Link>
    </div>
  );
};

export default PrimaryLink;
