import { useState } from "react";
 


export default ()=>{

    var [file, setfile]=useState<any>()

    const refreshfile=()=>{

        setfile('')
    }



    return {file, setfile, refreshfile}
}


 