import React, { useEffect, useState } from "react";
import { PrimaryLink } from "../../../components/links";
import { CiEdit } from "react-icons/ci";
import { useParams } from "react-router-dom";
import { Spinner } from "../../../components/generic";
import ProfileItem from "../../../components/generic/ProfileItem";
import usePatientDetails from "../../../query/usePatientDetails";
import { AppointmentContainer } from "../../../components/hospitals/patient";
import NOKDetails from "./NOKDetails";
import InsuranceDetails from "./InsuranceDetails";
import FamilyDetails from "./FamilyDetails";
import { DeptDocumentListings } from "../../../components/hospitals/dept";
import { GetPatientsRecords } from "../../../utils/services/api/hospital/appointment";
import { useAuthContext } from "../../../context/AuthContext";
import { InvoiceListings } from "../Invoice";
import InvoiceListingsPatient from "../Invoice/InvoiceListingsPatient";

const PatientDetails = () => {
  const [tab, setTab] = useState<string[]>([]);
  const [active, setActive] = useState("Appointments");
  const [cardTypeView, setcardTypeView] = useState(<></>);
  const params = useParams();
  const { config } = useAuthContext();
  const { id } = params;
  const [patientsDoc, setpatientsDoc] = useState<any[]>([]);
  const [isloading, setLoading] = useState(false);
  const { loading, patient } = usePatientDetails(id);
  useEffect(() => {
    var cardtypeTab = "";

    switch (patient?.cardType) {
      case "Family":
        cardtypeTab = "Family";
        setcardTypeView(<FamilyDetails patient={patient} />);
        break;

      case "Individual":
        cardtypeTab = "Next of Kin";
        setcardTypeView(<NOKDetails patient={patient} />);

        break;

      case "Insurance":
        cardtypeTab = "Insurance";
        setcardTypeView(<InsuranceDetails patient={patient} />);

        break;
    }

    setTab([
      "Appointments",
      "Invoice",
      "Documents",
      "Health Records",
      "Personal Details",
   
    ]);
  }, [patient]);

  const getpatientsRecords = async () => {
    setLoading(true);

    try {
      let emptarr: [] = [];

      var res = await GetPatientsRecords(id, config);
      res.data.map((gg: any) => {
        emptarr.push({
          documentURL: gg.documentURL,
          title: gg.diagnosis,
          createdAt: gg.createdAt,
        } as never);
      });

      setpatientsDoc(emptarr);
    } catch (e: unknown) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getpatientsRecords();
  }, []);
  return (
    <>
      {/* SPinner */}
      <Spinner loading={isloading || loading} />
      {patient && (
        <div className="p-5">
          {/* Header */}
          <div>
            <h1 className="text-md font-medium text-black">{`${patient?.firstName} ${patient?.lastName}`}</h1>
            <p className="text-xs text-black mt-1">
              Access and manage patient information and appointments.{" "}
            </p>
          </div>
          {/* Tab Container */}
          <div className="flex">
            <div className="bg-white flex items-center border border-gray-200 rounded-md my-5">
              {tab.map((item, index) => (
                <p
                  onClick={() => setActive(item)}
                  className={` cursor-pointer
            ${index === 0 ? " rounded-tl-md rounded-bl-md" : ""}
            ${index === tab.length - 1 ? "  rounded-tr-md rounded-br-md" : ""}
            ${ active === item ? "bg-primary text-white" : " text-black"  } text-xs font-medium py-2 px-3`}
                  key={item}
                >
                  {item}
                </p>
              ))}
            </div>
          </div>
          {/* Appointemnt Container */}
          {active === "Appointments" && <AppointmentContainer />}
          {/* Documents Container */}
          {active === "Documents" && (
            <DeptDocumentListings items={patientsDoc} />
          )}
          {active === "Invoice" && (
            <InvoiceListingsPatient  />
          )}
          {/* Profile COntainer */}
          {active === "Personal Details" && (
            <>
              {/* Personal Details */}
              <div className="bg-primary p-6 rounded-lg my-4">
                {/* Container Header */}
                <div className="w-full flex justify-between items-start">
                  {/* Logo Cont */}
                  <div>
                    <h1 className="text-white text-lg font-medium">
                      Personal Details
                    </h1>
                  </div>
                  {/* Edit Profile */}
                  <div className="w-[100px]">
                    <PrimaryLink
                      outlined
                      path={`/hospital/patient/edit/${id}`}
                      color="white"
                      text="Edit"
                      Icon={CiEdit}
                      borderColor="white"
                    />
                  </div>
                </div>
                {/* Container Content */}
                <div>
                  {/* Description */}
                  <div className="w-full flex justify-between items-start">
                    {/* Col 1 */}
                    <div className="w-1/3">
                      <ProfileItem
                        title="First Name"
                        content={patient?.firstName}
                      />
                      <ProfileItem
                        title="Last Name"
                        content={patient?.lastName}
                      />
                      <ProfileItem
                        title="Email Addess"
                        content={patient?.email}
                      />
                      <ProfileItem
                        title="Phone Number"
                        content={patient?.phoneNumber}
                      />
                    </div>
                    {/* Col 2 */}
                    <div className="w-1/3">
                      <ProfileItem
                        title="Country"
                        content={patient?.country ? patient?.country : "-"}
                      />
                      <ProfileItem
                        title="State"
                        content={patient?.state ? patient?.state : "-"}
                      />
                      <ProfileItem
                        title="Address"
                        content={patient?.address ? patient?.address : "-"}
                      />
                      <ProfileItem
                        title="Gender"
                        content={patient?.gender ? patient?.gender : "-"}
                      />
                    </div>
                    {/* Col 3 */}
                    <div className="w-1/3">
                      <ProfileItem
                        title="Card Type"
                        content={patient?.cardType ? patient?.cardType : "-"}
                      />
                    </div>
                  </div>
                </div>
              </div>
    {cardTypeView}
            </>

            
          )}
 
        </div>
      )}
    </>
  );
};
export default PatientDetails;
