import React, { useEffect, useState } from "react";
import { PrimaryLink } from "../../../components/links";
import { CiEdit } from "react-icons/ci";
import { DeptDocumentListings } from "../../../components/hospitals/dept";
import { Button } from "../../../components/form";
import { IoFilter, IoSearch } from "react-icons/io5";
import { GoPlus } from "react-icons/go";
import { useAuthContext } from "../../../context/AuthContext";
import { useParams } from "react-router-dom";
import { GetDeptByID } from "../../../utils/services/api/hospital/dept";
import { Spinner } from "../../../components/generic";
import { StaffTable } from "../../../components/layouts/table";
import AddDocModal from "../../../components/Modals/AddDocModal";
import useDeptHook from "../../../hooks/useDeptHook";

const DeptDetails = () => {
  const [dept, setDept] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [active, setActive] = useState("Details");
  const [showadddoc, setshowadddoc] = useState(false);
  const { getDeptDocuments } = useDeptHook();
  const [deptdoc, setdeptdoc] = useState();
  const { config } = useAuthContext();
  const params = useParams();
  const { id } = params;
  const data = ["Details", "Staff", "Documents"];
  const onGetDeptDetails = async () => {
    setLoading(true);
    try {
      let res = await GetDeptByID(id?.toString() as string, config);
      setDept(res?.data);
    } catch (error: any) {}
    setLoading(false);
  };

  useEffect(() => {
    const init = async () => {
      await onGetDeptDetails();
      setLoading(true);
      var resp = await getDeptDocuments(id);
      setdeptdoc(resp);
      console.log(resp, "docs");
      setLoading(false);
    };

    init();
    // eslint-disable-next-line
  }, []);
  return (
    <div className="p-5">
      {dept && (
        <>
          {/* SPinner */}
          <Spinner loading={loading} />
          {/* Header */}
          <div>
            <h1 className="text-md font-medium text-black">
              {`${dept?.departmentName} Department`}
            </h1>
            <p className="text-xs text-black mt-1">
              make change to this hospital department{" "}
            </p>
          </div>
          {/* Tab Container */}
          <div className="flex">
            <div className="bg-white flex items-center border border-gray-200 rounded-md my-5">
              {data.map((item, index) => (
                <p
                  onClick={() => setActive(item)}
                  className={` cursor-pointer
          ${index === 0 ? " rounded-tl-md rounded-bl-md" : ""}
          ${index === data.length - 1 ? "  rounded-tr-md rounded-br-md" : ""}
          ${
            active === item ? "bg-primary text-white" : " text-black"
          } text-xs font-medium py-2 px-3`}
                  key={item}
                >
                  {item}
                </p>
              ))}
            </div>
          </div>
          {/* Profile COntainer */}
          {active === "Details" && (
            <div className="w-full flex items-center justify-center">
              <div className=" w-[80%] bg-primary p-6 rounded-lg my-4">
                <div className="w-full flex justify-between items-start">
                  <div>
                    <div className="flex items-center gap-20">
                      <ProfileItem
                        title="Name of Department"
                        content={dept?.departmentName}
                      />
                      <ProfileItem
                        title="Head of Department"
                        content={
                          dept?.headDepartmentStaff
                            ? dept.headDepartmentStaff
                            : "-"
                        }
                      />
                    </div>
                    <div className="flex items-center gap-20">
                      <ProfileItem
                        title="Working Days"
                        content={dept?.workDay}
                      />
                      <ProfileItem
                        title="Working Hours"
                        content={`${dept?.startTime} - ${dept?.endTime}`}
                      />
                    </div>
                  </div>
                  {/* Edit Profile */}
                  <div className="w-[180px]">
                    <PrimaryLink
                      outlined
                      path={`/hospital/dept/edit/${id}`}
                      color="white"
                      text="Edit Department"
                      Icon={CiEdit}
                      borderColor="white"
                    />
                  </div>
                </div>
                {/* Dept Desc */}
                <ProfileItem title="Description" content={dept?.description} />
              </div>
            </div>
          )}

          {/* Staff Container */}
          {active === "Staff" && (
            <>
              <div className="flex items-center justify-end">
                {/* Seacrh and Filter */}
                <div className="flex items-center space-x-4 mb-1">
                  {/* Search Field */}
                  <div className="relative w-[300px]">
                    <IoSearch className="absolute top-2 left-2 text-xl text-zinc-400" />
                    <input
                      type={"text"}
                      // value={value}
                      placeholder={"Search"}
                      // onChange={(text) => onChange(text)}
                      className={`pl-8 block bg-white text-xs py-2 px-4 rounded-md w-full shadow-xs border border-gray-300}`}
                    />
                  </div>
                  {/* Filter Btn */}
                  <div className="w-[120px]">
                    <Button
                      text="Filter"
                      outlined
                      borderColor="gray-600"
                      color="black"
                      OnBtnClick={() => {}}
                      Icon={IoFilter}
                    />
                  </div>
                  {/* Create Staff */}
                  <PrimaryLink
                    Icon={GoPlus}
                    text="Create Staff"
                    path={`/hospital/dept/${id}/add-staff`}
                    width="150"
                    color="white"
                  />
                </div>
              </div>
              <StaffTable IsSame />
            </>
          )}

          {/* Doument COntaier */}
          {active === "Documents" && (
            <>
              {showadddoc && (
                <AddDocModal type={"dept"} id={id} show={setshowadddoc} />
              )}
              <div className="flex items-center justify-end">
                {/* Seacrh and Filter */}
                <div className="flex items-center space-x-4 mb-1">
                  {/* Search Field */}
                  <div className="relative w-[300px]">
                    <IoSearch className="absolute top-2 left-2 text-xl text-zinc-400" />
                    <input
                      type={"text"}
                      // value={value}
                      placeholder={"Search"}
                      // onChange={(text) => onChange(text)}
                      className={`pl-8 block bg-white text-xs py-2 px-4 rounded-md w-full shadow-xs border border-gray-300}`}
                    />
                  </div>
                  {/* Filter Btn */}
                  <div className="w-[120px]">
                    <Button
                      text="Filter"
                      outlined
                      borderColor="gray-600"
                      color="black"
                      OnBtnClick={() => {}}
                      Icon={IoFilter}
                    />
                  </div>
                  {/* Button */}
                  <Button
                    text="Add document"
                    borderColor="gray-600"
                    color="white"
                    OnBtnClick={() => {
                      setshowadddoc(true);
                    }}
                    width="200"
                    Icon={GoPlus}
                  />
                </div>
              </div>
              <DeptDocumentListings items={deptdoc} />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default DeptDetails;

interface ItemProps {
  title: string;
  content: string;
}
const ProfileItem: React.FC<ItemProps> = ({ title, content }) => {
  return (
    <div className="my-4">
      <p className="smallText text-white">{title}</p>
      <h1 className="bigText text-white mt-2">{content}</h1>
    </div>
  );
};
