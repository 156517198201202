import React, { useEffect, useState } from "react";
import { GoPlus } from "react-icons/go";
import { addrecord, empty } from "../../../constants/imports";
import { IoSearch } from "react-icons/io5";
import useStaffHook from "../../../hooks/useStaffHook";
import { StaffTable } from "../../layouts/table";
import { PulseLoader } from "react-spinners";
import { Button, InputField, SelectField } from "../../form";
import { FaRegTimesCircle } from "react-icons/fa";
import { textReg } from "../../../utils/regex";
import { useForm } from "react-hook-form";
import { MultiSelect, Spinner } from "../../generic";
import { toast } from "react-toastify";
import { useAuthContext } from "../../../context/AuthContext";
import { AddPermission } from "../../../utils/services/api/hospital/services";
import { RiHealthBookLine } from "react-icons/ri";
import AppointmentTable from "../../layouts/table/AppointmentTable";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { FaPlus } from "react-icons/fa6";
import {
  CompleteAppointment,
  GetAllvitals,
  GetAppointmentfulldetails,
  GetAppointmentRecords,
  GetAppointmentVitals,
} from "../../../utils/services/api/hospital/appointment";
import { MdCheck } from "react-icons/md";
import usePatientHook from "../../../hooks/usePatientHook";

export default () => {
  const [createModal, setCreateModal] = useState(false);
  const roles = [];
  const navigate = useNavigate();
  const [appointmentdetails, setappointmentdetails] = useState<any>({});
  const { config } = useAuthContext();
  const { id, appoinmentid } = useParams();
  const [isloading, setLoading] = useState(false);
  const { loading, setSearch } = useStaffHook();
  const { patients } = usePatientHook();
  const [recvitals, setrecvitals] = useState<any>([]);
  const [records, setrecords] = useState<any[]>([]);
  const [allvitals, setallvitals] = useState<any>([]);

  const pat: any = patients;

  const getappointmentdetails = async () => {
    setLoading(true);

    try {
      var res = await GetAppointmentfulldetails(appoinmentid, config);
      setappointmentdetails(res.data);
    } catch (e: any) {
      //console.log(e)
      // toast.error(e)
    } finally {
      setLoading(false);
    }
  };

  const getrecords = async () => {
    try {
      var res = await GetAppointmentRecords(appoinmentid, config);
      setrecords(res.data);
    } catch (e: any) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };


  
  const getAllVitals = async () => {
    var res = await GetAllvitals(config);
    setallvitals(res.data);
  };


  useEffect(()=>{
    getAllVitals()

  },[])
  const getRecordVitals = async () => {
    var res = await GetAppointmentVitals(appoinmentid, config);
    setrecvitals(res.data);
  };
  const markcomplete = async () => {
    setLoading(true);
    var res = await CompleteAppointment(
      appoinmentid,
      { status: "complete" },
      config
    );
    navigate(-1);
    
    setLoading(false); 
  };
  useEffect(() => {
    getappointmentdetails();
    getRecordVitals();
    getrecords();
  }, []);
  return (
    <div className="w-full bg-white p-5">
      {/* Header */}

      <div className="">
        <div className="flex">
          <button
            onClick={() => navigate(-1)}
            className="flex items-center justify-center  bg-blue-500 py-1 pr-2 rounded-md text-xs text-white"
          >
            <IoIosArrowBack className="inline" color="white" />
            Back
          </button>{" "}
          <h1 className="text-md ml-2 font-medium text-black">
            {pat.filter((kj: any) => kj.id == id)[0]?.firstName}{" "}
            {pat.filter((kj: any) => kj.id == id)[0]?.lastName}
          </h1>
        </div>
        <p className="text-xs text-black mt-1">
          Access and manage patient information and appointments.{" "}
        </p>
      </div>
      {/* Seacrh and Filter */}
      <div className="flex justify-between my-3"></div>
      {/* COntent */}

      <div>
        {loading || isloading ? (
          <div className=" m-12  justify-items-center justify-center">
            <PulseLoader
              className=" m-12 justify-center"
              color={"#350080"}
              loading={loading}
              size={14}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ) : (
          <>
            <div>
              <div className="flex justify-between items-center">
                <div>
                  <p className="text-md font-semibold text-black mt-1">
                    Appointment Records
                  </p>
                  <p className="text-xs text-gray-500 mt-1">
                    Reason for appointment
                  </p>
                  <p className="text-sm text-black mt-1">
                    {appointmentdetails?.appointment?.reason}
                  </p>
                </div>
                <button
                  onClick={() => markcomplete()}
                  className="bg-[#2F80ED] rounded text-sm py-2 px-3 text-white"
                >
                  {" "}
                  <MdCheck className="inline" /> Mark appointment as completed
                </button>
              </div>

              <div className=" mt-4 p-3 bg-[#FAFAFA] rounded grid grid-cols-4 gap-1 px-10">
                <div className="text-sm  p-3 border border-gray-400 rounded my-2">

                  <p className=" font-semibold text-gray-600 uppercase">
                    Vitals
                  </p><hr/>
                  {recvitals.map((vititem: any) => (
                    <div>
                      <p>{vititem.vital.name}</p>
                      <p className="text-green-500">{vititem.vitalResults} {allvitals.filter((kh:any)=>kh.id==vititem.vitalID)[0]?.unit} </p>
                    </div>
                  ))}
                </div>

                {records.map((record: any) => (
                  <RecordItem appointment={record.appointment} item={record} />
                ))}
                <img
                  onClick={() => {
                    navigate(
                      `/hospital/patients/${id}/appointments/${appoinmentid}/createrecord`
                    );
                  }}
                  className="scale-75"
                  src={addrecord}
                />
              </div>
            </div>
          </>
        )}
      </div>
      {/* Add Modal */}
      {createModal && <Modal setCreateModal={setCreateModal} />}
    </div>
  );
};

const RecordItem = ({ item, appointment }: any) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { staff } = useStaffHook();
  const stf: any = staff;
  return (
    <div
      onClick={() => {
        navigate(
          `/hospital/patients/${id}/appointments/${appointment.id}/updaterecord/${item.id}`
        );
      }}
      className=" text-white bg-[#E8EDFB] p-3  flex  flex-col justify-between items-start  w-[270px] h-[270px] rounded-[10px] "
    >
      <div className=" my-1">
        <p className="bg-[#FFA24C] p-1 text-xs mx-1 w-20 rounded text-white inline">
          Diagnosis
        </p>

        <p className="text-xs text-gray-400 truncate inline">
          {item.diagnosis}
        </p>
      </div>

      <div className=" my-1">
        <p className="bg-[#1849D6] p-1 text-xs mx-1 w-20 text-white inline rounded">
          Treatment
        </p>

        <p className="text-xs text-gray-400 truncate inline  ">
          {item.treatment}
        </p>
      </div>

      <p className="text-xs  font-light text-gray-500 mt-1">
        Presiding Doctor/Nurse{" "}
      </p>
      <p className="text-sm text-black font-semibold mt-1">
        {stf.filter((ik: any) => ik.id == appointment.doctorID)[0]?.firstName}{" "}
        {stf.filter((ik: any) => ik.id == appointment.doctorID)[0]?.lastName}
      </p>

      <p className="text-xs  font-light text-gray-500 mt-1">
        Date of appointment{" "}
      </p>

      <p className="text-sm text-black font-semibold mt-1">
        {" "}
        {`${appointment.date}${appointment?.time}`}{" "}
      </p>
    </div>
  );
};
const Modal = ({ setCreateModal }: any) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const { config } = useAuthContext();
  const { control, handleSubmit } = useForm();

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      data.access = selectedOptions.toString();
      await AddPermission(data, config);
      toast.success("Permission Added Successfully!");
      setCreateModal(false);
    } catch (error: any) {
      toast.error(error);
    }
    setLoading(false);
  };
  return (
    <div className="h-full w-full fixed top-0 left-0 pt-10">
      <Spinner loading={loading} />
      <div className="w-full h-full flex justify-center items-start">
        <div className="w-[30%] bg-white shadow-md rounded-md px-4 pt-4">
          {/* Header */}
          <div className="w-full flex justify-between items-center">
            <h2 className="text-sm font-medium text-black">
              Create Appointment
            </h2>
            <FaRegTimesCircle
              className="cursor-pointer text-gray-300"
              onClick={() => setCreateModal(false)}
            />
          </div>
          {/* Form Content */}
          <div className={""}>
            <InputField
              name="description"
              title="Reason for Appointment"
              placeholder="Reason"
              control={control}
              isTextArea
            />
            <SelectField
              control={control}
              name="headDepartmentStaff"
              placeholder="Select"
              title="Assign Doctor/Nurse"
              options={[""]}
            />
            <InputField
              control={control}
              name="date"
              placeholder="Date"
              title="Date"
              type="date"
            />
            <InputField
              control={control}
              name="time"
              placeholder="Time"
              title="Time"
              type="time"
            />
          </div>
          {/* Button */}
          <div className="w-full flex items-center justify-end gap-5">
            <Button
              text="Cancel"
              width="100"
              OnBtnClick={() => setCreateModal(false)}
              color="black"
              outlined
              borderColor="gray-300"
            />
            <Button
              text="Create Appointment"
              width="180"
              OnBtnClick={handleSubmit(onSubmit)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
