import React, { useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import {
  Button,
  DaysSelector,
  InputField,
  SelectField,
} from "../../../components/form";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuthContext } from "../../../context/AuthContext";
import { Spinner } from "../../../components/generic";
import { CreateDept } from "../../../utils/services/api/hospital/dept";
import useStaffHook from "../../../hooks/useStaffHook";
const AddDept = () => {
  const [selectedDays, setSelectedDays] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm();
  const { config } = useAuthContext();
  const row = "w-full block md:flex justify-between items-center";
  const col = "w-full md:w-[45%]";
const {staff}=useStaffHook()
  const handleDaysChange = (days: any) => {
    setSelectedDays(days);
  };


  const staffs_: any = staff.map((item: any) => {
    return {
      value: item.id,
      label: `${item.firstName} ${item.lastName} `
    };
  });
  const onSubmit = async (data: any) => {
    data.workDay = selectedDays.toLocaleString();
    setLoading(true);
    try {
      await CreateDept(data, config);
      toast.success("Department Added Successfully!");
      navigate("/dept");
    } catch (error: any) {
      toast.error(error);
    }
    setLoading(false);
  };

  return (
    <div className="w-full bg-lightBg p-5">
      <Spinner loading={loading} />
      {/* Header */}
      <div className="flex  justify-start items-start">
        {/* Back Button */}
        <button
          onClick={() => navigate(-1)}
          className="flex items-center justify-center bg-blue-500 py-1 pr-2 rounded-md"
        >
          <IoIosArrowBack color="white" />
          <p className="text-xs text-white">Back</p>
        </button>
        <div className="ml-4">
          <h1 className="text-lg font-medium text-black">Create Department</h1>
        </div>
      </div>
      <div className=" w-full flex justify-center mt-10 items-start">
        <div className="md:w-[70%] md:flex items-start  md:px-12 px-8 w-full">
          {/* Change Profile */}
          {/* form Content */}
          <div className="md:w-[80%]">
            <div className=" bg-darkBg py-5 md:px-8 px-5 rounded-sm mb-5">
              {/* Form Row One (Hopital Name and Contact Person Name) */}
              <div className={row}>
                {/* col 1 */}
                <div className={col}>
                  <InputField
                    name="departmentName"
                    title="Department Name"
                    placeholder="Department Name"
                    control={control}
                    rules={{
                      required: "Department Name is required",
                    }}
                  />
                </div>
                {/* Col 2 */}
                <div className={col}>
                  <SelectField
                    control={control}
                    name="headDepartmentStaffId"
                    placeholder="Select"
                    title="Head of Deparment"
                    options={staffs_}
                 //   rules={{   required: "Description is required",  }}
                    isValue
                  />
                </div>
              </div>
              {/* Form Row Five Address */}
              <div>
                <InputField
                  name="description"
                  title="Description"
                  placeholder="Description"
                  control={control}
                  rules={{
                    required: "Description is required",
                  }}
                  isTextArea
                />
              </div>
              {/* ROw Six Work  WOrk Days*/}
              {/* Days Selector */}
              <div className={row}>
                <DaysSelector
                  selectedDays={selectedDays}
                  onChange={handleDaysChange}
                  title={"Work Days"}
                />
              </div>
              {/* Row 7 Start and End Time */}
              <div className={row}>
                {/* STart Time */}
                <div className={col}>
                  <InputField
                    control={control}
                    name="startTime"
                    placeholder="Start Time"
                    title="Start Time"
                    type="time"
                  />
                </div>
                {/* End Time */}
                <div className={col}>
                  <InputField
                    control={control}
                    name="endTime"
                    placeholder="End Time"
                    title="End Time"
                    type="time"
                  />
                </div>
              </div>
            </div>
            {/* Button */}
            <div className="md:flex block justify-end items-center mt-3 mb-10">
              <div className="md:w-[180px] w-full">
                <Button
                  text="Create Department"
                  OnBtnClick={handleSubmit(onSubmit)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDept;
