import { CiEdit } from "react-icons/ci"
import { PrimaryLink } from "../../../components/links"
import ProfileItem from "../../../components/generic/ProfileItem"

export default ({patient}:any)=>{


    return (  
        <div className="bg-black p-6 rounded-lg my-5">
          {/* Container Header */}
          <div className="w-full flex justify-between items-start">
            {/* Logo Cont */}

            
            <div>
              <h1 className="text-white text-lg font-medium">
                Next of Kin Details
              </h1>
            </div>
            {/* Edit Profile */}
            <div className="w-[100px]">
              <PrimaryLink
                outlined
                path={`/hospital/nok/edit/${patient.id}`}
                color="white"
                text="Edit"
                Icon={CiEdit}
                borderColor="white"
              />
            </div>
          </div>
          {/* Container Content */}
          <div>
            {/* Description */}
            <div className="w-full flex justify-between items-start">
              {/* Col 1 */}
              <div className="w-1/3">
                <ProfileItem
                  title="First Name"
                  content={patient?.nokFirstname}
                />
                <ProfileItem
                  title="Last Name"
                  content={patient?.nokLastname}
                />
                <ProfileItem
                  title="Email Addess"
                  content={patient?.nokEmail}
                />
                <ProfileItem
                  title="Phone Number"
                  content={patient?.nokPhoneNumber}
                />
              </div>
              {/* Col 2 */}
              <div className="w-1/3">
                <ProfileItem
                  title="Country"
                  content={
                    patient?.nokCountry ? patient?.nokCountry : "-"
                  }
                />
                <ProfileItem
                  title="State"
                  content={patient?.nokState ? patient?.nokState : "-"}
                />
                <ProfileItem
                  title="Address"
                  content={
                    patient?.nokAddress ? patient?.nokAddress : "-"
                  }
                />
                <ProfileItem
                  title="Gender"
                  content={
                    patient?.nokGender ? patient?.nokGender : "-"
                  }
                />
              </div>
              {/* Col 3 */}
              <div className="w-1/3">
                <ProfileItem
                  title="Relationship"
                  content={
                    patient?.nokRelationship
                      ? patient?.nokRelationship
                      : "-"
                  }
                />
              </div>
            </div>
          </div>
        </div>)
}