import { useState, useEffect } from "react";
import { useAuthContext } from "../context/AuthContext";
import {
  DeleteStaffByID,
  GetAllStaff,
  GetFilteredstaff,
} from "../utils/services/api/hospital/staff";
import { toast } from "react-toastify";
import { onGetstaffDocuments } from "../utils/services/api/hospital/services";

const useStaffHook = () => {
  const [staff, setStaff] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [isDeleted, setIsDeleted] = useState(false);
  const { config, authUser } = useAuthContext();
  const GetAllDept = async () => {
    try {
      setLoading(true);
      let temp;
      let tempCurrent = currentPage === undefined ? 1 : currentPage;
      if (search === "") {
        temp = await GetAllStaff(tempCurrent, config);
      } else {
        temp = await GetFilteredstaff(search, tempCurrent, config);
      }
      setStaff(temp?.data);
      // setTotalPages(temp?.pagination?.totalPages);
      // setTotal(temp?.pagination?.totalRecords);
      // setCurrentPage(temp.pagination?.currentPage);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      console.log("error", error);
      setStaff([]);
    }
  };
  useEffect(() => {
    GetAllDept();
    // eslint-disable-next-line
  }, [authUser, currentPage, search, isDeleted]);
  const onDeleteStaff = async (id: any) => {
    setLoading(true);
    try {
      await DeleteStaffByID(id, config);
      toast.success("Staff Deleted Successfully");
      setIsDeleted(true);
    } catch (error: any) {
      toast.error(error);
    }
    setLoading(false);
  };

const getStaffdocuments = async(id:any)=>{

var res = await onGetstaffDocuments(id,config)

console.log(res.data)
return res.data
}



  return {
    staff,
    total,
    currentPage,
    setCurrentPage,
    totalPages,
    getStaffdocuments,
    loading,
    setSearch,
    onDeleteStaff,
  };
};
export default useStaffHook;
