import { Fragment } from "react";
import { IoSettings, IoSettingsOutline } from "react-icons/io5";
import { turgl } from "../../constants/imports";
import { useAuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { Menu, Popover, Transition } from "@headlessui/react";
import { Icon } from "@iconify/react";
import { HiOutlineBell } from "react-icons/hi";
import { Link } from "react-router-dom";
import classNames from "classnames";

const Header = () => {
  const navigate = useNavigate();
  const { authUser, setAuthUser, setToken } = useAuthContext();
  const logout = () => {
    localStorage.clear();
    setAuthUser(null);
    setToken(null);
    navigate("/hospital/login");
  };

  return (
    <div className="w-full bg-primary py-3 px-10 shadow-2xl drop-shadow-sm flex justify-between items-center">
      <img src={turgl} className="h-[25px]" alt="Turgl" />
      <div className="flex gap-8 items-center">
        <Link to={"/hospital/settings"}>
          <IoSettings size={30} color="white" />
        </Link>
        <Popover className="relative">
          <Popover.Button>
            <HiOutlineBell fontSize={30} color="white" />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute right-0 z-10 mt-2.5 transform w-80">
              <div className="bg-white rounded-sm shadow-md ring-1 ring-black ring-opacity-5 px-2 py-2.5">
                <strong className="text-gray-700 font-medium">
                  Notifications
                </strong>
                <div className="mt-2 py-1 text-sm">
                  This is notification panel.
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
        <Menu as="div" className="relative">
          <div>
            <Menu.Button className="ml-2  flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-neutral-400">
              <div className="flex flex-items items-center gap-4">
                <div className="h-10 w-10 rounded-full bg-white flex items-center justify-center">
                  <p className="text-primary font-black text-xl">
                    {`${authUser?.Account?.firstName?.slice(
                      0,
                      1
                    )}${authUser?.Account?.lastName?.slice(0, 1)}`}
                  </p>
                </div>
              </div>
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-right  block z-10 absolute right-0 mt-2 w-32 rounded-sm shadow-md py-1 px-2 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <Menu.Item>
                <button
                  className="rounded-sm py-1 text-black font-medium text-xs cursor-pointer focus:bg-gray-200"
                  onClick={() => navigate("/hospital/settings")}
                >
                  Settings
                </button>
              </Menu.Item>
              <br />
              <Menu.Item>
                <button
                  className="rounded-sm px-2 py-1 text-black font-medium bg-yellow-400 text-xs cursor-pointer focus:bg-gray-200"
                  onClick={logout}
                >
                  Logout
                </button>
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
};

export default Header;
