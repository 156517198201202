import React, { useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { Button, InputField, SelectField } from "../../../components/form";
import { useForm } from "react-hook-form";
import { emailReg, textReg } from "../../../utils/regex";
import countries from "../../../constants/data/countries.json";
import { user } from "../../../constants/imports";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../context/AuthContext";
import { Spinner } from "../../../components/generic";
import { toast } from "react-toastify";
import { UpdateHostpitalRecords } from "../../../utils/services/api/hospital/auth";
interface StateDto {
  code: string;
  name: string;
  subdivision: null;
}

interface CountryDto {
  code2: string;
  code3: string;
  name: string;
  capital: string;
  region: string;
  subregion: string;
  states: StateDto[];
}

const EditProfile = () => {
  const [loading, setLoading] = useState(false);
  const [stateNames, setStateNames] = useState<string[]>([]);
  const [temp, setTemp] = useState<StateDto[] | null>(null);
  const [AllCountries, setAllCountries] = useState<CountryDto[]>([]);
  const navigate = useNavigate();
  const { control, watch, getValues, handleSubmit, setValue } = useForm();
  const row = "w-full block md:flex justify-between items-center";
  const col = "w-full md:w-[45%]";
  const countryNames: string[] = [];
  const { authUser, setAuthUser, config } = useAuthContext();
  for (const item of (countries || []) as CountryDto[]) {
    if (item?.name !== undefined && item?.name !== "") {
      countryNames.push(item.name);
    }
  }
  useEffect(() => {
    setAllCountries(countries as never);
    setValue("hospitalName", authUser?.hospitalName);
    setValue("hospitalCountry", authUser?.hospitalCountry);
    setValue("hospitalState", authUser?.hospitalState);
    setValue("hospitalType", authUser?.hospitalType);
    setValue("contactName", authUser?.contactName);
    setValue("contactEmail", authUser?.contactEmail);
    setValue("contactPhone", authUser?.contactPhone);
    setValue("hospitalAddress", authUser?.hospitalAddress);
    setValue("hospitalArea", authUser?.hospitalArea);
  }, [authUser, setValue]);
  useEffect(() => {
    const selectedCountry = getValues("hospitalCountry");
    const filteredCountry = AllCountries.find(
      (country) => country.name === selectedCountry
    );
    setTemp(filteredCountry?.states || null);
    // eslint-disable-next-line
  }, [AllCountries, getValues, watch("hospitalCountry")]);

  useEffect(() => {
    const names = (temp || []).map((state) => state.name).filter(Boolean);
    setStateNames(names);
  }, [temp]);
  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      let res = await UpdateHostpitalRecords(data, authUser?.id, config);
      localStorage.setItem(`hosData`, JSON.stringify(res?.data));
      setAuthUser(res?.data);
      navigate(-1);
      toast.success("Hostpital Details updated Successfully");
    } catch (error: any) {
      toast.error(error);
    }
    setLoading(false);
  };
  return (
    <div className="w-full bg-lightBg p-5">
      <Spinner loading={loading} />
      {/* Header */}
      <div className="flex  justify-start items-start">
        {/* Back Button */}
        <button
          onClick={() => navigate(-1)}
          className="flex items-center justify-center bg-blue-500 py-1 pr-2 rounded-md"
        >
          <IoIosArrowBack color="white" />
          <p className="text-xs text-white">Back</p>
        </button>
        <div className="ml-4">
          <h1 className="text-lg font-medium text-black">
            Edit Hospital Profile
          </h1>
          <p className="text-xs text-gray-600 mt-1">
            View and edit hospital information and details{" "}
          </p>
        </div>
      </div>
      <div className=" w-full flex justify-center mt-10 items-start">
        <div className="md:w-[70%] md:flex items-start  md:px-12 px-8 w-full">
          {/* Change Profile */}
          <div className="md:mr-5 md:w-[20%]">
            <img
              src={user}
              className="h-[100px] w-[100px] mx-auto mb-2 rounded-full"
              alt="Profile"
            />
            <p className="text-center text-blue-600 text-[11px] font-light">
              Change Profile
            </p>
          </div>
          {/* form Content */}
          <div className="md:w-[80%]">
            <div className=" bg-darkBg py-5 md:px-8 px-5 rounded-sm mb-5">
              {/* Form Row One (Hopital Name and Contact Person Name) */}
              <div className={row}>
                {/* col 1 */}
                <div className={col}>
                  <InputField
                    name="hospitalName"
                    title="Organization Name"
                    placeholder="Organization Name"
                    control={control}
                    rules={{
                      required: "Organization Name is required",
                      pattern: {
                        value: textReg,
                        message: "Text Only",
                      },
                    }}
                  />
                </div>
                {/* Col 2 */}
                <div className={col}>
                  <InputField
                    name="contactName"
                    title="Contact Name"
                    placeholder="Contact Name"
                    control={control}
                    rules={{
                      required: "Contact Name is required",
                      pattern: {
                        value: textReg,
                        message: "Text Only",
                      },
                    }}
                  />
                </div>
              </div>
              {/* Form Row Two (Contact Name and Phone Number) */}
              <div className={row}>
                {/* col 1 */}
                <div className={col}>
                  <InputField
                    name="contactEmail"
                    title="Email"
                    placeholder="Email"
                    control={control}
                    rules={{
                      required: "Email is required",
                      pattern: {
                        value: emailReg,
                        message: "Invalid Email",
                      },
                    }}
                    disabled={true}
                  />
                </div>
                {/* Col 2 */}
                <div className={col}>
                  <InputField
                    name="contactPhone"
                    title="Phone Number"
                    placeholder="Phone Number"
                    control={control}
                    rules={{
                      required: "Phone Number is required",
                    }}
                    disabled={true}
                  />
                </div>
              </div>
              {/* Form Row Three (Country and  States) */}
              <div className={row}>
                {/* col 1 */}
                <div className={col}>
                  <SelectField
                    control={control}
                    name="hospitalType"
                    rules={{ required: "Organization Type is required" }}
                    placeholder="Select"
                    title="Organization Type"
                    options={["Public", "Private"]}
                  />
                </div>
                {/* Col 2 */}
                <div className={col}>
                  <SelectField
                    control={control}
                    name="hospitalCountry"
                    rules={{ required: "country is required" }}
                    placeholder="Select"
                    title="country"
                    options={countryNames}
                  />
                </div>
              </div>
              {/* Form Row Four (Hosptal Address and Hospital  Area) */}
              <div className={row}>
                {/* col 1 */}
                <div className={col}>
                  <SelectField
                    control={control}
                    name="hospitalState"
                    rules={{ required: "State is required" }}
                    placeholder="Select"
                    title="State"
                    options={stateNames}
                  />
                </div>
                {/* Col 2 */}
                <div className={col}>
                  <InputField
                    name="hospitalArea"
                    title="Area"
                    placeholder="Area"
                    control={control}
                    rules={{
                      required: "Area is required",
                    }}
                  />
                </div>
              </div>
              {/* Form Row Fivr */}
              <div>
                <InputField
                  name="hospitalAddress"
                  title="Organization Address"
                  placeholder="Organization Address"
                  control={control}
                  rules={{
                    required: "Organization Address is required",
                  }}
                />
              </div>
            </div>
            {/* Button */}
            <div className="md:flex block justify-end items-center mt-3 mb-10">
              <div className="md:w-[150px] w-full">
                <Button
                  text="Save Changes"
                  OnBtnClick={handleSubmit(onSubmit)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
