import axios from "axios";
import { baseURL } from "../../../URL";
import { DeptDTO } from "../../../typings/hostpital";

const CreateDept = async (postData: DeptDTO, config: any) => {
    try {
        const response = await axios.post(`${baseURL}/departments`, postData, config);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};
const GetAllDepts = async (currentPage: any, config: any) => {
    try {
        const response = await axios.get(`${baseURL}/departments?page=${currentPage}`, config);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};
const GetFilteredDept = async (search: any, currentPage: any, config: any) => {
    try {
        const response = await axios.get(`${baseURL}/departments/search/${search}`, config);
        // const response = await axios.get(`${baseURL}/staffs?page=${currentPage}`, config);
        console.log("Response", response.data)
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};
const GetDeptByID = async (id: any, config: any) => {
    try {
        const response = await axios.get(`${baseURL}/departments/${id}`, config);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};
const UpdateDept = async (updateData: any, deptID: any, config: any) => {
    try {
        const response = await axios.patch(`${baseURL}/departments/${deptID}`, updateData, config);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};
const DeleteDeptByID = async (id: any, config: any) => {
    try {
        const response = await axios.delete(`${baseURL}/departments/${id}`, config);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};



export { CreateDept, GetAllDepts, GetDeptByID, GetFilteredDept, UpdateDept, DeleteDeptByID }