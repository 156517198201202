import axios from "axios";
import { baseURL } from "../../../URL";

const onGetAllinvoices = async ( curpage :number,config: any) => {
    try {
        const response = await axios.get(`${baseURL}/patient-invoices?page=${curpage}`, config);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};
const onGetPatientinvoices = async ( id :number,config: any) => {
    try {
        const response = await axios.get(`${baseURL}/patient-invoices/${id}`, config);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};
const onGetIvoiceDetails = async ( id :number,config: any) => {
    try {
        const response = await axios.get(`${baseURL}/patient-invoices/${id}`, config);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};


const onCreateInvoice = async (postData: any, config: any) => {
    try {
        const response = await axios.post(`${baseURL}/patient-invoices`, postData, config);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};
const onCreateTransaction = async (postData: any, config: any) => {
    try {
        const response = await axios.post(`${baseURL}/patient-transactions`, postData, config);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};

export{onGetAllinvoices,  onCreateTransaction,onGetIvoiceDetails, onGetPatientinvoices,onCreateInvoice}